
import Vue from 'vue';

import ComponentService from '@/services/componentService';
require('../../Content/Common/Common.css');
export default Vue.extend({
  props: {
    userGuideUrl: {
      type: String,
      required: true,
    },
  },
  mounted(): void {
    if (!this.userGuideUrl) {
      new ComponentService().getUserGuideUrl(this.onUserGuideUrlAcquisitionSuccessfullyRetrieved);
    }
  },
  methods: {
    openModal(name: string): void {
      if (name === 'help') {
        this.$emit('changeContactDialogState');
      } else {
        this.$emit('changeInfoDialogState');
      }
    },
    onUserGuideUrlAcquisitionSuccessfullyRetrieved(response: any): void {
      this.$emit('changeUserGuideUrl', response.Data);
    },
    openUserGuide() {
      window.open(this.userGuideUrl, '_blank');
    },
  },
});
