export default class UserRightsComponent {
  public HasPublish = false;
  public HasPublishAdmin = false;

  public constructor(hasPublish = false, hasPublishAdmin = false) {
    this.HasPublish = hasPublish;
    this.HasPublishAdmin = hasPublishAdmin;
  }

  public get HasPublishRights(): boolean {
    return this.HasPublish || this.HasPublishAdmin;
  }
}
