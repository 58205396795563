import ErrorHelper from '@/helpers/errorHelper';

export default class ClipboardHelper {
  public static addToClipboard(textToCopy: string): boolean {
    let successful;
    const el = document.createElement('textarea'); // Create a <textarea> element
    el.value = textToCopy; // Set its value to the string that you want copied
    el.setAttribute('readonly', ''); // Make it readonly to be tamper-proof
    el.style.position = 'absolute';
    el.style.left = '-9999px'; // Move outside the screen to make it invisible
    document.body.appendChild(el);
    el.select();

    try {
      // Now that we've selected the anchor text, execute the copy command
      successful = document.execCommand('copy');
    } catch (err) {
      document.body.removeChild(el);
      return false;
    }

    document.body.removeChild(el);
    return successful;
  }

  public static copyDetail(textToCopy: string): void {
    if (this.addToClipboard(textToCopy)) {
      ErrorHelper.addSnackbarMessage('Copied to clipboard', 'success');
    } else {
      ErrorHelper.addSnackbarMessage('Copy failed. Please try again', 'error');
    }
  }
}
