
import Vue from 'vue';

import CopyButton from '@/components/details/CopyButton.vue';
import DetailsHelper from '@/helpers/detailsHelper';

export default Vue.extend({
  components: {
    CopyButton,
  },
  props: {
    infoItems: {
      type: [Array, Object],
    },
  },
  data(): {
    regActs: any;
  } {
    const regActDetails = this.infoItems.find(
      (x: any) => x.Header === 'Regulatory Activities',
    ).Content;
    const groupedData = DetailsHelper.makeArrayNestedByPropertyName(
      regActDetails,
      'DisplayAppNumber',
    );
    return {
      regActs: groupedData,
    };
  },
  mounted(): void {
    const dividers = document.querySelectorAll('#detailsCard > .pa-3 > .v-divider');
    const lastDivider = dividers[dividers.length - 1];
    lastDivider.parentElement!.removeChild(lastDivider);
  },
});
