import $ from 'jquery';

import ErrorHelper from '@/helpers/errorHelper';
import helper from '@/helpers/globalHelper';
import TreeComponent from '@/models/treeComponent';

export default class ComponentService {
  // app selector methods
  public getRecentApps(successFunction: any, url: string): any {
    return this.requestWrapper(null, successFunction, this.genericErrorFunction, url);
  }

  public openApp(data: any, successFunction: any, url: string): any {
    return this.requestWrapper(data, successFunction, this.genericErrorFunction, url);
  }

  // method to get the url of the user guide
  public getUserGuideUrl(successFunction: any): any {
    const url = './Help/GetUserGuideUrl';
    return this.requestWrapper(null, successFunction, this.genericErrorFunction, url);
  }

  // details methods
  public getDetails(successFunction: any, treeComponent: TreeComponent, url: string): void {
    return this.requestWrapper(treeComponent, successFunction, this.genericErrorFunction, url);
  }

  public getReusedFiles(
    successFunction: any,
    fileUseHistoryId: number,
    companyId: number,
    appId: number,
    format: number,
    region: number,
  ): void {
    const url = `./ReusedFiles/GetReusedFiles?fileUseHistoryId=${fileUseHistoryId}&companyId=${companyId}&appId=${appId}&format=${format}&region=${region}`;
    return this.requestWrapper(null, successFunction, this.genericErrorFunction, url);
  }

  // maincomponent methods
  public isAppOpen(successFunction: any, url: string): boolean {
    return this.requestWrapper(null, successFunction, this.genericErrorFunction, url);
  }

  // searchbar methods
  public searchText(data: any, successFunction: any, errorFunction: any, url: string): any {
    return this.requestWrapper(data, successFunction, errorFunction, url, true, false);
  }

  // sidebar methods
  public getAppTitle(successFunction: any, url: string): string {
    return this.requestWrapper(null, successFunction, this.genericErrorFunction, url);
  }

  public getTreePathFromGuid(companyId: number, guid: string, successFunction: any): string {
    return this.requestWrapper(
      { id: guid },
      successFunction,
      this.genericErrorFunction,
      `./LinkToShare/LookUpLink?companyId=${companyId}`,
    );
  }

  public getTreePathByFileId(
    companyId: number,
    appId: number,
    format: number,
    region: number,
    nodeInfo: any,
    successFunction: any,
  ): string {
    return this.requestWrapper(
      nodeInfo,
      successFunction,
      this.genericErrorFunction,
      `./Inventory/GetInventoryTreePath?companyId=${companyId}&appId=${appId}&format=${format}&region=${region}`,
    );
  }

  public getAppDetails(
    companyId: number,
    appId: number,
    format: number,
    region: number,
    successFunction: any,
  ): any {
    return this.requestWrapper(
      null,
      successFunction,
      this.genericErrorFunction,
      `./AppDetails/GetAppDetails?companyId=${companyId}&appId=${appId}&format=${format}&region=${region}`,
    );
  }

  // tree methods
  public getLinkToShare(data: any, successFunction: any, url: string): string {
    return this.requestWrapper(data, successFunction, this.genericErrorFunction, url, false);
  }

  // XML Viewer methods
  public isXmlStyleSheetBad(successFunction: any, url: string): boolean {
    return this.requestWrapper(null, successFunction, this.genericErrorFunction, url);
  }

  public doesFileExistById(companyId: number, fuhId: number): any {
    const url = `viewer/DoesFileExist?companyId=${companyId}&fuhId=${fuhId}`;
    return $.ajax({
      cache: false,
      url,
    });
  }

  public doesFileExistByPath(companyId: number, source: string, target: string): any {
    const url = `viewer/DoesFileExistByPath?companyId=${companyId}&target=${target}&source=${source}`;
    return $.ajax({
      cache: false,
      url,
    });
  }

  public requestWrapper(
    data: any,
    successFunction: any,
    errorFunction: any,
    url: string,
    async = true,
    useEncodedValue = true,
  ): any {
    $.ajax({
      async,
      cache: false,
      data: useEncodedValue ? helper.encodeData(data) : data,
      url,
    }).always((result: any): any => {
      if (result.status != null || result.Errors != null) {
        return errorFunction(result, result.Errors);
      }

      return successFunction(result);
    });
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  private genericErrorFunction(result: any, errors: any): void {
    const genericMessage =
      'Something went wrong. If this keeps occuring, please contact support for help.';
    // eslint-disable-next-line no-console
    console.error(result);
    const errorStack: string = result.responseText;
    ErrorHelper.addSnackbarMessage(genericMessage, 'error', errorStack, true);
  }
}
