import { EventBus } from '@/helpers/EventBus';
import { AlertMessage } from '@/models/alertMessage';

export default class ErrorHelper {
  public static addSnackbarMessage(
    message: string,
    color: string,
    errorMessage = '',
    showContactSupport = false,
    logId = 0,
    timeout: number | null = null,
  ): void {
    let timeoutValue = timeout ?? (color === 'error' ? 0 : 5000);
    if (timeoutValue === 0) timeoutValue = -1;

    const alert = new AlertMessage(message, color, timeoutValue, errorMessage, logId);
    EventBus.$emit('addSnackbar', alert, showContactSupport);
  }

  public static EnqueueProcessError =
    'Unable to enqueue the processing of this item. Please try again.';
  public static EnqueueRemoveError =
    'Unable to enqueue the removal of this item. Please try again.';
  public static ConfigurationSaveError = 'Unable to save configuration. Please try again.';
  public static ConfigurationGetError = 'Could not get configuration.';
  public static CompaniesGetError = 'Could not get companies.';
  public static GridDataGetError = 'Unable to get grid data.';
  public static StatusGetError = 'Unable to get status.';
  public static QueueError = 'Unable to get queue data';
  public static PermissionError = 'Unable to get user permissions.';
  public static UserGuideGetError = 'Could not get user doc.';
  public static PublishSupportedRegionsError = 'Could not get regions.';
  public static PublishSupportedVersions = 'Could not get versions.';
  public static AgencyTypes = 'Could not get agency types.';
  public static AgencyTypeCodes = 'Could not get agency type codes.';
  public static AppTypes = 'Could not get application types.';
  public static CreateNewApp = 'Could not create an application.';
  public static AppDetails = 'Could not get application details.';
  public static FileDetails = 'Could not get file details.';
  public static FileUses = 'Could not get file uses.';
  public static ValidationError = 'Validation error.';
  public static UnitTypes = 'Could not get submission types.';
  public static AllowedUnits = 'Could not get allowed units.';
  public static Subs = 'Could not get submissions.';
  public static SubTypes = 'Could not get submission unit types.';
  public static Applicants = 'Could not get applicants.';
  public static ContactTypes = 'Could not get contact types.';
  public static ContactDetailTypes = 'Could not get contact detail types.';
  public static Contacts = 'Could not get contacts.';
  public static ContactOrganizations = 'Could not get contact organizations.';
  public static TreeClipboardItem = 'Could not get tree clipboard item.';
}
