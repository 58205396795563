import { Format } from '@/helpers/Enums';

export enum FileUseHistoryStatus {
  Deleted = 1,
}

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace FileUseHistoryStatus {
  const buildKey = (formatId: number, status: FileUseHistoryStatus) =>
    `${Format[formatId]}:${FileUseHistoryStatus[status]}`;

  const transformations = new Map<string, string>([
    [buildKey(Format.eCTD40, FileUseHistoryStatus.Deleted), 'Suspended'],
  ]);

  export function fromString(status: string): FileUseHistoryStatus | null {
    switch (status) {
      case FileUseHistoryStatus[FileUseHistoryStatus.Deleted]:
        return FileUseHistoryStatus.Deleted;
      default:
        return null;
    }
  }

  export function displayText(
    formatId: number,
    status: FileUseHistoryStatus | number | null,
  ): string {
    if (!status) return '';
    const key = buildKey(formatId, status);
    return transformations.get(key) ?? FileUseHistoryStatus[status];
  }
}
