import { Format } from '@/helpers/Enums';

export enum FileUseState {
  Current = 0,
  Deleted = 1,
  Replaced = 2,
  Append = 3,
  Updated = 4,
  Amended = 5,
}

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace FileUseState {
  const buildKey = (formatId: number, state: FileUseState) =>
    `${Format[formatId]}:${FileUseState[state]}`;

  const transformations = new Map<string, string>([
    [buildKey(Format.eCTD40, FileUseState.Deleted), 'Suspended'],
  ]);

  export function displayText(formatId: number, state: FileUseState | number | null): string {
    if (state === null) return '';
    const key = buildKey(formatId, state);
    return transformations.get(key) ?? FileUseState[state];
  }
}
