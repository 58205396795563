import { BadRequestError } from '@/models/Errors';
import { File } from '@/models/WebPublish';
import { FileReplacementDto } from '@/models/WebPublish/FileReplacementDto';
import {
  ChangeFileNameRequest,
  ChangeFileTitleRequest,
  CompleteFilesUploadRequest,
  DeleteFilesRequest,
  RemoveSuspensionRequest,
  StartFilesUploadRequest,
} from '@/models/WebPublish/Requests';
import { AddFolderRequest } from '@/models/WebPublish/Requests/AddFolderRequest';
import { EditFolderRequest } from '@/models/WebPublish/Requests/EditFolderRequest';
import { SaveReplacesRequest } from '@/models/WebPublish/Requests/SaveReplacesRequest';
import { SuspendFileRequest } from '@/models/WebPublish/Requests/SuspendFileRequest';
import { RemoveSuspensionResponse, StartFileUploadResponse } from '@/models/WebPublish/Responses';

export class FilesService {
  public async getFile(companyId: number, fileId: number): Promise<File> {
    const response = await fetch(
      `${window.sessionUrl}Files/GetFile?companyId=${companyId}&fileId=${fileId}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      },
    );

    if (response.ok) {
      return JSON.parse(await response.text());
    } else {
      const errorMessage = JSON.parse(await response.text());

      switch (response.status) {
        default:
          throw Error(errorMessage);
      }
    }
  }

  public async startFilesUpload(
    companyId: number,
    request: StartFilesUploadRequest,
  ): Promise<StartFileUploadResponse[]> {
    const response = await fetch(`${window.sessionUrl}Files/StartFilesUpload`, {
      method: 'POST',
      body: JSON.stringify({ companyId, request }),
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (response.ok) {
      return JSON.parse(await response.text());
    } else {
      const errorMessage = JSON.parse(await response.text());

      switch (response.status) {
        case 400:
          throw new BadRequestError(errorMessage);
        default:
          throw Error(errorMessage);
      }
    }
  }

  public async completeFilesUpload(
    companyId: number,
    request: CompleteFilesUploadRequest,
  ): Promise<void> {
    const response = await fetch(`${window.sessionUrl}Files/CompleteFilesUpload`, {
      method: 'POST',
      body: JSON.stringify({ companyId, request }),
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (response.ok) {
      return;
    } else {
      const errorMessage = JSON.parse(await response.text());

      switch (response.status) {
        case 400:
          throw new BadRequestError(errorMessage);
        default:
          throw Error(errorMessage);
      }
    }
  }

  public async addFolder(companyId: number, request: AddFolderRequest): Promise<void> {
    const response = await fetch(`${window.sessionUrl}Folders/AddFolder`, {
      method: 'POST',
      body: JSON.stringify({ companyId, request }),
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (response.ok) {
      return;
    } else {
      const errorMessage = JSON.parse(await response.text());

      switch (response.status) {
        case 400:
          throw new BadRequestError(errorMessage);
        default:
          throw Error(errorMessage);
      }
    }
  }

  public async editFolder(companyId: number, request: EditFolderRequest): Promise<void> {
    const response = await fetch(`${window.sessionUrl}Folders/EditFolder`, {
      method: 'POST',
      body: JSON.stringify({ companyId, request }),
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (response.ok) {
      return;
    } else {
      const errorMessage = JSON.parse(await response.text());

      switch (response.status) {
        case 400:
          throw new BadRequestError(errorMessage);
        default:
          throw Error(errorMessage);
      }
    }
  }

  public async changeFileTitle(companyId: number, request: ChangeFileTitleRequest): Promise<void> {
    const response = await fetch(`${window.sessionUrl}Files/ChangeFileTitle`, {
      method: 'POST',
      body: JSON.stringify({ companyId, request }),
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (response.ok) {
      return;
    } else {
      const errorMessage = JSON.parse(await response.text());

      switch (response.status) {
        case 400:
          throw new BadRequestError(errorMessage);
        default:
          throw Error(errorMessage);
      }
    }
  }

  public async changeFileName(companyId: number, request: ChangeFileNameRequest): Promise<void> {
    const response = await fetch(`${window.sessionUrl}Files/ChangeFileName`, {
      method: 'POST',
      body: JSON.stringify({ companyId, request }),
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (response.ok) {
      return;
    } else {
      const errorMessage = JSON.parse(await response.text());

      switch (response.status) {
        case 400:
          throw new BadRequestError(errorMessage);
        default:
          throw Error(errorMessage);
      }
    }
  }

  public async suspendFile(
    companyId: number,
    appId: number,
    targetSubId: number,
    fileUseHistoryId: number,
  ): Promise<void> {
    const request: SuspendFileRequest = {
      AppId: appId,
      TargetSubId: targetSubId,
      FileUseHistoryId: fileUseHistoryId,
    };
    const response = await fetch(`${window.sessionUrl}Files/SuspendFile`, {
      method: 'POST',
      body: JSON.stringify({ companyId, request }),
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (response.ok) {
      return;
    } else {
      const errorMessage = JSON.parse(await response.text());

      switch (response.status) {
        case 400:
          throw new BadRequestError(errorMessage);
        default:
          throw Error(errorMessage);
      }
    }
  }

  public async removeSuspension(
    companyId: number,
    fileUseHistoryId: number,
  ): Promise<RemoveSuspensionResponse> {
    const request: RemoveSuspensionRequest = {
      FileUseHistoryId: fileUseHistoryId,
    };

    const response = await fetch(`${window.sessionUrl}Files/RemoveSuspension`, {
      method: 'POST',
      body: JSON.stringify({ companyId, request }),
      headers: { 'Content-Type': 'application/json' },
    });

    if (response.ok) {
      return JSON.parse(await response.text());
    } else {
      const errorMessage = JSON.parse(await response.text());

      switch (response.status) {
        case 400:
          throw new BadRequestError(errorMessage);
        default:
          throw Error(errorMessage);
      }
    }
  }

  public async deleteFile(
    companyId: number,
    appId: number,
    fileUseHistoryId: number,
  ): Promise<number> {
    const request: DeleteFilesRequest = { AppId: appId, FileUseHistoryIds: [fileUseHistoryId] };
    const response = await fetch(`${window.sessionUrl}Files/DeleteFiles`, {
      method: 'POST',
      body: JSON.stringify({ companyId, appId, request }),
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (response.ok) {
      return JSON.parse(await response.text());
    } else {
      const errorMessage = JSON.parse(await response.text());

      switch (response.status) {
        case 400:
          throw new BadRequestError(errorMessage);
        default:
          throw Error(errorMessage);
      }
    }
  }

  public async deleteFolder(
    companyId: number,
    subId: number,
    sectionExtensionId: number,
  ): Promise<void> {
    const response = await fetch(`${window.sessionUrl}Folders/DeleteFolder`, {
      method: 'POST',
      body: JSON.stringify({ companyId, subId, sectionExtensionId }),
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (response.ok) {
      return;
    } else {
      const errorMessage = JSON.parse(await response.text());

      switch (response.status) {
        case 400:
          throw new BadRequestError(errorMessage);
        default:
          throw Error(errorMessage);
      }
    }
  }

  public async GetPotentialReplaces(
    companyId: number,
    appId: number,
    fileUseHistoryId: number,
  ): Promise<Array<FileReplacementDto>> {
    const response = await fetch(
      `${window.sessionUrl}Files/GetPotentialReplaces?companyId=${companyId}&appId=${appId}&fileUseHistoryId=${fileUseHistoryId}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      },
    );

    if (response.ok) {
      return JSON.parse(await response.text());
    } else {
      const errorMessage = JSON.parse(await response.text());

      switch (response.status) {
        default:
          throw Error(errorMessage);
      }
    }
  }

  public async GetExistingReplaces(
    companyId: number,
    appId: number,
    fileUseHistoryId: number,
  ): Promise<Array<FileReplacementDto>> {
    const response = await fetch(
      `${window.sessionUrl}Files/GetExistingReplaces?companyId=${companyId}&appId=${appId}&fileUseHistoryId=${fileUseHistoryId}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      },
    );

    if (response.ok) {
      return JSON.parse(await response.text());
    } else {
      const errorMessage = JSON.parse(await response.text());

      switch (response.status) {
        default:
          throw Error(errorMessage);
      }
    }
  }

  public async SaveReplaces(companyId: number, request: SaveReplacesRequest): Promise<void> {
    const response = await fetch(`${window.sessionUrl}Files/SaveReplaces`, {
      method: 'POST',
      body: JSON.stringify({ companyId, request }),
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (response.ok) {
      return;
    } else {
      const errorMessage = JSON.parse(await response.text());

      switch (response.status) {
        case 400:
          throw new BadRequestError(errorMessage);
        default:
          throw Error(errorMessage);
      }
    }
  }
}

export const filesService = new FilesService();
