import $ from 'jquery';

export class SearchHelper {
  public static addDataAndMark(searchTerm: string): void {
    this.searchValue = searchTerm;
    const context = $('#search-result-tree > .k-item > .k-top > .k-in > .tree-item').add(
      $('#search-result-tree > .k-item > .k-mid > .k-in > .tree-item').add(
        $('#search-result-tree > .k-item > .k-bot > .k-in > .tree-item'),
      ),
    );
    this.highlightResults(context);
  }

  private static searchValue = '';

  private static highlightResults(context: any): void {
    const searchTerm = this.searchValue;
    const searchTermLength = this.searchValue.length;

    $(context).each(function (): void {
      const text = $(this).text();
      const p = text.toUpperCase().indexOf(searchTerm.toUpperCase());
      if (p >= 0) {
        let s1 = '';
        let s2 = '';
        const highlight = `<span class="highlight">${text.substring(
          p,
          p + searchTermLength,
        )}</span>`;

        if (p > 0) {
          s1 = text.substring(0, p);
        }

        if (p + searchTermLength < text.length) {
          s2 = text.substring(p + searchTermLength);
        }

        $(this).html(s1 + highlight + s2);
      }
    });
  }
}
