
import Vue from 'vue';

import GlobalHelper from '@/helpers/globalHelper';
export default Vue.extend({
  props: {
    value: {
      type: Boolean,
    },
    title: {
      type: String,
    },
    showButtons: {
      type: Boolean,
    },
    showOkButton: {
      type: Boolean,
      default: true,
    },
    showCloseButton: {
      type: Boolean,
      default: true,
    },
    okText: {
      type: String,
      default: 'OK',
    },
    okEnabled: {
      type: Boolean,
      default: true,
    },
    okLoading: {
      type: Boolean,
      default: false,
    },
    cancelText: {
      type: String,
      default: 'Cancel',
    },
    cancelEnabled: {
      type: Boolean,
      default: true,
    },
    cancelLoading: {
      type: Boolean,
      default: false,
    },
    width: {
      type: [String, Number],
      default: null,
    },
    persistent: {
      type: Boolean,
      default: false,
    },
    maxHeight: {
      type: Number,
      default: 350,
    }
  },
  data(): {
    dialogModel: boolean;
    maxWidth: number;
  } {
    return {
      dialogModel: false,
      maxWidth: 585,
    };
  },
  computed: {
    iconXUrl(): string {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      // eslint-disable-next-line @typescript-eslint/no-var-requires
      return GlobalHelper.getPath(require('../../Content/images/icon-x.svg'));
    },
  },
  watch: {
    value(): void {
      this.dialogModel = this.value;
    },
    dialogModel(newVal: boolean, oldVal: boolean): void {
      if (newVal != oldVal) {
        this.$emit('input', newVal);
      }
    },
  },
});
