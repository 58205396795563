export enum ContextMenuItemKey {
  divider,
  copyLink,
  editSubmission,
  deleteSubmission,
  publishSubmission,
  addFiles,
  deleteFile,
  downloadFile,
  editFileTitle,
  editFileName,
  copyForReused,
  pasteAsReused,
  addFolder,
  createNewContextGroup,
  editContextGroup,
  deleteContextGroup,
  deleteFolder,
  editFolder,
  suspendFile,
  details,
  removeSuspension,
  replaceFiles,
  add,
}
