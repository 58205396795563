import ErrorHelper from '@/helpers/errorHelper';
import GlobalHelper from '@/helpers/globalHelper';
import { BadRequestError } from '@/models/Errors';
import {
  AgencyType,
  AgencyTypeCode,
  AppType,
  Contact,
  ContactDetailType,
  ContactType,
  Region,
  SubType,
  Unit,
  UnitType,
  Version,
} from '@/models/WebPublish';
import { ApplicationDetails } from '@/models/WebPublish/ApplicationDetails';
import {
  CreateApplicationRequest,
  DeleteApplicationRequest,
  UpdateApplicationRequest,
  ValidateAppNumberAndTypeRequest,
} from '@/models/WebPublish/Requests';
import { CreateSubmissionRequest } from '@/models/WebPublish/Requests/CreateSubmissionRequest';
import { UpdateSubmissionRequest } from '@/models/WebPublish/Requests/UpdateSubmissionRequest';
import { Submission } from '@/models/WebPublish/Submission';

class ApplicationsService {
  public async getRegions(): Promise<Region[]> {
    return await GlobalHelper.http(
      `${window.sessionUrl}Applications/GetPublishSupportedRegions`,
      ErrorHelper.PublishSupportedRegionsError,
    );
  }

  public async getVersions(regionId: number): Promise<Version[]> {
    return await GlobalHelper.http(
      `${window.sessionUrl}Applications/GetPublishSupportedVersions?regionId=${regionId}`,
      ErrorHelper.PublishSupportedVersions,
    );
  }

  public async getAgencyTypes(regionId: number): Promise<AgencyType[]> {
    return await GlobalHelper.http(
      `${window.sessionUrl}Applications/GetAgencyTypes?regionId=${regionId}`,
      ErrorHelper.AgencyTypes,
    );
  }

  public async getAgencyTypeCodes(codeSystemId: number): Promise<AgencyTypeCode[]> {
    return await GlobalHelper.http(
      `${window.sessionUrl}Applications/GetAgencyTypeCodes?codeSystemId=${codeSystemId}`,
      ErrorHelper.AgencyTypeCodes,
    );
  }

  public async getAppTypes(codeSystemId: number): Promise<AppType[]> {
    return await GlobalHelper.http(
      `${window.sessionUrl}Applications/GetAppTypes?codeSystemId=${codeSystemId}`,
      ErrorHelper.AppTypes,
    );
  }

  public async getApplicants(companyId: number, startsWith: string): Promise<string[]> {
    return await GlobalHelper.http(
      `${window.sessionUrl}Submissions/GetApplicants?companyId=${companyId}&startsWith=${startsWith}`,
      ErrorHelper.Applicants,
    );
  }

  public async getUnitTypes(codeSystemId: number, appTypeId: number): Promise<UnitType[]> {
    return await GlobalHelper.http(
      `${window.sessionUrl}Submissions/GetUnitTypes?codeSystemId=${codeSystemId}&appTypeId=${appTypeId}`,
      ErrorHelper.UnitTypes,
    );
  }

  public async getSubTypes(codeSystemId: number, unitTypeId: number): Promise<SubType[]> {
    return await GlobalHelper.http(
      `${window.sessionUrl}Submissions/GetSubTypes?codeSystemId=${codeSystemId}&unitTypeId=${unitTypeId}`,
      ErrorHelper.SubTypes,
    );
  }

  public async getAllowedUnits(
    companyId: number,
    appId: number | null,
    format: number | null,
    region: number | null,
    unitTypeId: number | null,
  ): Promise<Unit[]> {
    let result = [{ Id: null, Title: 'Create a New Submission' }];

    if (appId && unitTypeId) {
      const units = await GlobalHelper.http(
        `${window.sessionUrl}Submissions/GetAllowedUnits?companyId=${companyId}&appId=${appId}&format=${format}&region=${region}&unitTypeId=${unitTypeId}`,
        ErrorHelper.AllowedUnits,
      );
      result = result.concat(units);
    }

    return result;
  }

  public async getContactTypes(): Promise<ContactType[]> {
    return await GlobalHelper.http(
      `${window.sessionUrl}Contacts/GetContactTypes`,
      ErrorHelper.ContactTypes,
    );
  }

  public async getContactDetailTypes(): Promise<ContactDetailType[]> {
    return await GlobalHelper.http(
      `${window.sessionUrl}Contacts/GetContactDetailTypes`,
      ErrorHelper.ContactDetailTypes,
    );
  }

  public async getContactsForUnit(companyId: number, unitId: number): Promise<Contact[]> {
    return await GlobalHelper.http(
      `${window.sessionUrl}Contacts/GetContactsForUnit?companyId=${companyId}&unitId=${unitId}`,
      ErrorHelper.Contacts,
    );
  }

  public async getContactsForSub(
    companyId: number,
    unitId: number,
    subId: number,
    displayOrder: number,
  ): Promise<Contact[]> {
    return await GlobalHelper.http(
      `${window.sessionUrl}Contacts/GetContactsForSub?companyId=${companyId}&unitId=${unitId}&subId=${subId}&displayOrder=${displayOrder}`,
      ErrorHelper.Contacts,
    );
  }

  public async getContactOrganizations(companyId: number, startsWith: string): Promise<string[]> {
    return await GlobalHelper.http(
      `${window.sessionUrl}Contacts/GetOrganizations?companyId=${companyId}&startsWith=${startsWith}`,
      ErrorHelper.ContactOrganizations,
    );
  }

  public async validateAppNumberAndType(
    companyId: number,
    request: ValidateAppNumberAndTypeRequest,
  ): Promise<boolean> {
    const response = await fetch(`${window.sessionUrl}Applications/ValidateAppNumberAndType`, {
      method: 'POST',
      body: JSON.stringify({ companyId, request }),
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (response.ok) {
      return (await response.text()) === 'true';
    } else {
      const errorMessage = JSON.parse(await response.text());
      throw Error(errorMessage);
    }
  }

  public async createApplication(
    companyId: number,
    request: CreateApplicationRequest,
  ): Promise<number> {
    const response = await fetch(`${window.sessionUrl}Applications/CreateApplication`, {
      method: 'POST',
      body: JSON.stringify({ companyId, request }),
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (response.ok) {
      return Number.parseInt(await response.text());
    } else {
      const errorMessage = JSON.parse(await response.text());

      switch (response.status) {
        case 400:
          throw new BadRequestError(errorMessage);
        default:
          throw Error(errorMessage);
      }
    }
  }

  public async deleteApplication(request: DeleteApplicationRequest): Promise<void> {
    const response = await fetch(`${window.sessionUrl}Applications/DeleteApplication`, {
      method: 'POST',
      body: JSON.stringify(request),
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (!response.ok) {
      const errorMessage = JSON.parse(await response.text());

      switch (response.status) {
        case 400:
          throw new BadRequestError(errorMessage);
        default:
          throw Error(errorMessage);
      }
    }
  }

  public async publishSubmissionUnit(
    companyId: number,
    appId: number,
    subId: number,
  ): Promise<void> {
    const response = await fetch(`${window.sessionUrl}Submissions/PublishSubmissionUnit`, {
      method: 'POST',
      body: JSON.stringify({ companyId, appId, subId }),
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (!response.ok) {
      const errorMessage = JSON.parse(await response.text());

      switch (response.status) {
        case 400:
          throw new BadRequestError(errorMessage);
        default:
          throw Error(errorMessage);
      }
    }
  }

  public async deleteSubmissionUnit(
    companyId: number,
    appId: number,
    subId: number,
  ): Promise<void> {
    const response = await fetch(`${window.sessionUrl}Submissions/DeleteSubmission`, {
      method: 'POST',
      body: JSON.stringify({ companyId, appId, subId }),
      headers: { 'Content-Type': 'application/json' },
    });

    if (!response.ok) {
      const errorMessage = JSON.parse(await response.text());

      switch (response.status) {
        case 400:
          throw new BadRequestError(errorMessage);
        default:
          throw Error(errorMessage);
      }
    }
  }

  public async updateApplication(
    companyId: number,
    request: UpdateApplicationRequest,
  ): Promise<void> {
    const response = await fetch(`${window.sessionUrl}Applications/UpdateApplication`, {
      method: 'POST',
      body: JSON.stringify({ companyId, request }),
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (!response.ok) {
      const errorMessage = JSON.parse(await response.text());

      switch (response.status) {
        case 400:
          throw new BadRequestError(errorMessage);
        default:
          throw Error(errorMessage);
      }
    }
  }

  public async getApplicationDetails(
    companyId: number,
    appId: number,
    format: number,
    region: number,
  ): Promise<ApplicationDetails> {
    return await GlobalHelper.http(
      `${window.sessionUrl}Applications/GetApplicationDetails?companyId=${companyId}&appId=${appId}&format=${format}&region=${region}`,
      ErrorHelper.AppDetails,
    );
  }

  public async getSubmissions(companyId: number, appId: number): Promise<Submission[]> {
    return await GlobalHelper.http(
      `${window.sessionUrl}Submissions/GetSubmissions?companyId=${companyId}&appId=${appId}`,
      ErrorHelper.Subs,
    );
  }

  public async createSubmission(
    companyId: number,
    appId: number,
    request: CreateSubmissionRequest,
  ): Promise<void> {
    const response = await fetch(`${window.sessionUrl}Submissions/createSubmission`, {
      method: 'POST',
      body: JSON.stringify({ companyId, appId, request }),
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (!response.ok) {
      const errorMessage = JSON.parse(await response.text());

      switch (response.status) {
        case 400:
          throw new BadRequestError(errorMessage);
        default:
          throw Error(errorMessage);
      }
    }
  }

  public async updateSubmission(
    companyId: number,
    appId: number,
    request: UpdateSubmissionRequest,
  ): Promise<void> {
    const response = await fetch(`${window.sessionUrl}Submissions/updateSubmission`, {
      method: 'POST',
      body: JSON.stringify({ companyId, appId, request }),
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (!response.ok) {
      const errorMessage = JSON.parse(await response.text());

      switch (response.status) {
        case 400:
          throw new BadRequestError(errorMessage);
        default:
          throw Error(errorMessage);
      }
    }
  }
}

export const applicationsService = new ApplicationsService();
