import TreeComponent from '@/models/treeComponent';

export class TreeHelper {
  public static makeTreeComponent(treeNode: any): TreeComponent {
    const treeComponent = new TreeComponent();
    treeComponent.Id = treeNode.get('Id');
    treeComponent.ReviewSectionId = treeNode.get('ReviewSectionId');
    treeComponent.HasChildren = treeNode.get('HasChildren');
    treeComponent.Expanded = treeNode.get('Expanded');
    treeComponent.Title = treeNode.get('Title');
    treeComponent.ParentId = treeNode.get('ParentId');
    treeComponent.UnitId = treeNode.get('UnitId');
    treeComponent.SubId = treeNode.get('SubId');
    treeComponent.SectionId = treeNode.get('SectionId');
    treeComponent.RepeatableSectionId = treeNode.get('RepeatableSectionId');
    treeComponent.SectionExtensionId = treeNode.get('SectionExtensionId');
    treeComponent.FileUseSetId = treeNode.get('FileUseSetId');
    treeComponent.FileUseHistoryId = treeNode.get('FileUseHistoryId');
    treeComponent.FileUseStateId = treeNode.get('FileUseStateId');
    treeComponent.FileIconImageUrl = treeNode.get('FileIconImageUrl');
    treeComponent.IsFiltered = treeNode.get('IsFiltered');
    treeComponent.IsEmptyAllTheWayDown = treeNode.get('IsEmptyAllTheWayDown');
    treeComponent.InRepeatGroup = treeNode.get('InRepeatGroup');
    treeComponent.ActionType = treeNode.ActionType;
    treeComponent.IsGrouped = treeNode.IsGrouped;
    treeComponent.IsReused = treeNode.IsReused;
    treeComponent.TreeTypeId = treeNode.TreeTypeId;
    treeComponent.NodeTypeId = treeNode.NodeTypeId;
    treeComponent.FileType = treeNode.FileType;
    treeComponent.SpecId = treeNode.SpecId;
    treeComponent.SubDisplayOrder = treeNode.SubDisplayOrder;
    treeComponent.DoesExist = treeNode.DoesExist;
    treeComponent.IsPlaceholder = treeNode.IsPlaceholder;
    treeComponent.HasXfa = treeNode.HasXfa;
    return treeComponent;
  }

  public static getTreeTypeId(tree: string): number {
    switch (tree) {
      case 'Lifecycle':
        return 1;
      case 'Current':
        return 2;
      case 'Activities':
        return 3;
      case 'Sequence':
        return 4;
      case 'Origin':
        return 5;
      case 'Search':
        return 6;
      default:
        return 0;
    }
  }

  public static findKidsAndMakeOrnamentHolder(element: any): void {
    const tops = element.querySelectorAll('.k-top') as NodeList;
    const mids = element.querySelectorAll('.k-mid') as NodeList;
    const bots = element.querySelectorAll('.k-bot') as NodeList;

    this.makeOrnamentHolder(tops);
    this.makeOrnamentHolder(mids);
    this.makeOrnamentHolder(bots);
  }

  private static makeOrnamentHolder(elements: NodeList): void {
    const elementCount = elements.length;

    for (let i = 0; i < elementCount; i += 1) {
      const node = elements[i] as HTMLElement;
      this.changeIcon(node, 'k-i-expand');
      this.changeIcon(node, 'k-i-collapse');
    }
  }

  private static changeIcon(node: HTMLElement, iconName: string) {
    const wrongExpandIcon = `<span class="k-icon ${iconName}">`;
    const expandIcon = `<span class="k-icon ${iconName}"></span>`;

    if (node.innerHTML.indexOf(wrongExpandIcon) >= 0 && node.innerHTML.indexOf(expandIcon) === -1) {
      node.innerHTML = node.innerHTML.replace(wrongExpandIcon, expandIcon);
    }
  }
}
