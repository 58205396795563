
import Vue from 'vue';

import { mapGetters, mapState } from 'vuex';

import Dialog from '@/components/popups/Dialog.vue';
import ErrorHelper from '@/helpers/errorHelper';
import { filesService } from '@/services';

export default Vue.extend({
  components: {
    // eslint-disable-next-line vue/no-reserved-component-names
    Dialog,
  },
  props: {
    open: { type: Boolean },
    treeNode: { type: Object, default: null },
  },
  data(): {
    loading: boolean;
    deleting: boolean;
    physical: boolean;
  } {
    return {
      loading: false,
      deleting: false,
      physical: false,
    };
  },
  computed: {
    ...mapGetters(['companyId']),
    ...mapState(['selectedApplicationId', 'selectedFormat', 'selectedRegion']),
    fileName(): string {
      return this.treeNode?.FileName;
    },
  },
  watch: {
    open(value) {
      if (value) {
        this.load();
      }
    },
  },
  methods: {
    async load() {
      this.loading = true;
      try {
        const fileId = this.treeNode.FileId;
        const file = await filesService.getFile(this.companyId, fileId);

        this.physical = file.PhysicalFileUseHistoryId === this.treeNode.FileUseHistoryId;
      } catch {
        ErrorHelper.addSnackbarMessage('Deleting file info load failed', 'error', '', true);
        this.close();
      } finally {
        this.loading = false;
      }
    },
    async onDeleteClick() {
      this.deleting = true;
      try {
        (await filesService.deleteFile(
          this.companyId,
          this.selectedApplicationId,
          this.treeNode.FileUseHistoryId,
        )) > 1
          ? this.close(this.physical ? 'all' : 'single')
          : this.close('single');

        ErrorHelper.addSnackbarMessage('File has been deleted successfully.', 'success');
      } catch {
        ErrorHelper.addSnackbarMessage('Delete file failed', 'error', '', true);
        this.close();
      } finally {
        this.deleting = false;
      }
    },
    close(deleted?: 'single' | 'all') {
      this.$emit('close', deleted);
    },
  },
});
