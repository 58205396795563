
import Vue from 'vue';

import { mapGetters } from 'vuex';

import ComponentService from '@/services/componentService';

export default Vue.extend({
  props: {
    gridLoaded: Boolean,
    isOpenRecent: Boolean,
    hideButton: Boolean,
  },
  data(): {
    recentApps: any;
    drawer: boolean;
    showRecents: boolean;
    maxLengthTrackingNumber: number;
  } {
    return {
      recentApps: null,
      drawer: false,
      showRecents: false,
      maxLengthTrackingNumber: 76,
    };
  },
  computed: {
    ...mapGetters(['companyId']),
  },
  watch: {
    isOpenRecent() {
      this.drawer = !this.drawer;
      if (this.drawer) {
        this.getRecentApps();
      }
    },
    companyId: {
      handler() {
        this.getRecentApps();
      },
      immediate: true,
    },
  },
  methods: {
    openRecentApp(appComponent: any): void {
      this.$emit('openAppEvent', appComponent);
    },
    getRecentAppsSuccess(result: any): void {
      this.recentApps = result;
      this.showRecents = result != null && result.length > 0;
      this.$emit('changeRecentsLoaded', true);
    },
    truncateTrackingNumber(trackingNumber: string): string {
      if (trackingNumber == null || trackingNumber.length <= this.maxLengthTrackingNumber) {
        return trackingNumber;
      } else {
        return trackingNumber.substr(1, this.maxLengthTrackingNumber).trim();
      }
    },
    getRecentApps(): void {
      if (this.companyId) {
        this.$emit('changeRecentsLoaded', false);
        new ComponentService().getRecentApps(
          this.getRecentAppsSuccess,
          `./AppSelector/GetRecentApps?companyId=${this.companyId}`,
        );
      }
    },
  },
});
