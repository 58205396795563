
import Vue from 'vue';

import { mapGetters, mapMutations, mapState } from 'vuex';

import SequenceContactList from '@/components/applicationWizard/SequenceContactList.vue';
import SequenceInformation from '@/components/applicationWizard/SequenceInformation.vue';
import ErrorHelper from '@/helpers/errorHelper';
import { EventBus } from '@/helpers/EventBus';
import { BadRequestError } from '@/models/Errors';
import {
  Contact,
  ContactDetailType,
  ContactType,
  SubType,
  Unit,
  UnitType,
} from '@/models/WebPublish';
import { applicationsService } from '@/services';
import { RootMutations } from '@/store';

export default Vue.extend({
  components: {
    SequenceInformation,
    SequenceContactList,
  },
  props: {
    applicationId: Number,
  },
  data(): {
    codeSystemId: number | null;
    appTypeId: number | null;
    unitTypes: UnitType[];
    subTypes: SubType[];
    units: Unit[];
    contactTypes: ContactType[];
    contactDetailTypes: ContactDetailType[];
    unitTypeId: number | null;
    subTypeId: number | null;
    unitId: number | null;
    applicant: string;
    sequenceNumber: number;
    description: string;
    contacts: Contact[];
    loading: boolean;
    saving: boolean;
  } {
    return {
      codeSystemId: null,
      appTypeId: null,
      unitTypes: [],
      subTypes: [],
      units: [],
      contactTypes: [],
      contactDetailTypes: [],
      contacts: [],
      unitTypeId: null,
      subTypeId: null,
      unitId: null,
      applicant: '',
      sequenceNumber: 1,
      description: '',
      loading: true,
      saving: false,
    };
  },
  computed: {
    ...mapState(['selectedApplicationId', 'selectedFormat', 'selectedRegion', 'selectedSubId']),
    ...mapGetters(['companyId']),
    canClickSave(): boolean {
      return !!(
        this.unitTypeId &&
        this.subTypeId &&
        this.applicant &&
        this.sequenceNumber &&
        this.contacts &&
        this.contacts.length > 0
      );
    },
  },
  async beforeMount() {
    await Promise.all([this.loadApp(), this.loadContactTypes(), this.loadContactDetailTypes()]);
  },
  methods: {
    ...mapMutations({
      close: RootMutations.closeModalWindow,
      changeAppTitle: 'changeAppTitle',
    }),
    async loadApp(): Promise<void> {
      const app = await applicationsService.getApplicationDetails(
        this.companyId,
        this.selectedApplicationId,
        this.selectedFormat,
        this.selectedRegion,
      );
      const sub = app.Submissions.find((x) => x.Id == this.selectedSubId);

      if (sub) {
        this.applicant = sub.Applicant;
        this.sequenceNumber = sub.SubmissionUnitNumber;
        this.subTypeId = sub.SubmissionTypeId;
        this.unitTypeId = sub.UnitTypeId;
        this.unitId = sub.UnitId;
        this.description = sub.Title;
      }

      this.codeSystemId = app.CodeSystemId;
      this.appTypeId = app.AppTypeId;

      await Promise.all([
        this.loadUnitTypes(),
        this.loadSubTypes(),
        this.loadUnits(),
        this.loadContacts(),
      ]);

      this.loading = false;
    },
    async setUnitTypeId(unitTypeId: number) {
      this.unitTypeId = unitTypeId;
      this.subTypeId = null;
      this.unitId = null;

      await this.loadSubTypes();
      await this.loadUnits();
      await this.loadContacts();
    },
    async setSubTypeId(subTypeId: number) {
      this.subTypeId = subTypeId;
      await this.loadUnits();
      await this.loadContacts();
    },
    async setUnitId(unitId: number) {
      this.unitId = unitId;
      await this.loadContacts();
    },
    setApplicant(applicant: string) {
      this.applicant = applicant;
    },
    setSequenceNumber(sequenceNumber: number) {
      this.sequenceNumber = sequenceNumber;
    },
    setDescription(description: string) {
      this.description = description;
    },
    setContacts(contacts: Contact[]) {
      this.contacts = contacts;
    },
    async loadUnitTypes() {
      this.unitTypes =
        this.codeSystemId && this.appTypeId
          ? await applicationsService.getUnitTypes(this.codeSystemId, this.appTypeId)
          : [];
    },
    async loadSubTypes() {
      this.subTypes =
        this.codeSystemId && this.unitTypeId
          ? await applicationsService.getSubTypes(this.codeSystemId, this.unitTypeId)
          : [];
    },
    async loadUnits() {
      this.units = await applicationsService.getAllowedUnits(
        this.companyId,
        this.selectedApplicationId,
        this.selectedFormat,
        this.selectedRegion,
        this.unitTypeId,
      );
    },
    async loadContactTypes() {
      this.contactTypes = await applicationsService.getContactTypes();
    },
    async loadContactDetailTypes() {
      this.contactDetailTypes = await applicationsService.getContactDetailTypes();
    },
    async loadContacts() {
      this.contacts =
        this.companyId && this.unitId && this.selectedSubId
          ? await applicationsService.getContactsForSub(
              this.companyId,
              this.unitId,
              this.selectedSubId,
              this.sequenceNumber,
            )
          : this.contacts.filter((x) => x.SubId == this.selectedSubId);
    },
    async loadContactsForUnit() {
      this.contacts =
        this.companyId && this.unitId && this.selectedSubId
          ? await applicationsService.getContactsForUnit(this.companyId, this.unitId)
          : [];
    },
    async save(): Promise<boolean> {
      if (!this.unitTypeId || !this.subTypeId) {
        ErrorHelper.addSnackbarMessage(
          'Updating submission failed.',
          'error',
          `unitTypeId = ${this.unitTypeId} subTypeId = ${this.subTypeId}`,
          true,
        );
        return false;
      }

      this.saving = true;
      try {
        await applicationsService.updateSubmission(this.companyId, this.selectedApplicationId, {
          SubId: this.selectedSubId,
          Applicant: this.applicant,
          SubmissionUnitNumber: this.sequenceNumber,
          SubmissionTypeId: this.subTypeId,
          UnitTypeId: this.unitTypeId,
          UnitId: this.unitId,
          Title: this.description,
          Contacts: this.contacts,
        });

        this.close();
        ErrorHelper.addSnackbarMessage('The submission has been updated successfully.', 'success');
        this.$nextTick(() => {
          EventBus.$emit('mainComponent_refreshTree');
        });
        return true;
      } catch (e) {
        if (e instanceof BadRequestError) {
          ErrorHelper.addSnackbarMessage(e.message, 'error', '');
        } else {
          ErrorHelper.addSnackbarMessage(
            'Updating submission failed.',
            'error',
            (e as Error).message,
            true,
          );
        }

        return false;
      } finally {
        this.saving = false;
      }
    },
    closeDialog() {
      this.close();
    },
  },
});
