
import Vue from 'vue';

import { chain } from 'lodash';

import {
  AgencyType,
  AgencyTypeCode,
  AppType,
  CrossReferenceApplication,
} from '@/models/WebPublish';

export default Vue.extend({
  props: {
    agencyTypes: { type: Array as () => Array<AgencyType>, default: () => [] },
    agencyTypeCodes: {
      type: Array as () => Array<AgencyTypeCode>,
      default: () => [],
    },
    appTypes: { type: Array as () => Array<AppType>, default: () => [] },
    agencyTypeCodeWarning: {
      type: String as () => string | null,
      default: () => null,
    },
    selectedAppTypeId: {
      type: Number as () => number | null,
      default: () => null,
    },
    selectedAgencyTypeCodeId: {
      type: Number as () => number | null,
      default: () => null,
    },
    appNumber: { type: String },
    crossReferenceApplications: {
      type: Array as () => Array<CrossReferenceApplication>,
      default: () => [],
    },
    uniqueAppNumberAndAppType: { type: Boolean, default: true },
    finishButtonName: { type: String, default: 'Next' },
  },
  data: () => ({
    dialog: false,
    appNumberRules: [
      (v: string) =>
        !v ||
        (/^\d{6}$/.test(v) && parseInt(v) > 0) ||
        'Please enter the proper format for a US Application Number (Example : 123456)',
    ],
  }),
  computed: {
    showOnlyAppTypes() {
      return this.appTypes.filter((x) => x.Show);
    },
    showAgencyTypeCodes() {
      return chain(this.agencyTypeCodes)
        .map((agencyTypeCode) => ({
          Id: agencyTypeCode.Id,
          Description: this.agencyTypes.find(
            (agencyType) => agencyType.Id === agencyTypeCode.AgencyTypeId,
          )!.Description,
        }))
        .orderBy((x) => x.Description)
        .value();
    },
  },
  methods: {
    addItem() {
      const crossReferenceApplications = [...this.crossReferenceApplications];
      crossReferenceApplications.push({ AppTypeId: null, AppNumber: '' });
      this.$emit('onCrossReferenceApplicationsChange', crossReferenceApplications);
    },
    deleteItem(item: CrossReferenceApplication) {
      if (this.crossReferenceApplications.length > 1) {
        this.$emit(
          'onCrossReferenceApplicationsChange',
          this.crossReferenceApplications.filter((x) => x !== item),
        );
      } else {
        item.AppTypeId = null;
        item.AppNumber = '';
      }
    },
    onAppTypeSelect(appTypeId: number) {
      this.$emit('onUniqueAppNumberAndAppTypeChange', true);
      this.$emit('onAppTypeSelect', appTypeId);
    },
    onAppNumberChange(appNumber: string) {
      this.$emit('onUniqueAppNumberAndAppTypeChange', true);
      this.$emit('onAppNumberChange', appNumber);
    },
    onAgencyTypeCodeSelect(agencyTypeCodeId: number) {
      this.$emit('onAgencyTypeCodeSelect', agencyTypeCodeId);
    },
    onAppNumberBlur() {
      if (
        this.appNumber.length &&
        this.$refs.appNumber &&
        (this.$refs.appNumber as any).validate()
      ) {
        this.onAppNumberChange(this.appNumber.padStart(6, '0'));
      }
    },
  },
});
