
import Vue from 'vue';

import NineDot from 'common-vue-components/components/Dropdowns/NineDot.vue';

import Info from '@/components/dropdowns/Info.vue';

export default Vue.extend({
  components: {
    Info,
    NineDot,
  },
  props: {
    drawerState: {
      type: Boolean,
      default: false,
    },
    userGuideUrl: {
      type: String,
      required: true,
    },
    webApplicationName: {
      type: String,
      required: true,
    },
  },
  data(): {
    drawer: boolean;
  } {
    return {
      drawer: false,
    };
  },
  watch: {
    drawerState(): void {
      this.drawer = this.drawerState;
    },
    drawer(): void {
      if (this.drawer === false) {
        this.$emit('changeDrawerState');
      }
    },
  },
  methods: {
    signOut(): void {
      location.href = './Main/SignOut';
    },
  },
});
