
import Vue from 'vue';

import { mapGetters, mapState } from 'vuex';    

import Dialog from '@/components/popups/Dialog.vue';
import ErrorHelper from '@/helpers/errorHelper';
import { EventBus } from '@/helpers/EventBus';
import { BadRequestError } from '@/models/Errors';
import { repeatableSectionsService } from '@/services';

export default Vue.extend({
  components: {
    // eslint-disable-next-line vue/no-reserved-component-names
    Dialog,
  },
  props: {
    open: { type: Boolean },
    treeNode: { type: Object, default: null },
  },
  data(): {
    deleting: boolean;
    reused: boolean;
    physical: boolean;
  } {
    return {
      deleting: false,
      reused: false,
      physical: false,
    };
  },
  computed: {
    ...mapGetters(['companyId']),
    ...mapState(['selectedApplicationId']),
    name(): string {
      return this.treeNode?.Title;
    },
  },
  methods: {
    async onDeleteClick() {
      this.deleting = true;
      try {
        await repeatableSectionsService.deleteRepeatableSection(
          this.companyId,
          this.selectedApplicationId,
          this.treeNode.SubId,
          this.treeNode.RepeatableSectionId,
        );
        ErrorHelper.addSnackbarMessage('Context group has been deleted successfully.', 'success');
        this.close(true);
      } catch (error) {
          let message = 'Context group delete failed'
          if (error instanceof BadRequestError) {
               message = error.message;                            
          }
          ErrorHelper.addSnackbarMessage(message, 'error', '', true);
          this.$nextTick(() => EventBus.$emit('mainComponent_refreshTree'));
          this.close(true);
      } finally {
        this.deleting = false;
      }
    },
    close(deleted: boolean) {
      this.$emit('close', deleted);
    },
  },
});
