
import Vue from 'vue';

import $ from 'jquery';
import 'jquery-resizable-dom';
import VueVisible from 'vue-visible';
import { mapGetters, mapMutations, mapState } from 'vuex';

import AppDeleteDialog from '@/components/appselector/AppDeleteDialog.vue';
import AppSelector from '@/components/appselector/AppSelector.vue';
import DetailsView from '@/components/details/Details.vue';
import HeaderLayout from '@/components/details/HeaderLayout.vue';
import Inventory from '@/components/details/Inventory.vue';
import ViewerOverlay from '@/components/details/ViewerOverlay.vue';
import XmlViewer from '@/components/details/XmlViewer.vue';
import AppLoader from '@/components/loaders/AppLoader.vue';
import PublishLoader from '@/components/loaders/PublishLoader.vue';
import TreeLocationNav from '@/components/loaders/TreeLocationNav.vue';
import ViewerToolbar from '@/components/navs/ViewerToolbar.vue';
import CopyFailedAlert from '@/components/popups/CopyFailedAlert.vue';
import TreeLegend from '@/components/popups/TreeLegend.vue';
import Trees from '@/components/tree/Trees.vue';
import { ModalWindow } from '@/enums';
import { Regions } from '@/enums/Regions';
import { FileTypes, Panels, TreeType } from '@/helpers/Enums';
import { EventBus } from '@/helpers/EventBus';
import { TreeHelper } from '@/helpers/treeHelper';
import { FileComponent } from '@/models/fileComponent';
import TreeComponent from '@/models/treeComponent';
import UserRightsComponent from '@/models/userRightsComponent';
import UserSettingsComponent from '@/models/userSettingsComponent';
import { ViewHistory } from '@/pdf/history-tracker';
import ComponentService from '@/services/componentService';
import { RootMutations } from '@/store';

require('../Content/Main/Main.css');
require('../Content/PreviewPane/PreviewPane.css');
require('../Content/Common/Common.css');
/* eslint @typescript-eslint/no-var-requires: "off" */
const Multipane: any = require('vue-multipane').Multipane;
/* eslint @typescript-eslint/no-var-requires: "off" */
const MultipaneResizer: any = require('vue-multipane').MultipaneResizer;

Vue.use(VueVisible);

export default Vue.extend({
  components: {
    TreeLegend,
    DetailsView,
    HeaderLayout,
    AppLoader,
    AppSelector,
    Multipane,
    MultipaneResizer,
    Inventory,
    TreeLocationNav,
    ViewerToolbar,
    Trees,
    XmlViewer,
    ViewerOverlay,
    PublishLoader,
    CopyFailedAlert,
    AppDeleteDialog,
  },
  props: {
    activeTree: { type: Number, default: TreeType.Origin },
    appTitle: String,
    canGoBack: Boolean,
    canGoForward: Boolean,
    drawerState: Boolean,
    failedLinkToShare: { type: String, default: '' },
    isFullScreen: Boolean,
    isDocumentLoaded: Boolean,
    isSearchOpen: Boolean,
    isUnsupportedFile: Boolean,
    // eslint-disable-next-line vue/require-valid-default-prop
    loadingInformation: {
      type: Array as () => boolean[],
      // eslint-disable-next-line vue/require-valid-default-prop
      default: [false, false, false, false, false],
    },
    loadedDoc: {
      type: Object as () => FileComponent,
      default: new FileComponent(),
    },
    loadedPdfPath: { type: Object, default: null },
    nonAppActiveView: { type: Number, default: Panels.Viewer },
    searchText: { type: String, default: '' },
    selectedNode: {
      type: Object as () => TreeComponent,
      default: new TreeComponent(),
    },
    showAppSelector: Boolean,
    showContextMenu: Boolean,
    showDetails: Boolean,
    showHide: { type: Boolean, default: true },
    showTrees: { type: Boolean, default: true },
    treeLegendState: Boolean,
    treeSearchResults: { type: Array, default: () => [] },
    userSettings: {
      type: Object as () => UserSettingsComponent,
      default: new UserSettingsComponent(),
    },
    userRights: {
      type: Object as () => UserRightsComponent,
      default: new UserRightsComponent(),
    },
    openMenuCount: { type: Number, default: 0 },
    fileToDownload: { type: String, default: '' },
    recentsLoaded: Boolean,
    showOverlayMessage: { type: String, default: '' },
    inventoryNodeToOpen: { type: Object, default: null },
    userGuideUrl: { type: String, default: '' },
    loadedPdfDoc: { type: Boolean },
    isWebPublish: Boolean,
    isWebPublishReleased: { type: Boolean, default: false },
    linkShareToOpen: { type: String, default: null },
  },
  data(): {
    bind: boolean;
    inventoryNode: TreeComponent | null;
    loaded: boolean;
    showAppLoader: boolean;
    isPublishing: boolean;
    showPreviewPane: boolean;
    showPdfViewer: boolean;
    treeExpanded: boolean;
    showPdfOverlay: boolean;
    isOpenRecent: boolean;
    tooltips: any[];
    treeObj: any;
    selectedDetailsNode: any;
    isDetailsVisible: boolean;
    topValue: number;
    leftValue: number;
    treeLegend: boolean;
    treeLegendTop: number;
    treeLegendLeft: number;
  } {
    return {
      bind: false,
      treeLegend: false,
      inventoryNode: new TreeComponent(),
      loaded: false,
      topValue: 0,
      leftValue: 0,
      isDetailsVisible: false,
      showAppLoader: false,
      isPublishing: false,
      showPreviewPane: true,
      showPdfViewer: true,
      treeExpanded: true,
      showPdfOverlay: false,
      isOpenRecent: false,
      tooltips: [],
      treeObj: {},
      selectedDetailsNode: {},
      treeLegendTop: 0,
      treeLegendLeft: 0,
    };
  },
  computed: {
    ...mapGetters(['companyId']),
    ...mapState([
      'selectedApplicationId',
      'selectedFormat',
      'selectedRegion',
      'selectedAppSelectorComponent',
    ]),
    showPublishLoader(): boolean {
      return this.isPublishing;
    },
    showDeletingApplication(): boolean {
      return this.$store.state.modalWindow === ModalWindow.deleteApplication;
    },
  },
  watch: {
    linkShareToOpen() {
      this.loaded = true;
    },
    loadingInformation() {
      const trueCount = this.loadingInformation.filter((val: boolean) => val === true).length;
      if (trueCount === 5) {
        this.showAppLoader = false;
      }
    },
    selectedNode() {
      if (this.selectedNode.Id === '') {
        this.refreshEmptySelectedNodeDisplay();
        return;
      }

      const treeNode = TreeHelper.makeTreeComponent(this.selectedNode);
      const fuhId = treeNode.FileUseHistoryId;

      if (fuhId) {
        this.getFileFromSelection(
          fuhId,
          treeNode.FileType,
          treeNode.FileUseStateId === 1 && treeNode.ActionType === 2,
          treeNode.DoesExist,
          treeNode.HasXfa,
          treeNode.IsPlaceholder,
        );
      } else {
        this.inventoryNode = treeNode;
        this.$emit('changeNonAppActiveView', Panels.Details);
      }
    },
    showAppSelector() {
      if (!this.showAppSelector) {
        this.setView(this.nonAppActiveView);
      }
    },
    nonAppActiveView() {
      this.showPreviewPane =
        this.nonAppActiveView === (Panels.Viewer as number) ||
        this.nonAppActiveView === (Panels.XmlViewer as number) ||
        this.nonAppActiveView === (Panels.TxtViewer as number) ||
        this.nonAppActiveView === (Panels.Error as number) ||
        this.nonAppActiveView === (Panels.ViewerOverlay as number) ||
        this.nonAppActiveView === (Panels.ImageViewer as number);
      this.showPdfViewer = this.nonAppActiveView === (Panels.Viewer as number);
    },
    changeOpenMenuCount() {
      this.showPdfOverlay = this.openMenuCount > 0;
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-empty-function
    fileToDownload(path: string): void {},
  },
  mounted(): void {
    window.addEventListener("mouseup", this.mouseup);
    EventBus.$on('treeLegendShow', (main: HTMLElement) => {
      if (main) {
        const rect = main.getBoundingClientRect();
        this.treeLegendTop = rect.top;
        this.treeLegendLeft = rect.right;
        this.treeLegend = true;
      }
    });
    EventBus.$on('tooltipOnHover', this.tooltipOnHover);
    EventBus.$on('tooltipOffHover', this.tooltipOffHover);
    EventBus.$on('showDetailsPopup', (t: any) => this.detailsCall(t));

    EventBus.$on('setTreeObj', (mainItem: any) => {
      this.treeObj = mainItem.sub;
    });

    // rework this to be more vue-like
    this.$nextTick(() => {
      ($('.trees') as any).resizable({
        handleSelector: '.splitter',
        resizeHeight: false,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        onDragEnd(e: any, el: any, opt: any): void {
          EventBus.$emit('checkPdfToolbar');
        },
      });

      if (!this.getLinkShareRequest()) {
        this.isAppOpen();
      }
    });

    this.$emit('getUserSettings');
    this.$emit('getUserRights');
    this.initializeFullscreenEvents();

    EventBus.$on('changeFileToDownload', (filePath: string) => {
      this.$emit('changeFileToDownload', filePath);
    });

    EventBus.$on('publishStateChanged', (isPublishing: boolean) => {
      this.isPublishing = isPublishing;
    });
  },
  methods: {
    ...mapMutations({
      close: RootMutations.closeModalWindow,
      toggleTreeLegendState: 'toggleTreeLegendState',
    }),
    mousedown() {
      const plane = this.$refs.horizontalResizerPlane as HTMLElement;
      plane.style.display = "flex";
    },

    mouseup() {
      const plane = this.$refs.horizontalResizerPlane as HTMLElement;
      plane.style.display = "none";
    },
    detailsCall(main: any) {
      if (!main.item && this.selectedNode) {
        this.selectedDetailsNode = this.selectedNode;
      } else {
        this.selectedDetailsNode = main.item;
      }
      if (!this.showDetails) {
        let position = 0;
        if (main.location === 1) {
          const popup = (this.$refs.headerLayoutId as any).$el as HTMLDivElement;
          position = popup.offsetHeight + popup.offsetTop + 60;
        } else if (main.location === 2) {
          const popup2 = (this.$refs.inventoryGrid as any).$el as HTMLDivElement;
          const child = popup2.getElementsByClassName('inventory-toolbar')[0] as HTMLDivElement;
          position = child.offsetHeight + popup2.offsetTop + 60;
        }
        this.isDetailsVisible = false;
        setTimeout(() => {
          this.setTopAndLeft(position);
        }, 100);

        this.$emit('showDetails');
      }

      const closeDetailsEvent = (e: any) => this.closeDetails(e);
      window.addEventListener(
        'click',
        (e) => {
          const reviewNode = e.target as HTMLDivElement;
          const hasParentWithId = reviewNode.closest('#detailsContainerCard') !== null;
          if (!hasParentWithId) {
            closeDetailsEvent(e);
          }
        },
        false,
      );
    },
    clickOutsideTreeLegend() {
      if (this.treeLegend) {
        this.treeLegend = false;
        this.toggleTreeLegendState(false);
      }
    },
    setTopAndLeft(popup: number): void {
      const popupLeft = (this.$refs.treesId as any).$el as HTMLDivElement;
      if (this.$vuetify.breakpoint.mdAndUp) {
        this.topValue =
          this.topValue + popup > window.innerHeight ? window.innerHeight - 20 - popup : popup;
        this.leftValue =
          this.leftValue + popupLeft.offsetWidth > window.innerWidth
            ? window.innerWidth - 20 - popupLeft.offsetWidth
            : popupLeft.clientWidth;
      } else {
        this.topValue = window.innerHeight / 2 - popup / 2;
        this.leftValue = window.innerWidth / 2 - popupLeft.offsetWidth / 2;
      }
      this.isDetailsVisible = true;
    },
    closeDetails(event: MouseEvent): void {
      this.isDetailsVisible = false;
      const target = event.target as Element;

      const container = $('.absolute-details');
      if (!container.is(target) && container.has(target).length === 0) {
        this.clearNodeAndRemoveListener(
          'selectedDetailsNode',
          'ornamentButton',
          target,
          this.closeDetails,
        );
        if (this.showDetails) {
          this.$emit('showDetails');
        }
      }
    },
    clearNodeAndRemoveListener(
      nodeId: string,
      containedClass: string,
      target: Element,
      listener: any,
    ): void {
      const element = document.querySelector('#' + nodeId);
      if (element && !target.classList.contains(containedClass)) {
        element.classList.remove('grey', 'lighten-3');
        element.removeAttribute('id');
      }

      document.removeEventListener('click', listener);
    },
    getIcon(itemMain: any, onlyIcon: boolean): string {
      const item = itemMain.item;
      let htmlString = '<div class="tree-node';

      if (item.NodeTypeId === 5) {
        htmlString += ' no-info">';
      } else {
        htmlString += '">';
      }

      if (item.NodeTypeId === 5) {
        htmlString += '<span class="k-icon k-i-fileuse-set"></span>';
      } else if (item.NodeTypeId === 6) {
        if (item.IsReused) {
          if (!item.DoesExist) {
            htmlString += `<span class="k-icon k-i-file-reused-${item.ActionType}-missing"></span>`;
          } else {
            htmlString += `<span class="k-icon k-i-file-reused-${item.ActionType}"></span>`;
          }
        } else {
          if (item.IsPlaceholder) {
            if (!item.DoesExist) {
              htmlString += `<span class="k-icon k-i-placeholder-${item.ActionType}-missing"></span>`;
            } else {
              htmlString += `<span class="k-icon k-i-placeholder-${item.ActionType}"></span>`;
            }
          } else {
            if (!item.DoesExist) {
              htmlString += `<span class="k-icon k-i-file-${item.ActionType}-missing"></span>`;
            } else {
              htmlString += `<span class="k-icon k-i-file-${item.ActionType}"></span>`;
            }
          }
        }
      } else if (item.NodeTypeId === 2 || item.NodeTypeId === 3 || item.NodeTypeId === 4) {
        if (item.HasFiles) {
          htmlString += '<span class="k-icon k-i-folder-full"></span>';
        } else {
          htmlString += '<span class="k-icon k-i-folder-empty"></span>';
        }
      } else if (item.NodeTypeId === 0) {
        if (item.IsGrouped) {
          htmlString += '<span class="k-icon k-i-grouped-sequence"></span>';
        } else {
          htmlString += '<span class="k-icon k-i-sequence"></span>';
        }
      } else if (item.NodeTypeId === 1) {
        htmlString += '<span class="k-icon k-i-regulatory-activity"></span>';
      }

      if (!onlyIcon) {
        htmlString += `<span class="tree-item"> ${item.Title}</span>`;

        if (item.MetadataPath) {
          htmlString += `<br /><span class="tocPath"> ${item.MetadataPath}</span>`;
        }
      }

      htmlString += '</div>';

      return htmlString;
    },
    parseDisplayOrder(title: string): number {
      const r = title.match(/\d+/);
      if (r?.length) {
        return parseInt(r[0]);
      }

      return 0;
    },
    hideDeletingApplication(value: boolean): void {
      !value && this.close();
    },
    refreshInventory(): void {
      const grid = this.$refs.inventoryGrid as any;
      grid?.refreshData && grid.refreshData();
    },
    isAppOpen(): void {
      this.$nextTick(() => {
        this.isAppOpenSuccess(!!this.selectedApplicationId);
      });
    },
    getLinkShareRequest(): any {
      return new URL(window.location.href).searchParams.get('id');
    },
    isAppOpenSuccess(result: any): void {
      result = result as boolean;
      if (!result) {
        this.setView(Panels.AppSelector);
        this.$nextTick(() => {
          this.loaded = true;
          EventBus.$emit('mainComponent_openApp');
        });
      } else {
        this.showAppLoader = true;
        this.setAppTitle();
        this.setView(Panels.Viewer);
        this.$nextTick(() => {
          this.loaded = true;
          EventBus.$emit('mainComponent_refreshTree');
        });
      }
    },
    refreshDisplay(): void {
      this.$emit('changeSelectedNode', new TreeComponent());
      this.showAppLoader = true;
      this.setAppTitle();
      this.$emit('changeSearchText', '');
      if (this.showAppSelector) {
        this.$emit('changeAppSelector');
        ViewHistory.clear();
      }

      EventBus.$emit('clearSnackbar');
      EventBus.$emit('mainComponent_refreshTree');
      this.$emit('changeTreeSearchResults', []);
      EventBus.$emit('treeToolbar_resetSearch');

      this.refreshEmptySelectedNodeDisplay();
      this.inventoryNode = null;
    },

    setAppTitle(): void {
      this.$nextTick(() => {
        if (this.selectedAppSelectorComponent) {
          const appTitle = `${this.getAppType()} ${
            this.selectedAppSelectorComponent.AppNumber ||
            this.selectedAppSelectorComponent.TrackingNumbers
          }`;
          this.$emit('changeAppTitle', appTitle);
        }
      });
    },
    getAppType(): string {
      switch (this.selectedRegion) {
        case Regions.EU:
        case Regions.AU:
        case Regions.GCC:
          return '';
        default:
          return this.selectedAppSelectorComponent?.AppType || '';
      }
    },
    setView(view: Panels): void {
      if (view === null) {
        return;
      }

      switch (view) {
        case Panels.Viewer:
        case Panels.Details:
          this.$emit('changeNonAppActiveView', view);
          break;
        case Panels.AppSelector:
          this.$emit('setShowAppSelector');
          break;
        case Panels.XmlViewer:
          this.$emit('changeNonAppActiveView', view);
          break;
      }
    },
    getFileFromSelection(
      fuhId: number,
      fileType: number | null,
      isDeletePlaceholder = false,
      Exists = true,
      hasXfa = false,
      isPlaceholder = false,
    ): void {
      if (fuhId) {
        this.$emit(
          'changeLoadedDoc',
          new FileComponent(
            fuhId,
            fileType as FileTypes,
            isDeletePlaceholder,
            Exists,
            null,
            hasXfa,
            isPlaceholder,
          ),
        );
        if (fileType === FileTypes.PDF) {
          this.$emit('changeNonAppActiveView', Panels.Viewer);
        }
      }
    },
    onFullScreenChange(): void {
      const fullScreenElement =
        document.fullscreenElement ||
        document.msFullscreenElement ||
        document.mozFullScreenElement ||
        document.webkitFullscreenElement;

      if (fullScreenElement == null) {
        this.$emit('changeIsFullScreen', false);
      }
    },
    initializeFullscreenEvents(): void {
      if (document.onfullscreenchange === null)
        document.onfullscreenchange = this.onFullScreenChange;
      else if (document.onmsfullscreenchange === null)
        document.onmsfullscreenchange = this.onFullScreenChange;
      else if (document.onmozfullscreenchange === null)
        document.onmozfullscreenchange = this.onFullScreenChange;
      else if (document.onwebkitfullscreenchange === null)
        document.onwebkitfullscreenchange = this.onFullScreenChange;
    },
    tooltipOnHover(sender: any, message: string): void {
      const tooltip = this.tooltips.filter((x) => x.message == message);
      const position = sender.getBoundingClientRect();
      const y = 15 + position.y;
      if (tooltip.length == 0) {
        this.tooltips.push({
          x: position.x,
          y,
          message,
          show: true,
        });
      } else {
        tooltip[0].show = true;
        tooltip[0].x = position.x;
        tooltip[0].y = y;
      }
    },
    tooltipOffHover(message: string): void {
      (this.tooltips.filter((x) => x.message == message)[0] || {}).show = false;
    },
    warmServices() {
      setTimeout(() => {
        const services = new ComponentService();
        services.requestWrapper(
          {},
          () => false,
          () => false,
          'LinkToShare/CopyLink?',
          true,
        );
        services.requestWrapper(
          {},
          () => false,
          () => false,
          'Origin/GetSections?',
          true,
        );
      }, 1000);
    },
    refreshEmptySelectedNodeDisplay() {
      this.$emit('changeLoadedDoc', new FileComponent());
      this.$emit('changeIsDocumentLoaded', false);
      this.$emit('changeNonAppActiveView', Panels.Viewer);
      if (window.previewPane) {
        window.previewPane.clearDocumentAndHistory();
      }
    },
  },
});
