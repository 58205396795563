import { NodeType, TreeType } from '@/helpers/Enums';

export default class TreeComponent {
  public Id = '';

  public ReviewSectionId = 0;

  public HasSections = false;

  public HasFiles = false;

  public HasChildren: boolean = this.HasSections || this.HasFiles;

  public Expanded = false;

  public Title = '';

  public ParentId: number | null = null;

  public UnitId: number | null = null;

  public SubId: number | null = null;

  public SectionId: number | null = null;

  public RepeatableSectionId: number | null = null;

  public SectionExtensionId: number | null = null;

  public FileUseSetId: number | null = null;

  public FileUseHistoryId: number | null = null;

  public FileUseStateId: number | null = null;

  public FileId: number | null = null;

  public FileName: string | null = null;

  public FileIconImageUrl: string | null = null;

  public IsFiltered: boolean | null = null;

  public IsEmptyAllTheWayDown: boolean | null = null;

  public InRepeatGroup: boolean | null = null;

  public TreeTypeId: number | null = null;

  public MetadataPath: string | null = null;

  public NodeTypeId: number | null = null;

  public ActionType: number | null = null;

  public IsGrouped = false;

  public IsReused = false;

  public FileType: number | null = null;

  public SectionPath: string | null = null;

  public SpecId: number | null = null;

  public SubDisplayOrder: number | null = null;

  public DoesExist = false;

  public IsPlaceholder = false;

  public HasXfa = false;

  public AllowFiles: boolean | null = null;

  public WarnAddFiles: boolean | null = null;

  public AllowSectionExtensions: boolean | null = null;

  public StartsRepeatGroup: boolean | null = null;

  public TreeType: TreeType | null = null;

  public NodeType: NodeType | null = null;

  [key: string]: any;

  get(key: string): any {
    return this[key];
  }
}
