
import Vue from 'vue';

import SequenceContact from '@/components/applicationWizard/SequenceContact.vue';
import Dialog from '@/components/popups/Dialog.vue';
import { Contact, ContactDetailType, ContactType } from '@/models/WebPublish';

enum EditingMode {
  none,
  edit,
  delete,
}

export default Vue.extend({
  components: {
    SequenceContact,
    // eslint-disable-next-line vue/no-reserved-component-names
    Dialog,
  },
  props: {
    contactTypes: { type: Array as () => Array<ContactType>, default: () => [] },
    contactDetailTypes: { type: Array as () => Array<ContactDetailType>, default: () => [] },
    contacts: { type: Array as () => Array<Contact>, default: () => [] },
    companyId: { type: Number },
    subId: { type: Number },
  },
  data: () => ({
    currentContact: null as Contact | null,
    editingMode: EditingMode.none,
  }),
  computed: {
    showContactWindow(): boolean {
      return !!this.currentContact && this.editingMode === EditingMode.edit;
    },
    showDeleteContactDialog(): boolean {
      return !!this.currentContact && this.editingMode === EditingMode.delete;
    },
  },
  methods: {
    addContact() {
      this.currentContact = {
        Id: 0,
        GivenName: '',
        FamilyName: '',
        MiddleName: '',
        MiddleInitial: '',
        ContactTypeId: null,
        Organization: '',
        Details: [{ Id: 0, ContactDetailTypeId: null, Value: '' }],
        SubId: this.subId,
      } as Contact;
      this.editingMode = EditingMode.edit;
    },
    isEditable(contact: Contact): boolean {
      return contact.SubId === this.subId;
    },
    startContactEditing(contact: Contact) {
      this.currentContact = contact;
      this.editingMode = EditingMode.edit;
    },
    stopContactEditing() {
      this.currentContact = null;
      this.editingMode = EditingMode.none;
    },
    startContactDeleting(contact: Contact) {
      this.currentContact = contact;
      this.editingMode = EditingMode.delete;
    },
    stopContactDeleting() {
      this.currentContact = null;
      this.editingMode = EditingMode.none;
    },
    deleteContact() {
      this.$emit(
        'onChangeContacts',
        this.contacts.filter((x: Contact) => x !== this.currentContact),
      );
      this.stopContactDeleting();
    },
    getContactName(contact: Contact) {
      return `${contact.GivenName} ${contact.MiddleName} ${contact.MiddleInitial} ${contact.FamilyName}`.replace(
        / {2,}/g,
        ' ',
      );
    },
    getContactType(id: number): string {
      return this.contactTypes.find((x: ContactType) => x.Id == id)?.Description || '';
    },
    getContactDetailType(id: number): string {
      return this.contactDetailTypes.find((x: ContactDetailType) => x.Id == id)?.Description || '';
    },
    onSaveContact(contact: Contact) {
      const contacts = [...this.contacts];
      const indexOfCurrentItem = contacts.findIndex((x) => x === this.currentContact);

      if (indexOfCurrentItem > -1) {
        contacts[indexOfCurrentItem] = contact;
      } else {
        contacts.push(contact);
      }

      this.currentContact = null;
      this.$emit('onChangeContacts', contacts);
    },
  },
});
