import { ActionType, ContextMenuItemKey, FileUseState } from '@/enums';
import { NodeType, TreeType } from '@/helpers/Enums';
import GlobalHelper from '@/helpers/globalHelper';
import {
  ContextMenuContext,
  ContextMenuItem,
  ContextMenuItemStyle,
  ContextMenuSubItem,
} from '@/models';
import TreeComponent from '@/models/treeComponent';
import { Submission } from '@/models/WebPublish/Submission';
import { applicationsService, treeClipboardService } from '@/services';

class ContextMenuBuilder {
  private orderLookup: Map<ContextMenuItemKey, number> = new Map([
    // Common items
    [ContextMenuItemKey.copyLink, 1],
    [ContextMenuItemKey.details, 2],
    // Submission items
    [ContextMenuItemKey.publishSubmission, 10],
    [ContextMenuItemKey.editSubmission, 11],
    [ContextMenuItemKey.deleteSubmission, 12],
    // Section items
    [ContextMenuItemKey.add, -1],
    [ContextMenuItemKey.createNewContextGroup, 21],
    [ContextMenuItemKey.editContextGroup, 22],
    [ContextMenuItemKey.deleteContextGroup, 23],
    [ContextMenuItemKey.pasteAsReused, 24],
    [ContextMenuItemKey.editFolder, 25],
    [ContextMenuItemKey.deleteFolder, 26],
    // File items
    [ContextMenuItemKey.editFileName, 30],
    [ContextMenuItemKey.editFileTitle, 31],
    [ContextMenuItemKey.replaceFiles, 32],
    [ContextMenuItemKey.copyForReused, 33],
    [ContextMenuItemKey.pasteAsReused, 34],
    [ContextMenuItemKey.suspendFile, 35],
    [ContextMenuItemKey.removeSuspension, 36],
    [ContextMenuItemKey.downloadFile, 37],
    [ContextMenuItemKey.deleteFile, 38],
  ]);

  public async build(context: ContextMenuContext | null): Promise<ContextMenuItem[]> {
    const result: ContextMenuItem[] = [];

    if (!context || !context.selectedNode || context.selectedNode.NodeType == null) {
      return result;
    }

    result.push(...this.buildCommonItems());

    switch (context.selectedNode.NodeType) {
      case NodeType.FileUseHistory:
      case NodeType.FileUseSet:
        result.push(...(await this.buildFileItems(context)));
        break;
      case NodeType.Sub:
        result.push(...this.buildSubItems(context));
        break;
      case NodeType.Section:
      case NodeType.RepeatSection:
      case NodeType.SectionExtension:
        result.push(...(await this.buildSectionItems(context)));
        break;
    }

    return result.sort((a, b) => (a.order > b.order ? 1 : -1));
  }

  private buildCommonItems(): ContextMenuItem[] {
    const result: ContextMenuItem[] = [];

    result.push(
      new ContextMenuItem(
        ContextMenuItemKey.copyLink,
        'Copy Link',
        this.getOrderFor(ContextMenuItemKey.copyLink),
      ),
    );

    result.push(
      new ContextMenuItem(
        ContextMenuItemKey.details,
        'View Details',
        this.getOrderFor(ContextMenuItemKey.details),
      ),
    );

    return result;
  }

  private async buildFileItems(context: ContextMenuContext): Promise<ContextMenuItem[]> {
    const result: ContextMenuItem[] = [];
    const selectedNode: TreeComponent = context.selectedNode ?? new TreeComponent();

    // Common file items
    if (selectedNode.DoesExist) {
      result.push(
        new ContextMenuItem(
          ContextMenuItemKey.downloadFile,
          'Download',
          this.getOrderFor(ContextMenuItemKey.downloadFile),
        ),
      );
    }

    if (!context.isPublishMode) {
      return result;
    }

    // Publish file items
    if (selectedNode.DoesExist) {
      result.push(
        new ContextMenuItem(
          ContextMenuItemKey.copyForReused,
          'Copy For Reused',
          this.getOrderFor(ContextMenuItemKey.copyForReused),
        ),
      );
    }

    if (this.isNotCurrentAndLifecycle(selectedNode)) {
      if (!selectedNode.IsPlaceholder) {
        result.push(
          new ContextMenuItem(
            ContextMenuItemKey.editFileName,
            'Edit Name',
            this.getOrderFor(ContextMenuItemKey.editFileName),
          ),
        );

        result.push(
          new ContextMenuItem(
            ContextMenuItemKey.editFileTitle,
            'Edit Title',
            this.getOrderFor(ContextMenuItemKey.editFileTitle),
          ),
        );

        result.push(
          new ContextMenuItem(
            ContextMenuItemKey.replaceFiles,
            'Replaces',
            this.getOrderFor(ContextMenuItemKey.replaceFiles),
          ),
        );
      }

      // FileUseHistory specific items
      if (selectedNode.NodeType == NodeType.FileUseHistory) {
        switch (selectedNode.FileUseStateId) {
          case FileUseState.Current:
            {
              const subs = await this.getSuspensionSubItems(context);
              if (subs.length == 0) break;

              result.push(
                new ContextMenuItem(
                  ContextMenuItemKey.suspendFile,
                  'Suspend File',
                  this.getOrderFor(ContextMenuItemKey.suspendFile),
                  ContextMenuItemStyle.default,
                  false,
                  subs,
                ),
              );
            }
            break;
          case FileUseState.Deleted:
            result.push(
              new ContextMenuItem(
                ContextMenuItemKey.removeSuspension,
                'Remove Suspension',
                this.getOrderFor(ContextMenuItemKey.removeSuspension),
                ContextMenuItemStyle.danger,
              ),
            );
            break;
        }
      }

      if (
        selectedNode.FileUseStateId != FileUseState.Deleted &&
        selectedNode.ActionType != ActionType.Replace
      ) {
        result.push(
          new ContextMenuItem(
            ContextMenuItemKey.deleteFile,
            'Delete',
            this.getOrderFor(ContextMenuItemKey.deleteFile),
            ContextMenuItemStyle.danger,
          ),
        );
      }
    }

    return result;
  }

  private buildSubItems(context: ContextMenuContext): ContextMenuItem[] {
    const result: ContextMenuItem[] = [];

    if (!context.isPublishMode) {
      return result;
    }

    result.push(
      new ContextMenuItem(
        ContextMenuItemKey.editSubmission,
        'Edit Submission Unit',
        this.getOrderFor(ContextMenuItemKey.editSubmission),
      ),
    );

    result.push(
      new ContextMenuItem(
        ContextMenuItemKey.publishSubmission,
        'Publish Submission Unit',
        this.getOrderFor(ContextMenuItemKey.publishSubmission),
      ),
    );

    result.push(
      new ContextMenuItem(
        ContextMenuItemKey.deleteSubmission,
        'Delete Submission Unit',
        this.getOrderFor(ContextMenuItemKey.deleteSubmission),
        ContextMenuItemStyle.danger,
      ),
    );

    return result;
  }

  private async buildSectionItems(context: ContextMenuContext): Promise<ContextMenuItem[]> {
    const result: ContextMenuItem[] = [];

    if (
      !context.selectedNode ||
      !context.isPublishMode ||
      !this.isNotCurrentAndLifecycle(context.selectedNode)
    ) {
      return result;
    }

    const addItems = new Array<ContextMenuSubItem>();

    if (context.selectedNode.AllowFiles) {
      addItems.push(new ContextMenuSubItem(ContextMenuItemKey.addFiles, 'Files'));
    }

    if (context.selectedNode.AllowSectionExtensions) {
      addItems.push(new ContextMenuSubItem(ContextMenuItemKey.addFolder, 'Folders'));
    }

    if (context.selectedNode.StartsRepeatGroup) {
      addItems.push(
        new ContextMenuSubItem(ContextMenuItemKey.createNewContextGroup, 'Context Group'),
      );
    }

    if (addItems.length > 0) {
      result.push(
        new ContextMenuItem(
          ContextMenuItemKey.add,
          'Add',
          this.getOrderFor(ContextMenuItemKey.add),
          ContextMenuItemStyle.success,
          false,
          addItems,
        ),
      );
    }

    if (context.selectedNode.NodeType == NodeType.RepeatSection) {
      result.push(
        new ContextMenuItem(
          ContextMenuItemKey.editContextGroup,
          'Edit',
          this.getOrderFor(ContextMenuItemKey.editContextGroup),
        ),
      );

      result.push(
        new ContextMenuItem(
          ContextMenuItemKey.deleteContextGroup,
          'Delete',
          this.getOrderFor(ContextMenuItemKey.deleteContextGroup),
          ContextMenuItemStyle.danger,
        ),
      );
    }

    if (context.selectedNode.AllowFiles) {
      const hasClipboardItem = await treeClipboardService.hasItem(context.companyId);

      result.push(
        new ContextMenuItem(
          ContextMenuItemKey.pasteAsReused,
          'Paste As Reused',
          this.getOrderFor(ContextMenuItemKey.pasteAsReused),
          ContextMenuItemStyle.default,
          !hasClipboardItem,
        ),
      );
    }

    if (context.selectedNode.NodeType == NodeType.SectionExtension) {
      result.push(
        new ContextMenuItem(
          ContextMenuItemKey.editFolder,
          'Edit Folder',
          this.getOrderFor(ContextMenuItemKey.editFolder),
        ),
      );

      result.push(
        new ContextMenuItem(
          ContextMenuItemKey.deleteFolder,
          'Delete Folder',
          this.getOrderFor(ContextMenuItemKey.deleteFolder),
          ContextMenuItemStyle.danger,
        ),
      );
    }

    return result;
  }

  private getOrderFor = (key: ContextMenuItemKey) => this.orderLookup.get(key) ?? -1;

  private isNotCurrentAndLifecycle(treeNode: TreeComponent) {
    return treeNode.TreeType != TreeType.Current && treeNode.TreeType != TreeType.Lifecycle;
  }

  private async getSuspensionSubItems(context: ContextMenuContext): Promise<ContextMenuSubItem[]> {
    if (!context.selectedNode) return [];

    const subs = await applicationsService.getSubmissions(context.companyId, context.appId);

    const currentSub =
      context.activeTreeType == TreeType.Activities
        ? subs.filter((x) => x.UnitId == context.selectedNode?.UnitId)
        : subs.filter((x) => x.Id == context.selectedNode?.SubId);
    if (!currentSub || currentSub.length == 0) return [];

    return subs
      .sort((a, b) =>
        GlobalHelper.numbersDescendingSort(a.SubmissionUnitNumber, b.SubmissionUnitNumber),
      )
      .filter((x: Submission) => x.SubmissionUnitNumber > currentSub[0].SubmissionUnitNumber)
      .slice(0, 5)
      .map((x: Submission) => new ContextMenuSubItem(x.Id, x.UnitSubTitle));
  }
}

export const contextMenuBuilder = new ContextMenuBuilder();
