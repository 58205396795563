import { FileTypes } from '@/helpers/Enums';
import PdfDestination from '@/pdf/pdfDestination';

export default class NavigationHistory {
  public constructor(
    public fuhId: number,
    public dest: PdfDestination | null,
    public path: string | undefined | null,
    public filename: string | undefined,
    public type: FileTypes | null,
    public isPlaceholder: boolean = false,
  ) {}

  public prev: NavigationHistory | null = null;

  public next: NavigationHistory | null = null;

  public isSamePageAs(destination: PdfDestination | null): boolean {
    if (!this.dest || !destination) {
      return true;
    }
    return this.dest.page === destination.page;
  }

  public updateScroll(dest: PdfDestination | null): void {
    if (!this.dest || !dest) {
      return;
    }
    this.dest.x = dest.x;
    this.dest.y = dest.y;
    this.dest.zoom = dest.zoom;
  }
}
