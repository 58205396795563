
import Vue from 'vue';

import { mapGetters, mapState } from 'vuex';

import { contextMenuBuilder } from '@/builders/ContextMenuBuilder';
import DeleteFolderDialog from '@/components/details/DeleteFolderDialog.vue';
import DeleteSubmissionUnitDialog from '@/components/details/DeleteSubmissionUnitDialog.vue';
import PublishSubmissionUnitDialog from '@/components/details/PublishSubmissionUnitDialog.vue';
import AddFilesDialog from '@/components/popups/AddFilesDialog.vue';
import AddFolderDialog from '@/components/popups/AddFolderDialog.vue';
import CreateContextGroupDialog from '@/components/popups/CreateContextGroupDialog.vue';
import DeleteContextGroupDialog from '@/components/popups/DeleteContextGroupDialog.vue';
import DeleteFileDialog from '@/components/popups/DeleteFileDialog.vue';
import EditFileNameDialog from '@/components/popups/EditFileNameDialog.vue';
import EditFileTitleDialog from '@/components/popups/EditFileTitleDialog.vue';
import EditFolderDialog from '@/components/popups/EditFolderDialog.vue';
import ReplaceFilesDialog from '@/components/popups/ReplaceFilesDialog.vue';
import { ContextMenuItemKey, EntityDialogMode, ModalWindow } from '@/enums';
import { DownloadHelper } from '@/helpers/downloadHelper';
import { EventBus } from '@/helpers/EventBus';
import { LinkToShareHelper } from '@/helpers/linkToShareHelper';
import {
  ContextMenuContext,
  ContextMenuItem,
  ContextMenuItemStyle,
  CreateClipboardItemRequest,
  PasteClipboardItemRequest,
} from '@/models';
import InventoryComponent from '@/models/inventoryComponent';
import TreeComponent from '@/models/treeComponent';
import UserRightsComponent from '@/models/userRightsComponent';
import { treeClipboardService } from '@/services';
import ServiceComponent from '@/services/componentService';
import { RootMutations } from '@/store';

require('../../Content/Common/Common.css');

export default Vue.extend({
  components: {
    DeleteContextGroupDialog,
    DeleteSubmissionUnitDialog,
    DeleteFileDialog,
    EditFolderDialog,
    DeleteFolderDialog,
    PublishSubmissionUnitDialog,
    CreateContextGroupDialog,
    AddFolderDialog,
    AddFilesDialog,
    EditFileTitleDialog,
    EditFileNameDialog,
    ReplaceFilesDialog,
  },
  props: {
    treeNode: {
      type: Object as () => TreeComponent,
    },
    showOverlayMessage: {
      type: String,
    },
    getIcon: {
      type: Function,
    },
    search: {
      type: String,
    },
    treeObj: {
      type: Object as any,
    },
    showDetails: {
      type: Boolean,
    },
    userRights: {
      type: Object as () => UserRightsComponent,
      default: new UserRightsComponent(),
    },
    activeTree: Number,
    title: String,
    isWebPublish: Boolean,
  },
  data() {
    return {
      total: 0,
      refresh: false,
      emptySearch: false,
      loaded: false,
      editFileNameDialogOpen: false,
      addFilesDialogOpen: false,
      addFolderDialogOpen: false,
      createContextGroupDialogOpen: false,
      deleteContextGroupDialogOpen: false,
      deleteFolderDialogOpen: false,
      deleteFileDialogOpen: false,
      editFolderDialogOpen: false,
      replaceFilesDialogOpen: false,
      publishSubmissionUnitDialogOpen: false,
      deleteSubmissionUnitDialogOpen: false,
      entityDialogMode: EntityDialogMode.create,
      editFileTitleDialogOpen: false,
      showContextMenu: false,
      service: new ServiceComponent(),
      contextElement: '',
      menuYOffset: 0,
      contextMenuItems: [] as ContextMenuItem[],
      menuXOffset: 0,
      selectedFuh: null,
      selectedItem: null as TreeComponent | null, // Populate your items array as needed
      itemsPerPage: 10,
      dataLoading: false,
      currentPage: 1,
      selectedTitle: '', // Track selected rows
      contextMenuDropdownSelection: 0,
    };
  },
  computed: {
    contextMenuItemKey() {
      return ContextMenuItemKey;
    },
    contextMenuItemStyle() {
      return ContextMenuItemStyle;
    },
    ...mapGetters(['companyId']),
    ...mapState(['selectedApplicationId', 'selectedFormat', 'selectedRegion', 'highlightedItem']),
  },
  watch: {
    async treeNode(): Promise<void> {
      await this.refreshComponent(this.treeNode);

      this.$nextTick(function () {
        setTimeout(() => {
          EventBus.$emit(`changeInventoryHeight`);
        }, 500);
      });
    },
  },
  mounted() {
    EventBus.$on('refreshHeaderComponent', (node: any) => {
      this.refreshComponent(node);
    });
  },
  created() {
    window.addEventListener('resize', () => {
      EventBus.$emit(`changeInventoryHeight`);
    });
  },
  methods: {
    doesExist() {
      return this.treeNode.DoesExist;
    },
    onAddFilesDialogClose(uploaded: boolean) {
      this.addFilesDialogOpen = false;

      if (uploaded) {
        this.$nextTick(() => EventBus.$emit(`onAddFilesDialogClose${this.activeTree}`, uploaded));
        this.$nextTick(() => EventBus.$emit(`filesGridRefreshData${this.activeTree}`));
      }
    },
    onAddFolderDialogClose(added: boolean) {
      this.addFolderDialogOpen = false;

      if (added) {
        this.$nextTick(() => EventBus.$emit(`onAddFolderDialogClose${this.activeTree}`, added));
      }
    },
    onEditFileNameDialogClose(renamed: boolean) {
      this.editFileNameDialogOpen = false;

      if (renamed) {
        this.$nextTick(() =>
          EventBus.$emit(`onEditFileNameDialogClose${this.activeTree}`, renamed),
        );
        this.$nextTick(() => EventBus.$emit(`filesGridRefreshData${this.activeTree}`));
        this.refreshComponent(this.selectedItem);
      }
    },
    onEditFileTitleDialogClose(renamed: boolean) {
      this.editFileTitleDialogOpen = false;

      if (renamed) {
        this.$nextTick(() =>
          EventBus.$emit(`onEditFileTitleDialogClose${this.activeTree}`, renamed),
        );
        this.$nextTick(() => EventBus.$emit(`filesGridRefreshData${this.activeTree}`));
        this.refreshComponent(this.selectedItem);
      }
    },
    onReplaceFileDialogClose(open: boolean) {
      this.replaceFilesDialogOpen = open;
    },
    onCreateContextGroupDialogClose(created: boolean) {
      this.createContextGroupDialogOpen = false;
      this.$nextTick(() =>
        EventBus.$emit(
          `onCreateContextGroupDialogClose${this.activeTree}`,
          created,
          this.entityDialogMode,
        ),
      );
      if (created) {
        this.refreshComponent(this.selectedItem);
      }
    },
    onEditFolderDialogClose(edited: boolean) {
      this.editFolderDialogOpen = false;

      if (edited) {
        this.$nextTick(() => EventBus.$emit(`onEditFolderDialogClose${this.activeTree}`, edited));
        this.$nextTick(() => EventBus.$emit(`filesGridRefreshData${this.activeTree}`));
        this.refreshComponent(this.selectedItem);
      }
    },
    onDeleteFolderDialogClose(deleted: boolean) {
      this.deleteFolderDialogOpen = false;

      if (deleted) {
        this.$nextTick(() =>
          EventBus.$emit(`onDeleteFolderDialogClose${this.activeTree}`, deleted),
        );
        this.refreshComponent(null);
      }
    },
    onDeleteContextGroupDialogClose(deleted: boolean) {
      this.deleteContextGroupDialogOpen = false;

      if (deleted) {
        this.$nextTick(() =>
          EventBus.$emit(`onDeleteContextGroupDialogClose${this.activeTree}`, deleted),
        );
        this.refreshComponent(this.selectedItem);
      }
    },
    onDeleteFileDialogClose(deleted: boolean) {
      this.deleteFileDialogOpen = false;
      if (deleted) {
        this.$nextTick(() =>
          EventBus.$emit(`onDeleteFileDialogClose${this.activeTree}`, {
            deleted: deleted,
            fileUseHistoryId: this.selectedItem?.FileUseHistoryId,
          }),
        );
        this.$nextTick(() => EventBus.$emit(`filesGridRefreshData${this.activeTree}`));
        this.refreshComponent(null);
      }
    },
    expandNode(item: any): void {
      const model: InventoryComponent = this.treeNode as InventoryComponent;
      const data = {
        fileUseHistoryId: item.FileUseHistoryId,
        TreeType: model.TreeTypeId,
      };
      this.$emit('changeInventoryNodeToOpen', data);
    },
    rowClass(item: any): string {
      return item.Name === this.highlightedItem ? 'highlighted-item' : '';
    },
    encodeData(data: any): any {
      for (const key in data) {
        if (typeof data[key] === 'string') {
          data[key] = encodeURI(data[key]);
        }
      }
      return data;
    },
    async refreshComponent(node: TreeComponent | null) {
      this.$nextTick(async () => {
        if (node) {
          this.selectedTitle = node.Title;
          this.selectedItem = node;
          await this.buildContextMenuFor(this.selectedItem);
        } else {
          this.selectedTitle = '';
          this.selectedItem = null;
          this.clearContextMenu();
        }
      });
    },
    async buildContextMenuFor(node: TreeComponent) {
      const context = new ContextMenuContext(
        this.isWebPublish,
        this.companyId,
        this.selectedApplicationId,
        this.activeTree,
        node,
      );
      this.contextMenuItems = await contextMenuBuilder.build(context);
    },
    clearContextMenu() {
      this.contextMenuItems = [];
    },
    async onContextMenuItemClick(contextMenuItem: ContextMenuItem, args: any | null = null) {
      this.$nextTick(() => {
        this.contextMenuDropdownSelection = 0;
      });

      if (!this.selectedItem) return;

      switch (contextMenuItem.key) {
        case ContextMenuItemKey.copyLink: {
          LinkToShareHelper.copyLink(
            this.selectedItem,
            this.companyId,
            this.selectedApplicationId,
            this.selectedFormat,
            this.selectedRegion,
          );

          break;
        }
        case ContextMenuItemKey.downloadFile:
          await DownloadHelper.downloadFile(
            this.companyId,
            this.selectedItem.FileUseHistoryId,
            null,
          );
          break;
        case ContextMenuItemKey.details:
          EventBus.$emit('showDetailsPopup', { item: null, location: 1 });
          break;
        case ContextMenuItemKey.editFileTitle:
          this.editFileTitleDialogOpen = true;
          break;
        case ContextMenuItemKey.editFileName:
          this.editFileNameDialogOpen = true;
          break;
        case ContextMenuItemKey.replaceFiles:
          this.replaceFilesDialogOpen = true;
          break;
        case ContextMenuItemKey.copyForReused:
          await treeClipboardService.create(
            this.companyId,
            new CreateClipboardItemRequest(
              this.selectedApplicationId,
              this.selectedItem.SubId,
              this.selectedItem.FileUseHistoryId ?? 0,
              this.selectedItem.FileId ?? 0,
            ),
          );
          break;
        case ContextMenuItemKey.pasteAsReused:
          {
            const response = await treeClipboardService.paste(
              this.companyId,
              new PasteClipboardItemRequest(
                this.selectedApplicationId,
                this.selectedItem.SubId ?? 0,
                this.selectedItem.ReviewSectionId,
                this.selectedItem.RepeatableSectionId,
                this.selectedItem.SectionExtensionId,
              ),
            );

            this.$nextTick(() =>
              EventBus.$emit(`filesGridRefreshData${this.activeTree}`, this.selectedItem),
            );

            this.$nextTick(() =>
              EventBus.$emit(
                `filesGridReusedPasted${this.activeTree}`,
                response.OriginalFileUseHistoryId,
              ),
            );
          }
          break;
        case ContextMenuItemKey.suspendFile:
          this.$nextTick(() => {
            EventBus.$emit(`onSuspendClickCall${this.activeTree}`, {
              id: args as number,
              hisId: this.selectedItem?.FileUseHistoryId,
            });
          });
          break;
        case ContextMenuItemKey.removeSuspension:
          EventBus.$emit(`removeSuspension${this.activeTree}`, this.selectedItem.FileUseHistoryId);
          break;
        case ContextMenuItemKey.deleteFile:
          this.deleteFileDialogOpen = true;
          break;
        case ContextMenuItemKey.publishSubmission:
          this.publishSubmissionUnitDialogOpen = true;
          break;
        case ContextMenuItemKey.editSubmission:
          {
            this.$store.commit(RootMutations.setSelectedSub, this.selectedItem.SubId);
            this.$store.commit(RootMutations.openModalWindow, ModalWindow.editSubmission);
          }
          break;
        case ContextMenuItemKey.editContextGroup:
          this.entityDialogMode = EntityDialogMode.edit;
          this.createContextGroupDialogOpen = true;
          break;
        case ContextMenuItemKey.deleteContextGroup:
          this.deleteContextGroupDialogOpen = true;
          break;
        case ContextMenuItemKey.deleteSubmission:
          this.deleteSubmissionUnitDialogOpen = true;
          break;
        case ContextMenuItemKey.editFolder:
          this.editFolderDialogOpen = true;
          break;
        case ContextMenuItemKey.deleteFolder:
          this.deleteFolderDialogOpen = true;
          break;
        case ContextMenuItemKey.add:
          {
            const key = args as ContextMenuItemKey;

            switch (key) {
              case ContextMenuItemKey.addFiles:
                this.addFilesDialogOpen = true;
                break;
              case ContextMenuItemKey.addFolder:
                this.addFolderDialogOpen = true;
                break;
              case ContextMenuItemKey.createNewContextGroup:
                {
                  this.entityDialogMode = EntityDialogMode.create;
                  this.createContextGroupDialogOpen = true;
                }
                break;
              default:
                throw Error(`No handler for ${contextMenuItem.key}:${key}.`);
            }
          }
          break;
        default:
          throw Error(`No handler for ${contextMenuItem.key}.`);
      }

      await this.buildContextMenuFor(this.selectedItem);
    },
  },
});
