
import Vue from 'vue';

import { mapActions, mapState } from 'vuex';

import CompanySelector from 'common-vue-components/components/Dropdowns/CompanySelector.vue';
import NineDot from 'common-vue-components/components/Dropdowns/NineDot.vue';

import Info from '@/components/dropdowns/Info.vue';
import { ModalWindow } from '@/enums';
import { Panels } from '@/helpers/Enums';
import GlobalHelper from '@/helpers/globalHelper';
import { RootMutations } from '@/store';

require('../../Content/Common/Common.css');

export default Vue.extend({
  components: {
    Info,
    NineDot,
    //AppDetails,
    CompanySelector,
  },
  props: {
    showAppSelector: {
      type: Boolean,
      required: true,
    },
    showTrees: {
      type: Boolean,
      required: true,
    },
    appTitle: {
      type: String,
      required: true,
    },
    nonAppActiveView: {
      type: Number,
    },
    userGuideUrl: {
      //Info
      type: String,
      required: true,
    },
    showAppDetails: {
      //AppDetails
      type: Boolean,
    },
    webApplicationName: String,
    isWebPublish: Boolean,
    accessDenied: Boolean,
  },
  data() {
    return {
      info: false,
      classSelector: '',
      nineDot: false,
      trees: this.isWebPublish
        ? [
            { id: 1, label: 'Add New Submission Unit' },
            { id: 2, label: 'Edit Application' },
            { id: 3, label: 'Delete Application' },
          ]
        : [{ id: 4, label: 'View Details' }],
      openApp: false,
      tooltip: 'File Preview',
      treesVisible: true,
      version: '',
      appinfo: false,
      selectedOption: 0,
      userName: '',
      showCertaraLogo: true,
    };
  },
  computed: {
    ...mapState(['companies', 'activeCompany', 'areCompaniesLoading']),
    hideAppSelectorAndHasTitle(): boolean {
      return this.appTitle !== '' && !this.showAppSelector;
    },
    certaraLogoUrl(): string {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      // eslint-disable-next-line @typescript-eslint/no-var-requires
      return GlobalHelper.getPath(require('../../Content/images/certara-logo.png'));
    },
    infoUrl(): string {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      // eslint-disable-next-line @typescript-eslint/no-var-requires
      return GlobalHelper.getPath(require('../../Content/images/info.svg'));
    },
    arrowUrl(): string {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      return this.showCertaraLogo
        ? // eslint-disable-next-line @typescript-eslint/no-var-requires
          GlobalHelper.getPath(require('../../Content/images/arrow_drop_up.svg'))
        : // eslint-disable-next-line @typescript-eslint/no-var-requires
          GlobalHelper.getPath(require('../../Content/images/arrow_drop_down.svg'));
    },
    logoutUrl(): string {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      // eslint-disable-next-line @typescript-eslint/no-var-requires
      return GlobalHelper.getPath(require('../../Content/images/icon-logout.svg'));
    },
    homeUrl(): string {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      // eslint-disable-next-line @typescript-eslint/no-var-requires
      return GlobalHelper.getPath(require('../../Content/images/icon-home.svg'));
    },
  },
  watch: {
    nonAppActiveView(): void {
      this.tooltip = this.nonAppActiveView === Panels.Viewer ? 'File Preview' : 'Inventory';
    },
    treesVisible(): void {
      if (this.treesVisible !== this.showTrees) {
        this.$emit('changeShowTrees');
      }
    },
    nineDot(): void {
      this.$emit('changeOpenMenuCount', this.nineDot);
    },
    info(): void {
      this.$emit('changeOpenMenuCount', this.info);
    },
    appinfo(): void {
      this.$emit('changeOpenMenuCount', this.appinfo);

      if (!this.hideAppSelectorAndHasTitle) {
        this.classSelector = 'certaraHeader';
      } else {
        this.classSelector = 'certaraHeaderNew';
      }
    },
  },
  async mounted(): Promise<void> {
    this.version = window.version;
    const user = await this.$auth.getUser();
    this.userName = user.name ?? '';
  },
  methods: {
    onRegionSelect(regionId: number) {
      switch (regionId) {
        case 1: {
          this.createSubmission();
          break;
        }
        case 2: {
          this.editApplication();
          break;
        }
        case 3: {
          this.deleteApplication();
          break;
        }
        case 4: {
          this.viewApplicationDetails();
          break;
        }
      }

      this.$nextTick(() => {
        this.selectedOption = 0;
      });
    },
    ...mapActions(['changeActiveCompany']),

    appDisplayName(): string {
      const words = this.appTitle.split(' ');
      return words[words.length - 1];
    },
    showHideAppSelector(): void {
      if (this.hideAppSelectorAndHasTitle) {
        this.$emit('gridLoaded', true);
        setTimeout(() => this.$emit('changeAppSelector'), 500);

        //this.gridLoaded = true;
        this.$emit('gridLoaded', true);
      }
    },
    showHideDrawer(): void {
      this.$emit('changeDrawerState');
    },
    signOut(): void {
      this.$auth.signOut();
    },
    createSubmission(): void {
      this.$store.commit(RootMutations.openModalWindow, ModalWindow.createSubmission);
    },
    editApplication() {
      this.$store.commit(RootMutations.openModalWindow, ModalWindow.editApplication);
    },
    deleteApplication() {
      this.$store.commit(RootMutations.openModalWindow, ModalWindow.deleteApplication);
    },
    viewApplicationDetails() {
      this.$store.commit(RootMutations.openModalWindow, ModalWindow.viewApplicationDetails);
    },
  },
});
