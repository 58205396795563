import { Detail } from '@/models/adaptedDetailComponent';

export default class DetailsHelper {
  public static sortDetails(data: Detail[], order: string[], filter: any): any {
    return data
      .filter(filter)
      .sort(
        (a: any, b: any) =>
          order.findIndex((z) => z === a.Header) - order.findIndex((z) => z === b.Header),
      );
  }

  public static makeArrayNestedByPropertyName(infoItems: any, propertyName: string): any {
    const groupBy = (key: any) => (array: any) =>
      array.reduce((objectsByKeyValue: any, obj: any) => {
        const value = obj[key];
        objectsByKeyValue[value] = (objectsByKeyValue[value] || []).concat(obj);
        return objectsByKeyValue;
      }, {});

    const groupByPropertyName = groupBy(propertyName);
    const groupedData = groupByPropertyName(infoItems);
    return groupedData;
  }

  public static sortCurrentAppFirst(
    objectToSort: any,
    currentAppDisplayNumber: string | null,
  ): any[] {
    let keys = Object.keys(objectToSort);

    // remove current app from list, then add to front
    if (currentAppDisplayNumber != null) {
      // currentAppDisplayNumber is null if there are no reuses of the file in current app
      const currentAppKey = keys.splice(keys.indexOf(currentAppDisplayNumber), 1);
      keys = keys.sort();
      keys.unshift(currentAppKey[0]);
    } else {
      keys = keys.sort();
    }

    // make into array
    const sortedArray: any[] = [];
    keys.forEach((key) => {
      sortedArray.push(objectToSort[key]);
    });
    return sortedArray;
  }

  public static getActionTypeWord(actionTypeId: number): string | null {
    switch (actionTypeId) {
      case 0:
      case 1:
      case 5:
      case 6:
        return 'New';
      case 2:
        return 'Delete';
      case 3:
        return 'Replace';
      case 4:
        return 'Append';
    }
    return null;
  }
}
