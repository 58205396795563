import { BadRequestError } from '@/models/Errors';
import { CreateRepeatableSectionData, EditRepeatableSectionData } from '@/models/WebPublish';
import { CreateRepeatableSectionRequest } from '@/models/WebPublish/Requests';
import { EditRepeatableSectionRequest } from '@/models/WebPublish/Requests/EditRepeatableSectionRequest';

export class RepeatableSectionsService {
  public async getRepeatableSectionCreateData(
    companyId: number,
    parentReviewSectionId: number,
  ): Promise<CreateRepeatableSectionData> {
    const response = await fetch(
      `${window.sessionUrl}RepeatableSections/GetRepeatableSectionCreateData?companyId=${companyId}&parentReviewSectionId=${parentReviewSectionId}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      },
    );

    if (response.ok) {
      return JSON.parse(await response.text());
    } else {
      const errorMessage = JSON.parse(await response.text());

      switch (response.status) {
        default:
          throw Error(errorMessage);
      }
    }
  }

  public async createRepeatableSection(
    companyId: number,
    request: CreateRepeatableSectionRequest,
  ): Promise<number> {
    const response = await fetch(`${window.sessionUrl}RepeatableSections/CreateRepeatableSection`, {
      method: 'POST',
      body: JSON.stringify({ companyId, request }),
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (response.ok) {
      return JSON.parse(await response.text());
    } else {
      const errorMessage = JSON.parse(await response.text());

      switch (response.status) {
        case 400:
          throw new BadRequestError(errorMessage);
        default:
          throw Error(errorMessage);
      }
    }
  }

  public async editRepeatableSection(
    companyId: number,
    request: EditRepeatableSectionRequest,
  ): Promise<number> {
    const response = await fetch(`${window.sessionUrl}RepeatableSections/EditRepeatableSection`, {
      method: 'POST',
      body: JSON.stringify({ companyId, request }),
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (response.ok) {
      return JSON.parse(await response.text());
    } else {
      const errorMessage = JSON.parse(await response.text());

      switch (response.status) {
        case 400:
          throw new BadRequestError(errorMessage);
        default:
          throw Error(errorMessage);
      }
    }
  }

  public async getRepeatableSectionEditData(
    companyId: number,
    repeatableSectionId: number,
    parentReviewSectionId: number,
    subId: number,
  ): Promise<EditRepeatableSectionData> {
    const response = await fetch(
      `${window.sessionUrl}RepeatableSections/GetRepeatableSectionEditData?companyId=${companyId}&repeatableSectionId=${repeatableSectionId}&parentReviewSectionId=${parentReviewSectionId}&subId=${subId}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      },
    );

    if (response.ok) {
      return JSON.parse(await response.text());
    } else {
      const errorMessage = JSON.parse(await response.text());

      switch (response.status) {
        default:
          throw Error(errorMessage);
      }
    }
  }

  public async getKeywordValues(
    companyId: number,
    headerId: number,
    startsWith: string,
    rowsCount = 10,
  ): Promise<string[]> {
    const response = await fetch(
      `${
        window.sessionUrl
      }RepeatableSections/GetKeywordValues?companyId=${companyId}&headerId=${headerId}&startsWith=${
        startsWith || ''
      }&rowsCount=${rowsCount}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      },
    );

    if (response.ok) {
      return JSON.parse(await response.text());
    } else {
      const errorMessage = JSON.parse(await response.text());

      switch (response.status) {
        default:
          throw Error(errorMessage);
      }
    }
  }

  public async deleteRepeatableSection(
    companyId: number,
    appId: number,
    subId: number,
    repeatableSectionId: number,
  ): Promise<void> {
    const response = await fetch(`${window.sessionUrl}RepeatableSections/DeleteRepeatableSection`, {
      method: 'POST',
      body: JSON.stringify({ companyId, appId, subId, repeatableSectionId }),
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (response.ok) {
      return;
    } else {
      const errorMessage = JSON.parse(await response.text());

      switch (response.status) {
        case 400:
          throw new BadRequestError(errorMessage);
        default:
          throw Error(errorMessage);
      }
    }
  }
}

export const repeatableSectionsService = new RepeatableSectionsService();
