import { FileTypes } from '@/helpers/Enums';
import NavigationHistory from '@/pdf/navigationHistory';

export class FileComponent {
  public constructor(
    public FuhId: number | null = null,
    public Type: FileTypes | null = null,
    public IsDeletePlaceholder: boolean = false,
    public FileExists: boolean = true,
    public history: NavigationHistory | null = null,
    public HasXfa: boolean = false,
    public IsPlaceholder: boolean = false,
  ) {}
}
