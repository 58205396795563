
import Vue from 'vue';

import { mapGetters } from 'vuex';

import PdfDropdown from '@/components/dropdowns/PdfDropdown.vue';
import { DownloadHelper } from '@/helpers/downloadHelper';
import { Panels } from '@/helpers/Enums';
import { EventBus } from '@/helpers/EventBus';
import GlobalHelper from '@/helpers/globalHelper';
import { ViewerToolbarHelper } from '@/helpers/ViewerToolbarHelper';
import { FileComponent } from '@/models/fileComponent';

export default Vue.extend({
  components: {
    PdfDropdown,
  },
  props: {
    isFullScreen: {
      type: Boolean,
    },
    nonAppActiveView: {
      type: Number,
    },
    canGoBack: {
      type: Boolean,
    },
    canGoForward: {
      type: Boolean,
    },
    isDocumentLoaded: {
      type: Boolean,
    },
    isSearchOpen: {
      type: Boolean,
    },
    loadedDoc: {
      type: Object,
    },
    isUnsupportedFile: {
      type: Boolean,
    },
    loadedPdfPath: {
      type: Object,
    },
    userSettings: {
      type: Object,
    },
    loadedPdfDoc: {
      type: Object,
    },
  },
  data(): {
    menuOpen: boolean;
    searchShowing: boolean;
    sidePanelShowing: boolean;
    sidePanelToggleUrl: string;
    showPdfViewer: boolean;
    backButtonDisabled: boolean;
    forwardButtonDisabled: boolean;
    fileLoaded: boolean;
    isUnsupported: boolean;
    toolbarTooWide: boolean;
    isDeletePlaceholder: boolean;
    loadedFile: FileComponent;
    loadedFilePath: any;
  } {
    return {
      menuOpen: false,
      searchShowing: false,
      sidePanelShowing: false,
      sidePanelToggleUrl: '',
      showPdfViewer: true,
      backButtonDisabled: true,
      forwardButtonDisabled: true,
      fileLoaded: false,
      isUnsupported: false,
      toolbarTooWide: false,
      isDeletePlaceholder: false,
      loadedFile: new FileComponent(),
      loadedFilePath: null,
    };
  },
  computed: {
    ...mapGetters(['companyId']),
    icLeftSidebarBlack24pxUrl(): string {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      // eslint-disable-next-line @typescript-eslint/no-var-requires
      return GlobalHelper.getPath(require('../../Content/images/ic_left_sidebar_black_24px.svg'));
    },
  },
  watch: {
    nonAppActiveView() {
      this.showPdfViewer = this.nonAppActiveView === (Panels.Viewer as number);
    },
    canGoBack() {
      this.backButtonDisabled = !this.canGoBack;
    },
    canGoForward() {
      this.forwardButtonDisabled = !this.canGoForward;
    },
    isDocumentLoaded() {
      this.fileLoaded = this.isDocumentLoaded;
    },
    isSearchOpen() {
      this.searchShowing = this.isSearchOpen;
    },
    loadedDoc(file: FileComponent): void {
      this.isDeletePlaceholder = file.IsDeletePlaceholder;
      this.loadedFile = file;
    },
    isUnsupportedFile() {
      this.isUnsupported = this.isUnsupportedFile;
    },
    loadedPdfPath(file: any): void {
      this.loadedFilePath = file;
    },
    menuOpen() {
      this.$emit('changeOpenMenuCount', this.menuOpen);
    },
  },
  mounted(): void {
    this.sidePanelToggleUrl = this.icLeftSidebarBlack24pxUrl;

    EventBus.$on('changeNonAppActiveView', (viewer: number) => {
      this.$emit('changeNonAppActiveView', viewer);
    });

    EventBus.$on('changeLoadedDoc', (file: FileComponent) => {
      this.$emit('changeLoadedDoc', file);
    });

    EventBus.$on('toggleNotesPanel', () => {
      this.sidePanelShowing = true;
    });

    EventBus.$on('checkPdfToolbar', () => {
      this.toolbarTooWide = (this.$refs.toolbar as any).$el.clientWidth <= 550;
    });

    this.sidePanelShowing = this.userSettings.BookmarkPanelOpen;
  },
  methods: {
    toggleSideWindow() {
      ViewerToolbarHelper.toggleSideWindow();
      this.sidePanelShowing = !this.sidePanelShowing;
      this.$emit('updateBookmarkPanelOpenSetting', this.sidePanelShowing);
    },
    async downloadFile(): Promise<void> {
      DownloadHelper.downloadFile(this.companyId, this.loadedFile.FuhId, this.loadedFilePath);
    },
    goBack() {
      ViewerToolbarHelper.goBack();
    },
    goForward() {
      ViewerToolbarHelper.goForward();
    },
    zoomIn() {
      ViewerToolbarHelper.zoomIn();
    },
    zoomOut() {
      ViewerToolbarHelper.zoomOut();
    },
    fitWidth() {
      ViewerToolbarHelper.fitWidth();
    },
    fitPage() {
      ViewerToolbarHelper.fitPage();
    },
    search() {
      ViewerToolbarHelper.search();
      this.searchShowing = !this.searchShowing;
      this.$emit('changeIsSearchOpen', this.searchShowing);
    },
    fullscreen() {
      ViewerToolbarHelper.fullscreen();
    },
    getFullscreenIcon(): string {
      return this.isFullScreen ? 'mdi-fullscreen-exit' : 'mdi-fullscreen';
    },
  },
});
