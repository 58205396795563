
import Vue from 'vue';

import { chain } from 'lodash';
import { mapState } from 'vuex';

import { UploadEventArgs } from 'common-vue-components/components/Dialogs/Upload/types/uploadEventArgs';
import { UploadFileEventArgs } from 'common-vue-components/components/Dialogs/Upload/types/uploadFileEventArgs';
import { UploadFileStatus } from 'common-vue-components/components/Dialogs/Upload/types/uploadFileStatus';
import { UploadStatus } from 'common-vue-components/components/Dialogs/Upload/types/uploadStatus';
import UploadDialog, {
  UploadDialogRef,
} from 'common-vue-components/components/Dialogs/Upload/UploadDialog.vue';

import Dialog from '@/components/popups/Dialog.vue';
import ErrorHelper from '@/helpers/errorHelper';
import { CompleteFilesUploadRequest, StartFilesUploadRequest } from '@/models/WebPublish/Requests';
import { StartFileUploadResponse } from '@/models/WebPublish/Responses';
import { filesService } from '@/services';

const GENERIC_UPLOAD_ERROR =
  'The file(s) upload failed, please try again or contact system administrator for more details';

export default Vue.extend({
  components: {
    // eslint-disable-next-line vue/no-reserved-component-names
    Dialog,
    UploadDialog,
  },
  props: {
    open: { type: Boolean },
    treeNode: { type: Object, default: null },
  },
  data(): {
    showWarning: boolean;
    showUploadDialog: boolean;
    uploadedFiles: UploadFileEventArgs[];
    uploaded: boolean;
  } {
    return {
      showWarning: false,
      showUploadDialog: false,
      uploadedFiles: [],
      uploaded: false,
    };
  },
  computed: {
    ...mapState(['activeCompany', 'selectedApplicationId', 'selectedFormat', 'selectedRegion']),
    uploadDialog(): UploadDialogRef {
      return this.$refs.uploadDialog as any as UploadDialogRef;
    },
  },
  watch: {
    open: function () {
      this.uploaded = false;
      if (this.open) {
        if (this.treeNode.WarnAddFiles) {
          this.showWarning = true;
        } else {
          this.showUploadDialog = true;
        }
      } else {
        this.uploadedFiles = [];
        this.showWarning = false;
        this.showUploadDialog = false;
      }
    },
    showWarning: function () {
      if (!this.showWarning && !this.showUploadDialog) {
        this.$emit('close');
      }
    },
  },
  methods: {
    uploadFiles(): void {
      this.showUploadDialog = true;
      this.showWarning = false;
    },
    getRepeatableSectionInfo(): {
      parentRepeatableSectionId: number | null;
      repeatableSectionId: number | null;
    } {
      let parentRepeatableSectionId: number | null = null;
      const repeatableSectionId = this.treeNode.RepeatableSectionId;

      if (repeatableSectionId) {
        let node = this.treeNode;
        do {
          if (node.parentNode) {
            node = node.parentNode();
          } else if (node.parent?.length > 0) {
            node = node.parent()[0];
          } else {
            node = null;
          }

          parentRepeatableSectionId = node?.RepeatableSectionId ?? null;
        } while (parentRepeatableSectionId && parentRepeatableSectionId === repeatableSectionId);
      }

      return {
        parentRepeatableSectionId,
        repeatableSectionId,
      };
    },
    async handleUpload(e: UploadEventArgs): Promise<void> {
      const startUploadRequest: StartFilesUploadRequest = {
        AppId: this.selectedApplicationId,
        SubId: this.treeNode.SubId,
        Files: e.files.map((uploadFile) => ({
          FileName: uploadFile.path,
          Size: uploadFile.size,
        })),
      };

      let uploadingFiles: StartFileUploadResponse[];
      try {
        uploadingFiles = await filesService.startFilesUpload(
          this.activeCompany.Id,
          startUploadRequest,
        );
      } catch (error) {
        this.uploadDialog.setUploadStatus(UploadStatus.Error);
        ErrorHelper.addSnackbarMessage(GENERIC_UPLOAD_ERROR, 'error', error.message, true);
        return;
      }

      this.uploadedFiles = [];

      let uploadError: Error | undefined = undefined;
      try {
        await this.uploadDialog.upload({
          files: uploadingFiles.map((uploadingFile) => ({
            status: UploadFileStatus.Success,
            uploadId: uploadingFile.UploadId,
            fileName: uploadingFile.NewFileName,
            filePath: uploadingFile.OriginalFileName,
            partSize: uploadingFile.PartSize,
            partUrls: uploadingFile.PartUrls,
          })),
        });
      } catch (error) {
        uploadError = error;
      }

      if (uploadError || !this.uploadedFiles.length) {
        this.uploadDialog.setUploadStatus(UploadStatus.Error);
        ErrorHelper.addSnackbarMessage(GENERIC_UPLOAD_ERROR, 'error', '', true);
        return;
      }

      this.uploadDialog.setUploadStatus(UploadStatus.Uploading);

      const { parentRepeatableSectionId, repeatableSectionId } = this.getRepeatableSectionInfo();

      const completeUploadRequest: CompleteFilesUploadRequest = {
        AppId: this.selectedApplicationId,
        SubId: this.treeNode.SubId,
        ReviewSectionId: this.treeNode.ReviewSectionId,
        ParentRepeatableSectionId: parentRepeatableSectionId,
        RepeatableSectionId: repeatableSectionId,
        SectionExtensionId: this.treeNode.SectionExtensionId,
        Files: chain(uploadingFiles)
          .map((uploadingFile) => ({
            uploadingFile,
            uploadedFile: this.uploadedFiles.find(
              (uploadedFile) => uploadedFile.uploadId === uploadingFile.UploadId,
            ),
          }))
          .filter(({ uploadedFile }) => !!uploadedFile)
          .map(({ uploadingFile, uploadedFile }) => ({
            UploadId: uploadingFile.UploadId,
            OriginalFileName: uploadingFile.OriginalFileName,
            FilePath: uploadingFile.FilePath,
            PartETags: uploadedFile!.eTags,
          }))
          .value(),
      };

      try {
        await filesService.completeFilesUpload(this.activeCompany.Id, completeUploadRequest);
      } catch {
        this.uploadDialog.setUploadStatus(UploadStatus.Error);
        ErrorHelper.addSnackbarMessage(GENERIC_UPLOAD_ERROR, 'error', '', true);
        return;
      }

      this.uploaded = true;

      this.uploadedFiles.forEach((uploadedFile) => {
        this.uploadDialog.setFileUploadSuccess(uploadedFile.path);
      });

      this.uploadDialog.setUploadStatus(UploadStatus.Success);

      if (uploadingFiles.length === this.uploadedFiles.length) {
        ErrorHelper.addSnackbarMessage('The file(s) added successfully', 'success');
      } else {
        const failedFilesCount = uploadingFiles.length - this.uploadedFiles.length;
        const filesCount = uploadingFiles.length;
        ErrorHelper.addSnackbarMessage(
          `Something went wrong as ${failedFilesCount}/${filesCount} files did not load. Please try loading these files again.`,
          'warning',
          '',
          true,
        );
      }
    },
    handleUploadFile(e: UploadFileEventArgs): void {
      this.uploadedFiles.push(e);
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    handleUploadDialogClose(): void {
      this.showUploadDialog = false;
      this.showWarning = false;
      this.$emit('close', this.uploaded);
    },
  },
});
