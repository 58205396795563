
import Vue from 'vue';

import { mapGetters, mapMutations, mapState } from 'vuex';

import ApplicationInformation2 from '@/components/applicationWizard/ApplicationInformation2.vue';
import Dialog from '@/components/popups/Dialog.vue';
import ErrorHelper from '@/helpers/errorHelper';
import { BadRequestError } from '@/models/Errors';
import {
  AgencyType,
  AgencyTypeCode,
  AppType,
  CrossReferenceApplication,
} from '@/models/WebPublish';
import { ApplicationDetails } from '@/models/WebPublish/ApplicationDetails';
import { applicationsService } from '@/services';
import { RootMutations } from '@/store';

export default Vue.extend({
  components: {
    // eslint-disable-next-line vue/no-reserved-component-names
    Dialog,
    ApplicationInformation2,
  },
  props: {
    applicationId: Number,
  },
  data(): {
    app: ApplicationDetails | null;
    agencyTypeCodeChangeWarningVisible: boolean;
    agencyTypeCodeChangeWarningConfirmed: boolean;
    agencyTypes: AgencyType[];
    agencyTypeCodes: AgencyTypeCode[];
    appTypes: AppType[];
    appTypeId: number | null;
    appNumber: string;
    agencyTypeCodeId: number | null;
    crossReferenceApplications: CrossReferenceApplication[];
    loading: boolean;
    saving: boolean;
  } {
    return {
      app: null,
      agencyTypeCodeChangeWarningVisible: false,
      agencyTypeCodeChangeWarningConfirmed: false,
      agencyTypes: [],
      agencyTypeCodes: [],
      appTypes: [],
      appTypeId: null,
      appNumber: '',
      agencyTypeCodeId: null,
      crossReferenceApplications: [{ AppTypeId: null, AppNumber: '' }],
      loading: true,
      saving: false,
    };
  },
  computed: {
    ...mapState(['selectedApplicationId', 'selectedFormat', 'selectedRegion']),
    ...mapGetters(['companyId']),
    canClickSave(): boolean {
      return !!(
        this.appTypeId &&
        parseInt(this.appNumber) &&
        this.agencyTypeCodeId &&
        this.appNumber.length === 6
      );
    },
  },
  async beforeMount() {
    await this.loadApp();
  },
  methods: {
    ...mapMutations({
      close: RootMutations.closeModalWindow,
      changeAppTitle: 'changeAppTitle',
    }),
    async loadApp() {
      const app = await applicationsService.getApplicationDetails(
        this.companyId,
        this.selectedApplicationId,
        this.selectedFormat,
        this.selectedRegion,
      );

      this.app = app;
      this.appTypeId = app.AppTypeId;
      this.appNumber = app.AppNumber;
      this.agencyTypeCodeId = app.AgencyTypeCodeId;

      const [appTypes, agencyTypes, agencyTypeCodes] = await Promise.all([
        app.CodeSystemId ? applicationsService.getAppTypes(app.CodeSystemId) : Promise.resolve([]),
        applicationsService.getAgencyTypes(app.RegionId),
        applicationsService.getAgencyTypeCodes(app.CodeSystemId),
      ]);
      this.appTypes = appTypes;
      this.agencyTypes = agencyTypes;
      this.agencyTypeCodes = agencyTypeCodes;

      if (app.CrossReferenceApps?.length) {
        this.crossReferenceApplications = app.CrossReferenceApps;
      }

      this.loading = false;
    },
    setAppTypeId(appTypeId: number) {
      this.appTypeId = appTypeId;
    },
    setAppNumber(appNumber: string) {
      this.appNumber = appNumber;
    },
    setAgencyTypeCodeId(agencyTypeCodeId: number) {
      if (
        !this.agencyTypeCodeChangeWarningConfirmed &&
        this.app!.Submissions.length > 1 &&
        this.agencyTypeCodeId != agencyTypeCodeId
      ) {
        this.agencyTypeCodeChangeWarningVisible = true;
      }

      this.agencyTypeCodeId = agencyTypeCodeId;
    },
    setCrossReferenceApplications(apps: CrossReferenceApplication[]) {
      this.crossReferenceApplications = apps;
    },
    onChangedAgencyTypeCodeWarningOkClick(): void {
      this.agencyTypeCodeChangeWarningVisible = false;
      this.agencyTypeCodeChangeWarningConfirmed = true;
    },
    onChangedAgencyTypeCodeWarningCancelClick(): void {
      this.agencyTypeCodeId = this.app!.AgencyTypeCodeId;
    },
    async save(): Promise<boolean> {
      this.saving = true;
      try {
        await applicationsService.updateApplication(this.$store.state.activeCompany.Id, {
          AppId: this.selectedApplicationId,
          AppNumber: this.appNumber,
          AppTypeId: this.appTypeId ?? 0,
          AgencyTypeCodeId: this.agencyTypeCodeId!,
          CrossReferenceApps: this.crossReferenceApplications.filter((x) => x.AppTypeId),
        });

        ErrorHelper.addSnackbarMessage('The application has been updated successfully.', 'success');
        const app = await applicationsService.getApplicationDetails(
          this.companyId,
          this.selectedApplicationId,
          this.selectedFormat,
          this.selectedRegion,
        );
        this.changeAppTitle(app.Title);

        this.close();
        return true;
      } catch (e) {
        if (e instanceof BadRequestError) {
          ErrorHelper.addSnackbarMessage(e.message, 'error', '');
        } else {
          ErrorHelper.addSnackbarMessage(
            'Updating application failed.',
            'error',
            (e as Error).message,
            true,
          );
        }

        return false;
      } finally {
        this.saving = false;
      }
    },
  },
});
