import { Format } from '@/helpers/Enums';

export enum ActionType {
  New = 1,
  Delete = 2,
  Replace = 3,
  Append = 4,
  Updated = 5,
  Link = 6,
}

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace ActionType {
  const buildKey = (formatId: number, actionType: ActionType) =>
    `${Format[formatId]}:${ActionType[actionType]}`;

  const transformations = new Map<string, string>([
    [buildKey(Format.eCTD40, ActionType.Delete), 'Suspend'],
  ]);

  export function displayText(formatId: number, actionType: ActionType | number | null): string {
    if (!actionType) return '';
    const key = buildKey(formatId, actionType);
    return transformations.get(key) ?? ActionType[actionType];
  }
}
