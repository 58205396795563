
import Vue from 'vue';

import { SubType, Unit, UnitType } from '@/models/WebPublish';
import { applicationsService } from '@/services';

export default Vue.extend({
  props: {
    unitTypes: { type: Array as () => Array<UnitType>, default: () => [] },
    subTypes: { type: Array as () => Array<SubType>, default: () => [] },
    units: { type: Array as () => Array<Unit>, default: () => [] },
    selectedUnitTypeId: { type: Number as () => number | null, default: () => null },
    selectedSubTypeId: { type: Number as () => number | null, default: () => null },
    selectedUnitId: { type: Number as () => number | null, default: () => null },
    applicant: { type: String },
    sequenceNumber: { type: Number },
    description: { type: String },
    companyId: { type: Number },
    finishButtonName: { type: String, default: 'Next' },
  },
  data: () => ({
    dialog: false,
    sequenceNumberRules: [
      (v: string) =>
        !v ||
        (/^\d+$/.test(v) && parseInt(v) > 0) ||
        'Please enter the proper format for a Submission Unit Number (Example: 1234 or 1)',
    ],
    searchApplicant: '',
    applicants: [] as string[],
    timerId: null as any,
  }),
  computed: {
    isUnitTypeSelected(): boolean {
      return !!this.selectedUnitTypeId;
    },
  },
  watch: {
    async searchApplicant(val: string) {
      clearTimeout(this.timerId);

      this.timerId = setTimeout(async () => {
        this.applicants = await applicationsService.getApplicants(this.companyId, val || '');
      }, 500);
    },
  },
  async mounted() {
    this.searchApplicant = this.applicant || '';
  },
  methods: {
    onUnitTypeSelect(unitTypeId: number) {
      this.$emit('onUnitTypeSelect', unitTypeId);
    },
    onSubTypeSelect(subTypeId: number) {
      this.$emit('onSubTypeSelect', subTypeId);
    },
    onUnitSelect(unitId: number) {
      this.$emit('onUnitSelect', unitId);
    },
    onApplicantChange(applicant: string) {
      this.$emit('onApplicantChange', applicant);
    },
    onSequenceNumberBlur() {
      if (this.$refs.sequenceNumber && !(this.$refs.sequenceNumber as any).validate()) {
        this.onSequenceNumberChange(1);
      }
      this.$nextTick(() => {
        this.$emit('onSequenceNumberBlur');
      });
    },
    onSequenceNumberChange(sequenceNumber: number) {
      this.$emit('onSequenceNumberChange', +sequenceNumber);
    },
    onDescriptionChange(description: string) {
      this.$emit('onDescriptionChange', description);
    },
  },
});
