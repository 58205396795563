
import Vue from 'vue';

import { InputValidationRules } from 'vuetify';
import { mapGetters, mapMutations, mapState } from 'vuex';

import ErrorHelper from '@/helpers/errorHelper';
import { TreeHelper } from '@/helpers/treeHelper';
import TreeComponent from '@/models/treeComponent';
import { filesService } from '@/services';
import ComponentService from '@/services/componentService';

export default Vue.extend({
  props: {
    open: Boolean,
    treeNode: { type: Object, default: null },
    list: { type: Array as () => Array<any>, default: () => [] },
  },
  data(): {
    title: string;
    titleError: string;
    submitting: boolean;
    showContinue: boolean;
  } {
    return {
      title: '',
      titleError: '',
      submitting: false,
      showContinue: false,
    };
  },
  computed: {
    ...mapGetters(['companyId']),
    ...mapState(['selectedApplicationId', 'selectedFormat', 'selectedRegion']),
    titleRules(): InputValidationRules {
      return this.titleError ? [this.titleError] : [];
    },
  },
  watch: {
    title() {
      this.titleError = '';
      this.showContinue = false;
    },
    open(value) {
      if (value) {
        this.title = '';
        this.titleError = '';
        this.showContinue = false;

        const treeComponent: TreeComponent = TreeHelper.makeTreeComponent(this.treeNode);
        const url = `./Details/GetDetails?companyId=${this.companyId}&appId=${this.selectedApplicationId}&format=${this.selectedFormat}&region=${this.selectedRegion}&`;
        new ComponentService().getDetails(
          (details: any) => {
            this.title = (details?.InfoItems || []).find((x: any) => x.Header == 'Title').Content;
          },
          treeComponent,
          url,
        );
      }
    },
  },
  methods: {
    ...mapMutations(['refresh']),
    async setTitle() {
      this.title = (this.title || '').trim();
    },
    async changeTitle() {
      this.titleError = '';
      await new Promise((resolve) => setTimeout(resolve, 500));

      let items = [];
      if (this.list != null && this.list.length > 0) {
        items = this.list;
      } else {
        items = this.treeNode.parentNode().items;
      }

      items
        .filter((x: any) => x.FileUseHistoryId && x.Id !== this.treeNode.Id)
        .forEach((item: any) => {
          const itemTitle =
            item.Title.lastIndexOf(' (') > 0
              ? (item.Title ?? '').substr(0, item.Title.lastIndexOf(' ('))
              : item.Title;
          if (itemTitle === this.title) {
            this.titleError = 'You have entered a duplicate file title';
            this.showContinue = true;
            return;
          }
        });

      if (this.titleError) return;

      await this.changeFileTitle();
    },
    async changeFileTitle() {
      this.submitting = true;
      try {
        await filesService.changeFileTitle(this.companyId, {
          FileUseHistoryId: this.treeNode.FileUseHistoryId,
          AppId: this.selectedApplicationId,
          SubId: this.treeNode.SubId,
          Title: this.title,
        });
        ErrorHelper.addSnackbarMessage('The file title has been changed successfully.', 'success');
        this.$emit('close', true);
      } catch {
        ErrorHelper.addSnackbarMessage('Changing the file title failed', 'error', '', true);
        this.$emit('close', false);
      }

      this.submitting = false;
    },
    closeDialog() {
      this.$emit('close');
    },
  },
});
