
import Vue from 'vue';

import { EventBus } from '@/helpers/EventBus';

export default Vue.extend({
  props: {
    failedLinkToShare: {
      type: String,
    },
  },
  data(): {
    showCopyFailed: boolean;
  } {
    return {
      showCopyFailed: false,
    };
  },
  watch: {
    failedLinkToShare(): void {
      if (this.failedLinkToShare) {
        this.showCopyFailed = true;
      }
    },
    showCopyFailed(): void {
      if (this.showCopyFailed) {
        this.$nextTick(() => {
          this.focusOnLink();
        });
      } else {
        this.$emit('changeFailedLinkToShare', '');
      }
    },
  },
  mounted() {
    EventBus.$on('changeFileToDownload', (filePath: string) => {
      this.$emit('changeFileToDownload', filePath);
    });
    EventBus.$on('changeFailedLinkToShare', (result: any) => {
      this.$emit('changeFailedLinkToShare', result);
    });
  },
  methods: {
    focusOnLink(): void {
      (this.$refs.focusThis as HTMLFormElement).focus();
      (this.$refs.focusThis as HTMLFormElement).select();
    },
  },
});
