export default class LinkedNode<T> {
  public constructor(
    public data: T,
    public isPlaceholder: boolean = false,
  ) {}

  public prev: LinkedNode<T> | null = null;

  public next: LinkedNode<T> | null = null;
}
