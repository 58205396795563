import 'url-polyfill';
import '@progress/kendo-ui/js/kendo.grid.js';
import '@progress/kendo-ui/js/kendo.treeview.js';

import '@/interceptors';

import Vue from 'vue';

import PdfTron from '@pdftron/webviewer';
import $ from 'jquery';
//import PrimeVue from 'primevue/config'; // Import PrimeVue
import Router from 'vue-router';

import init from 'common-vue-components/init';
import OktaVue from 'common-vue-components/oktaVue';
import OktaVueCookie from 'common-vue-components/plugins/OktaVueCookie';

import App from '@/App.vue';
import { EventBus } from '@/helpers/EventBus';
import { initWindow } from '@/helpers/window';
import PdfViewerControl from '@/pdf/pdfviewer-control';
import PdfViewerGlobals from '@/pdf/pdfviewer-globals';
import vuetify from '@/plugins/vuetify';
import router from '@/router';
import store from '@/store';

// PrimeVue CSS (import these if you want to use PrimeVue's default styles)
//import 'primeicons/primeicons.css';

declare global {
  interface Window {
    previewPane: any;
    returnUrl: string;
    errorMessage: string;
    PdfViewerGlobals: PdfViewerGlobals;
    clipboardData: any;
    baseUrl: string;
    sessionUrl: string;
    version: string;
    infoClick: any;
    menuClick: any;
    publishMode: string;
  }

  interface Document {
    fullscreenElement: any;
    msFullscreenElement: any;
    mozFullScreenElement: any;
    webkitFullscreenElement: any;
    onmsfullscreenchange: any;
    onmozfullscreenchange: any;
    onwebkitfullscreenchange: any;
  }
}

init();

const KEEP_ALIVE_TIMEOUT = 60 * 1000;
setInterval(() => {
  $.get('./Main/KeepAlive');
}, KEEP_ALIVE_TIMEOUT);

function emitClickEvent(eventName: string, senderElement: any, parentElement: HTMLElement): void {
  const coordinates = parentElement.getBoundingClientRect();
  const treeNode = senderElement.parentElement.parentElement.querySelectorAll('.tree-item');
  const treeId = senderElement.getAttribute('treeId');
  EventBus.$emit(eventName, treeNode, treeId, coordinates);
}

function removeHighlights(oldNode: Element): void {
  oldNode.classList.remove('grey', 'lighten-3');
  oldNode.removeAttribute('id');
}

window.infoClick = (sender: any): void => {
  const oldNode = document.querySelector('#selectedDetailsNode');
  const parent = sender.parentElement.parentElement as HTMLElement;

  if (oldNode) {
    removeHighlights(oldNode);
  }

  if (oldNode === parent) return;

  parent.classList.add('grey', 'lighten-3');
  parent.setAttribute('id', 'selectedDetailsNode');
  emitClickEvent('infoClick', sender, parent);
};

window.menuClick = (sender: any): void => {
  const oldNode = document.querySelector('#selectedContextNode');
  const parent = sender.parentElement.parentElement as HTMLElement;

  if (oldNode) {
    removeHighlights(oldNode);
  }

  if (oldNode === parent) {
    return;
  }

  parent.classList.add('grey', 'lighten-3');
  parent.setAttribute('id', 'selectedContextNode');
  emitClickEvent('menuClick', sender, parent);
};

window.PdfViewerGlobals = PdfViewerGlobals;
initWindow(PdfViewerGlobals);
PdfViewerControl.register(store, PdfTron, {
  config: './Content/pdftron/viewer-config.js',
  documentType: 'pdf',
  enableAnnotations: true,
  enableReadOnlyMode: true,
  hideAnnotationPanel: false,
  path: './Content/pdftron/dist',
  showToolbarControl: true,
  streaming: false,
  disabledElements: ['header'],
  css: './Content/pdftron/preview-pane.css',
});

Vue.use(Router);
Vue.use(OktaVueCookie);
Vue.use(OktaVue);
//Vue.use(PrimeVue, { unstyled: true }); // Configure PrimeVue

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount('#mainContent');
