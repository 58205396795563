
import Vue from 'vue';

export default Vue.extend({
  props: {
    treeLegendState: {
      type: Boolean,
    },
  },
  data(): {
    treeItems: any[];
    treeLegend: boolean;
  } {
    return {
      treeLegend: false,
      treeItems: [
        {
          imageClass: 'k-i-regulatory-activity',
          description: 'Regulatory Activity',
        },
        {
          imageClass: 'k-i-sequence',
          description: 'Sequence',
        },
        {
          imageClass: 'k-i-grouped-sequence',
          description: 'Grouped Sequence',
        },
        {
          imageClass: 'k-i-folder-empty',
          description: 'Empty Section',
        },
        {
          imageClass: 'k-i-folder-full',
          description: 'Section Containing Files',
        },
        {
          imageClass: 'k-i-file-0',
          description: 'New File',
        },
        {
          imageClass: 'k-i-file-4',
          description: 'Appending File',
        },
        {
          imageClass: 'k-i-file-3',
          description: 'Replacing File',
        },
        {
          imageClass: 'k-i-file-2',
          description: 'Deleting Placeholder',
        },
        {
          imageClass: 'k-i-file-reused-1',
          description: 'Reused New File',
        },
        {
          imageClass: 'k-i-file-reused-4',
          description: 'Reused Appending File',
        },
        {
          imageClass: 'k-i-file-reused-3',
          description: 'Reused Replacing File',
        },
        {
          imageClass: 'k-i-fileuse-set',
          description: 'Related File Set',
        },
        {
          imageClass: 'k-i-file-1-missing',
          description: 'Missing New File',
        },
        {
          imageClass: 'k-i-file-3-missing',
          description: 'Missing Replaced File',
        },
        {
          imageClass: 'k-i-file-4-missing',
          description: 'Missing Appending File',
        },
        {
          imageClass: 'k-i-file-reused-1-missing',
          description: 'Missing Reused New File',
        },
        {
          imageClass: 'k-i-file-reused-3-missing',
          description: 'Missing Reused Replaced File',
        },
        {
          imageClass: 'k-i-file-reused-4-missing',
          description: 'Missing Reused Appending File',
        },
      ],
    };
  },
  watch: {
    treeLegendState(): void {
      this.treeLegend = this.treeLegendState;
    },
    treeLegend(): void {
      if (this.treeLegend === false) {
        this.$emit('changeTreeLegendState');
      }
    },
  },
});
