export enum Regions {
  CA = 1,
  EU = 2,
  US = 3,
  CH = 4,
  AU = 5,
  JP = 6,
  GCC = 7,
  MCC = 8,
  CN = 9,
}
