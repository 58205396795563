
import Vue from 'vue';

import CopyButton from '@/components/details/CopyButton.vue';

export default Vue.extend({
  components: {
    CopyButton,
  },
  props: {
    detail: Object,
    isEditMode: Boolean,
    error: String,
  },
});
