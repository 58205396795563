var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.loaded)?_c('v-card',{attrs:{"id":"detailsContainerCard"}},[_c('v-tabs',{attrs:{"active-class":"selectedTab","background-color":"grey lighten-3","height":"30"},model:{value:(_vm.active),callback:function ($$v) {_vm.active=$$v},expression:"active"}},[_vm._l((_vm.tabs),function(tab){return _c('v-tab',{key:tab.value,staticClass:"detailsTab",attrs:{"ripple":""}},[_c('span',{directives:[{name:"show",rawName:"v-show",value:(tab.isLc && _vm.isFile),expression:"tab.isLc && isFile"}]},[_vm._v(" "+_vm._s(tab.value)+_vm._s(_vm.relationships !== null ? ` (${_vm.relationships.length})` : '')+" ")]),_c('span',{directives:[{name:"show",rawName:"v-show",value:(!tab.isLc),expression:"!tab.isLc"}]},[_vm._v(_vm._s(tab.value))])])}),_vm._l((_vm.tabs),function(tab){return _c('v-tab-item',{key:tab.value},[_c('v-card',{staticClass:"card-size",attrs:{"id":"detailsCard"}},[(!tab.isLc && !_vm.details)?_c('div',{staticClass:"loader"},[_c('v-progress-linear',{staticClass:"position-initial",attrs:{"color":"grey lighten-3","indeterminate":""}})],1):_vm._e(),(
            !tab.isLc &&
            !tab.isRegAct &&
            !tab.isReused &&
            _vm.details &&
            _vm.treeComponent.NodeTypeId === 0
          )?_c('SequenceDetails',{attrs:{"details":_vm.details,"tree-node":_vm.treeComponent}}):_vm._e(),(
            !tab.isLc &&
            !tab.isRegAct &&
            !tab.isReused &&
            _vm.details &&
            _vm.treeComponent.NodeTypeId !== 0
          )?_c('v-list',{attrs:{"id":"details"}},[_vm._l((_vm.details),function(detail){return [_c('div',{key:detail.Header},[_c('div',{class:{ 'disable-item': _vm.isDisabled(detail) }}),_c('DetailItem',{class:{ 'disabled-for-edit': _vm.isDisabled(detail) },attrs:{"detail":detail,"error":_vm.titleError,"is-edit-mode":_vm.isEditMode && !_vm.isDisabled(detail)}})],1)]})],2):_vm._e(),(_vm.isDeletePlaceholder && !tab.isLc && !tab.isRegAct && !tab.isReused)?_c('v-list',[_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-subtitle',{staticClass:"font-italic"},[_vm._v(" * This leaf is a "+_vm._s(_vm.operationDisplayText)+" Placeholder ")]),_c('v-list-item-subtitle',{staticClass:"font-italic"},[_vm._v(" which "+_vm._s(_vm.operationDisplayText?.toLowerCase())+"s the related file ")])],1)],1)],1):_vm._e(),(tab.isRegAct && _vm.details)?_c('RegulatoryActivities',{attrs:{"info-items":_vm.details.InfoItems}}):_vm._e(),(tab.isReused && _vm.reuses)?_c('ReusedFiles',{attrs:{"reuse-info":_vm.reuses}}):_vm._e(),(tab.isLc && !_vm.relationships)?_c('div',{staticClass:"loader"},[_c('v-progress-linear',{staticClass:"position-initial",attrs:{"color":"grey lighten-3","indeterminate":""}})],1):_vm._e(),(tab.isLc && _vm.relationships)?_c('v-list',{attrs:{"id":"relationships"}},_vm._l((_vm.relationships),function(file){return _c('v-list-group',{key:file.title,class:{
              selected: file.FileUseHistoryId === _vm.selectedFuhId,
            },attrs:{"no-action":""},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item-avatar',[_c('img',{staticClass:"image",attrs:{"src":file.Icon}})]),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(file.FileName)+" ")]),_c('v-list-item-subtitle',[_vm._v(_vm._s(file.SubmittedIn))])],1)]},proxy:true}],null,true),model:{value:(file.active),callback:function ($$v) {_vm.$set(file, "active", $$v)},expression:"file.active"}},[_vm._l((file.InfoItems.filter((el) => el.Header !== 'Action Type Id')),function(item){return [_c('LifeCycleDetailItem',{key:item.Header,attrs:{"detail":item}})]})],2)}),1):_vm._e()],1)],1)})],2),(_vm.showEditIcon && _vm.isEditMode)?_c('v-btn',{staticClass:"icon-details mr-6",attrs:{"disabled":_vm.disableSave,"height":"30","icon":"","width":"30"},on:{"click":function($event){$event.stopPropagation();return _vm.saveClick.apply(null, arguments)}}},[_c('img',{attrs:{"src":_vm.disableSave ? _vm.iconSaveGrayUrl : _vm.iconSaveUrl}})]):_vm._e(),(_vm.showEditIcon)?_c('v-btn',{staticClass:"icon-details",attrs:{"height":"30","icon":"","width":"30"},on:{"click":function($event){$event.stopPropagation();_vm.isEditMode && _vm.copySourceDetails();
      _vm.isEditMode = !_vm.isEditMode;
      _vm.titleError = null;}}},[_c('img',{attrs:{"src":_vm.isEditMode ? _vm.iconXUrl : _vm.iconEditUrl}})]):_vm._e(),(_vm.showRelationshipIcon)?_c('v-btn',{staticClass:"icon-details mr-1",attrs:{"height":"30","icon":"","width":"30"},on:{"click":function($event){$event.stopPropagation();_vm.isEditMode = !_vm.isEditMode;
      _vm.titleError = null;}}},[_c('img',{attrs:{"src":_vm.iconRelationshipUrl}})]):_vm._e()],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }