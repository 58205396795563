import ClipboardHelper from '@/helpers/clipboardHelper';
import ErrorHelper from '@/helpers/errorHelper';
import { EventBus } from '@/helpers/EventBus';
import { TreeHelper } from '@/helpers/treeHelper';
import ComponentService from '@/services/componentService';

export class LinkToShareHelper {
  public static copyLink(
    treeNode: any,
    companyId: number,
    appId: number,
    format: number,
    region: number,
  ): void {
    const treeComponent = TreeHelper.makeTreeComponent(treeNode);
    const url = `LinkToShare/CopyLink?companyId=${companyId}&appId=${appId}&format=${format}&region=${region}`;
    new ComponentService().getLinkToShare(treeComponent, this.copyLinkSuccess, url);
  }

  public static copyLinkSuccess(result: any): void {
    if (ClipboardHelper.addToClipboard(result)) {
      ErrorHelper.addSnackbarMessage('Link copied to clipboard!', 'success');
    } else {
      // catch in CopyFailedAlert.
      EventBus.$emit('changeFailedLinkToShare', result);
    }
  }
}
