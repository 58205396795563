import $ from 'jquery';

export default class PdfViewerService {
  private controlPath = './viewer';

  private METHOD_GETLICENSE = 'getlicense';

  private METHOD_GETS3PATHFROMFUHID = 'gets3pathfromfuhid';

  private METHOD_GETS3PATHFROMRELATIVEPATH = 'gets3pathfromrelativepath';

  public getUrlFromFuhId(id: number, companyId: number): any {
    return $.ajax({
      cache: false,
      url: `${this.controlPath}\\${this.METHOD_GETS3PATHFROMFUHID}?fuhId=${id}&companyId=${companyId}`,
    });
  }

  public getUrlFromRelativePath(sourceFile: string, targetFile: string, companyId: number): any {
    return $.ajax({
      cache: false,
      url: `${this.controlPath}\\${this.METHOD_GETS3PATHFROMRELATIVEPATH}?&companyId=${companyId}`,
      data: { sourceFile, targetFile },
    });
  }

  public getLicense(): any {
    return $.ajax({
      cache: false,
      url: `${this.controlPath}\\${this.METHOD_GETLICENSE}`,
    });
  }
}
