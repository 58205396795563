
import Vue from 'vue';

import { TreeView, TreeViewInstaller } from '@progress/kendo-treeview-vue-wrapper';
import $ from 'jquery';
//import Tree from 'primevue/tree/Tree';
import { mapGetters, mapState } from 'vuex';

import ContextMenu from '@/components/popups/ContextMenu.vue';
import { TreeType } from '@/helpers/Enums';
import ErrorHelper from '@/helpers/errorHelper';
import { EventBus } from '@/helpers/EventBus';
import Filters from '@/helpers/Filters';
import { TreeHelper } from '@/helpers/treeHelper';
import TreeComponent from '@/models/treeComponent';
import UserRightsComponent from '@/models/userRightsComponent';

// required for kendo
Vue.use(TreeViewInstaller);

export default Vue.extend({
  components: {
    ContextMenu,
    //Tree
  },
  props: {
    bind: {
      type: Boolean,
    },
    getIcon: {
      type: Function,
    },
    requestUrl: {
      type: String,
    },
    tree: {
      type: String,
    },
    treeId: {
      type: String,
    },
    treeIntId: {
      type: Number,
    },
    visible: {
      type: Boolean,
    },
    selectedNode: {
      type: Object as () => TreeComponent,
      default: new TreeComponent(),
    },
    showHide: {
      type: Boolean,
    },
    showDetails: {
      type: Boolean,
    },
    showContextMenu: {
      type: Boolean,
    },
    treeSearchResults: {
      type: Array,
    },
    activeTree: {
      type: Number,
    },
    isWebPublish: Boolean,
    userRights: {
      type: Object as () => UserRightsComponent,
      default: new UserRightsComponent(),
    },
    resetDontHide: {
      type: Boolean,
      default: false,
    },
    originTreeObj: Function,
  },
  data(): {
    contextNode: any;
    filter: string;
    isTreeRefreshing: boolean;
    leftValue: number;
    rightValue: number;
    target: string;
    topValue: number;
    treeNode: any;
    isDetailsVisible: boolean;
    nodes: any;
  } {
    return {
      nodes: [],
      contextNode: null,
      filter: 'div.k-top>span.k-in, div.k-mid>span.k-in, div.k-bot>span.k-in',
      isTreeRefreshing: false,
      leftValue: 0,
      isDetailsVisible: false,
      rightValue: 0,
      target: '#' + this.tree,
      topValue: 0,
      treeNode: null,
    };
  },
  computed: {
    ...mapGetters(['companyId']),
    ...mapState(['selectedApplicationId', 'selectedFormat', 'selectedRegion']),
    requestUrlWithCompanyId(): string {
      return `${this.requestUrl}?companyId=${this.companyId}&appId=${this.selectedApplicationId}&format=${this.selectedFormat}&region=${this.selectedRegion}`;
    },
    remoteDataSource(): kendo.data.HierarchicalDataSource {
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const self = this;
      return new kendo.data.HierarchicalDataSource({
        schema: {
          model: {
            hasChildren: 'HasChildren',
            id: 'Id',
          },
        },
        transport: {
          read: {
            cache: false,
            dataType: 'json',
            url: self.requestUrlWithCompanyId,
            data(request: any) {
              const model = new TreeComponent();

              if (Object.keys(request).length !== 0) {
                const treeview = (
                  self.$refs.myTreeView as TreeView
                ).kendoWidget() as kendo.ui.TreeView;
                const treeNode = treeview.dataSource.get(request.Id);

                model.Id = treeNode.get('Id');
                model.SectionId = treeNode.get('SectionId');
                model.SectionExtensionId = treeNode.get('SectionExtensionId');
                model.RepeatableSectionId = treeNode.get('RepeatableSectionId');
                model.InRepeatGroup = treeNode.get('InRepeatGroup');
                model.FileUseSetId = treeNode.get('FileUseSetId');
                model.SubId = treeNode.get('SubId');
                model.UnitId = treeNode.get('UnitId');
                model.ReviewSectionId = treeNode.get('ReviewSectionId');
                model.NodeTypeId = treeNode.get('NodeTypeId');
                return model;
              }

              return request;
            },
          },
        },
      });
    },
  },
  watch: {
    showHide(): void {
      if (this.treeIntId !== TreeType.Search) {
        this.$nextTick(() => {
          this.setShowHide();
        });
      }
    },
    treeSearchResults(): void {
      if (this.tree === 'Search') {
        this.$nextTick(() =>
          // need to wait for nextTick so kendo treeview can finish rendering
          this.thisTree().dataSource.data(this.treeSearchResults),
        );
      }
    },
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    activeTree(): void {},
    resetDontHide(): void {
      this.thisTree()
        .dataSource.data()
        .forEach((node: any) => {
          this.resetDontHideMethod(node);
        });
    },
  },
  mounted() {
    this.bindEvents();
  },
  methods: {
    thisTree(): kendo.ui.TreeView {
      return (this.$refs.myTreeView as TreeView).kendoWidget() as kendo.ui.TreeView;
    },
    refreshTrees(event: any): void {
      TreeHelper.findKidsAndMakeOrnamentHolder(event.sender.element[0]);
      if (this.isTreeRefreshing || this.treeIntId === TreeType.Search) {
        return;
      }

      const treeView = (this.$refs.myTreeView as TreeView).kendoWidget() as kendo.ui.TreeView;
      const dataItem = treeView.dataItem(event.node);

      if (dataItem === undefined) {
        EventBus.$emit('baseTree_refreshed', this.treeIntId);
        this.setShowHide();
        return;
      }

      if (!dataItem || !this.showHide) {
        return;
      }

      try {
        this.isTreeRefreshing = true;
        dataItem.children.filter(Filters.HideEmptyFilter);
      } finally {
        this.isTreeRefreshing = false;
      }
    },
    setShowHide(): void {
      if (this.isTreeRefreshing) {
        return;
      }

      const treeView = this.thisTree();
      try {
        this.isTreeRefreshing = true;
        const showHide = this.showHide;
        treeView.dataSource.data().forEach((node: any) => {
          node.set('hasChildren', showHide && !node.HasFiles ? false : node.HasChildren);
        });

        if (
          this.selectedNode.Id.length > 0 &&
          this.selectedNode.HasFiles == false &&
          !this.selectedNode.FileUseHistoryId &&
          this.selectedNode.TreeTypeId == this.treeIntId
        ) {
          const item = treeView.dataSource.get(this.selectedNode.Id);
          let element = treeView.findByUid(item.uid);
          let dataItem = treeView.dataItem(element) as any;

          try {
            while (!dataItem.HasFiles) {
              const previous = element.prev();
              if (previous.length === 0) {
                element = element.parent();
              } else {
                element = previous;
              }
              dataItem = treeView.dataItem(element) as any;
            }

            const treeNode = treeView.findByUid(dataItem.uid);
            treeView.select(treeNode);
            treeView.trigger('select', { node: treeNode });
          } catch (error) {
            this.selectTopNode(null);
          }
        }
        treeView.dataSource.filter(showHide ? Filters.HideEmptyFilter : {});
      } finally {
        this.isTreeRefreshing = false;
      }
    },
    treeOnSelect(event: any): void {
      const treeView = this.thisTree();
      const item = { sub: treeView, id: this.treeId };

      const treeNode = treeView.dataItem(event.node);
      this.contextNode = treeNode;
      this.$emit('selectedNodeChanged', treeNode);
      this.$nextTick(() => EventBus.$emit('setTreeObj', item));
    },
    fetchData(node: any) {
      const tt = this.nodes;
      const self = this.requestUrlWithCompanyId;

      let data = {};
      if (node && node.item) {
        data = node.item;
      }
      $.ajax({
        url: self,
        type: 'GET',
        dataType: 'json',
        cache: false,
        data: data,
        success: (response: any) => {
          response.forEach((item: any) => {
            const temp = {
              key: item.Id,
              label: item.Title,
              item: item,
              data: 'Documents Folder',
              icon: 'pi pi-fw pi-folder',
              children: [
                {
                  key: '1',
                  label: '2',
                },
              ],
            };

            /*if(item.HasChildren)
                                    {
                                      temp.children.push({
                                                           'key'  : '1',
                                                           'label': '1'
                                                         });
                                    }*/

            tt.push(temp);
          });
        },
      });
    },
    getNodeElm(id: string): any {
      const tree = this.thisTree();
      const item = tree.dataSource.get(id);
      if (item == null) {
        return null;
      }
      const element = tree.findByUid(item.uid);
      return element.get(0);
    },
    setTopAndLeft(popup: HTMLDivElement): void {
      if (this.$vuetify.breakpoint.mdAndUp) {
        const offsetHeight = popup.offsetHeight + (popup.innerHTML.includes('suspend') ? 150 : 0);
        this.topValue =
          this.topValue + offsetHeight > window.innerHeight
            ? window.innerHeight - 20 - offsetHeight
            : this.topValue;
        this.leftValue =
          this.leftValue + popup.offsetWidth > window.innerWidth
            ? window.innerWidth - 20 - popup.offsetWidth
            : this.leftValue;
      } else {
        this.topValue = window.innerHeight / 2 - popup.offsetHeight / 2;
        this.leftValue = window.innerWidth / 2 - popup.offsetWidth / 2;
      }
      this.isDetailsVisible = true;
    },
    onDetailsLoaded(): void {
      if (!this.showDetails) {
        const popup = (this.$refs.details as any).$el as HTMLDivElement;
        this.isDetailsVisible = false;
        setTimeout(() => {
          this.setTopAndLeft(popup);
        }, 100);

        this.$emit('showDetails');
      }
      document.addEventListener('click', this.closeDetails);
    },
    onContextMenuOpen(downloadVisible: boolean): void {
      this.topValue =
        this.topValue + 60 > window.innerHeight
          ? downloadVisible
            ? this.topValue - 60
            : this.topValue
          : this.topValue;
      document.addEventListener('click', this.closeContextMenu);
    },
    closeDetails(event: MouseEvent): void {
      const target = event.target as Element;

      const container = $('.absolute-details');
      if (!container.is(target) && container.has(target).length === 0) {
        this.clearNodeAndRemoveListener(
          'selectedDetailsNode',
          'ornamentButton',
          target,
          this.closeDetails,
        );
        if (this.showDetails) {
          this.$emit('showDetails');
        }
        this.treeNode = null;
      }
    },
    closeContextMenu(event: MouseEvent): void {
      const target = event.target as Element;
      if (target.classList.contains('k-i-more-vertical') && !this.showContextMenu) {
        this.$emit('showContextMenu');
        return;
      }

      if (target.id != 'suspend' && !target.classList.contains('mdi-chevron-down')) {
        this.clearNodeAndRemoveListener(
          'selectedContextNode',
          'k-i-more-vertical',
          target,
          this.closeContextMenu,
        );
        document.removeEventListener('click', this.closeContextMenu);
        if (this.showContextMenu) {
          this.$emit('showContextMenu');
        }

        this.contextNode = null;
      }
    },
    clearNodeAndRemoveListener(
      nodeId: string,
      containedClass: string,
      target: Element,
      listener: any,
    ): void {
      const element = document.querySelector('#' + nodeId);
      if (element && !target.classList.contains(containedClass)) {
        element.classList.remove('grey', 'lighten-3');
        element.removeAttribute('id');
      }

      document.removeEventListener('click', listener);
    },
    selectTopNode(subId: number | null): void {
      const treeView = this.thisTree();
      const data = treeView.dataSource.data();
      const item = data.find((x: any) => x.SubId == subId) || data[0];

      const element = treeView.findByUid(item.uid);
      treeView.select(element);
      treeView.trigger('select', { node: element });
    },
    publish(node: any): void {
      if (node) {
        //check if it is in Publishing ?
        EventBus.$emit('publishStateChanged', true);
        const submissionParts = node.OriginalTitle.split('(');
        const request = {
          submissionId: node.SubId,
          submissionType:
            submissionParts.length > 1 ? submissionParts[1].replace(')', '').trim() : '',
          submissionNumber: submissionParts.length > 0 ? submissionParts[0].trim() : '',
        } as any;

        $.ajax({
          method: 'POST',
          cache: false,
          url: './ValidateMessageQueue/EnqueuePublish',
          data: request,
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          success: function (result) {
            ErrorHelper.addSnackbarMessage('Submission has been queued for publishing', 'success');
          },
          error: function (result, errorText, errorDescription) {
            EventBus.$emit('publishStateChanged', false);
            ErrorHelper.addSnackbarMessage(
              `Publishing has failed. ${errorDescription || ''}`,
              'error',
              result.responseText,
              true,
            );
          },
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          complete: function () {
            EventBus.$emit('publishStateChanged', false);
          },
        });
      }
    },
    bindEvents(): void {
      EventBus.$on('setInfoForFiles', (node: any) => {
        this.nodes = [];
        const tt = this.nodes;
        const self = this.requestUrlWithCompanyId;
        let data = {};
        if (node) {
          data = node;
        }
        $.ajax({
          url: self,
          type: 'GET',
          dataType: 'json',
          cache: false,
          data: data,
          success: (response: any) => {
            response.forEach((item: any) => {
              tt.push(item);
            });
            EventBus.$emit('getInfoForFiles', this.nodes);
          },
        });
      });

      EventBus.$on('mainComponent_refreshTree', () => {
        this.$nextTick(() =>
          this.thisTree()
            .dataSource.read()
            .then(() => {
              this.$emit('loadingInformationChanged', TreeHelper.getTreeTypeId(this.tree) - 1);
              if (this.activeTree === this.treeIntId) {
                this.selectTopNode(this.selectedNode?.SubId);
              } else if (this.selectedNode.Id === '') {
                this.selectTopNode(null);
              }
            }),
        );
      });
      EventBus.$on(
        'baseTree_request_expandPath',
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        (treeId: number, path: string[], finishedCallback: () => any) => {
          if (this.treeIntId !== treeId) {
            return;
          }
          this.$nextTick(() =>
            this.thisTree().expandPath(path, () =>
              EventBus.$emit('baseTree_autoExpanded', this.treeIntId),
            ),
          );
        },
      );
      EventBus.$on('baseTree_request_select', (treeId: number, nodeId: string) => {
        if (this.treeIntId !== treeId) {
          return;
        }

        const tree = this.thisTree();
        const item = tree.dataSource.get(nodeId);
        if (item == null) {
          ErrorHelper.addSnackbarMessage('The destination could not be found.', 'error');
          return;
        }

        const element = tree.findByUid(item.uid);
        tree.select(element);
        tree.trigger('select', { node: element });

        const node = this.getNodeElm(nodeId);
        if (node == null) {
          ErrorHelper.addSnackbarMessage('The destination could not be found.', 'error');
          return;
        }

        const treeContainer = $('#' + this.treeId + '-wrapper');
        const scrollTop = treeContainer.scrollTop();
        // TODO: vue-ify this.
        const mainToolbar = document.getElementById('mainToolbar') as HTMLDivElement;
        const originTree = document.getElementById('origin-tree-wrapper') as HTMLDivElement;
        const pageHeader = document.getElementById('page-header') as HTMLDivElement;

        let mainHeight =
          mainToolbar.offsetHeight + pageHeader.offsetHeight + originTree.offsetHeight / 2;

        if (originTree.offsetHeight === 0) {
          mainHeight = mainToolbar.offsetHeight + pageHeader.offsetHeight + 200;
        }

        treeContainer.animate(
          {
            scrollTop: $(node).offset()!.top - mainHeight + (scrollTop ? scrollTop : 0),
          },
          500,
          () => EventBus.$emit('baseTree_scrollFinished', node),
        );
      });
      EventBus.$on('infoClick', (treeNode: any, treeId: string, coordinates: DOMRect) => {
        if (this.treeId === treeId) {
          const treeView = this.thisTree();
          this.treeNode = treeView.dataItem(treeNode) as any;

          this.topValue = coordinates.top;
          this.leftValue = coordinates.right;
        }
      });
      EventBus.$on('menuClick', (treeNode: any, treeId: string, coordinates: DOMRect) => {
        if (this.treeId === treeId) {
          if (this.showContextMenu) {
            this.$emit('showContextMenu');
          }

          const treeView = this.thisTree();

          this.contextNode = treeView.dataItem(treeNode) as any;

          this.topValue = coordinates.top;
          this.leftValue = coordinates.right;

          const popup = (this.$refs.contextMenu as any).$el as HTMLDivElement;
          this.isDetailsVisible = false;
          setTimeout(() => {
            this.setTopAndLeft(popup);
          }, 100);
        }
      });
      EventBus.$on('publishClick', (treeNode: any, treeId: string) => {
        if (this.treeId === treeId) {
          const treeView = this.thisTree();
          const node = treeView.dataItem(treeNode) as any;
          this.publish(node);
        }
      });

      // having to bind databound here instead of component, not sure why
      // but binding in component doesn't work with remote data source
      this.$nextTick(() => {
        this.thisTree().bind('dataBound', this.refreshTrees);
      });
    },
    resetDontHideMethod(node: any) {
      node.DontHide = false;
      (node.items || []).forEach((x: any) => this.resetDontHideMethod(x));
    },
  },
});
