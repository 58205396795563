
import Vue from 'vue';

import $ from 'jquery';
import { mapGetters, mapState } from 'vuex';

import DetailItem from '@/components/details/DetailItem.vue';
import LifeCycleDetailItem from '@/components/details/LifeCycleDetailItem.vue';
import RegulatoryActivities from '@/components/details/RegulatoryActivities.vue';
import ReusedFiles from '@/components/details/ReusedFiles.vue';
import SequenceDetails from '@/components/details/SequenceDetails.vue';
import { ActionType, FileUseHistoryStatus, FileUseState } from '@/enums';
import DetailsHelper from '@/helpers/detailsHelper';
import { FileTypes, NodeType } from '@/helpers/Enums';
import ErrorHelper from '@/helpers/errorHelper';
import GlobalHelper from '@/helpers/globalHelper';
import { TreeHelper } from '@/helpers/treeHelper';
import { DetailItemModel } from '@/models';
import { Detail } from '@/models/adaptedDetailComponent';
import TreeComponent from '@/models/treeComponent';
import UserRightsComponent from '@/models/userRightsComponent';
import ComponentService from '@/services/componentService';

export default Vue.extend({
  ...mapState(['selectedFormat']),
  components: {
    DetailItem,
    LifeCycleDetailItem,
    RegulatoryActivities,
    ReusedFiles,
    SequenceDetails,
  },
  props: {
    treeNode: {
      type: Object,
    },
    showDetails: {
      type: Boolean,
    },
    isWebPublish: Boolean,
    treeObj: {
      type: Object,
    },
    userRights: {
      type: Object as () => UserRightsComponent,
      default: new UserRightsComponent(),
    },
  },
  data(): {
    active: number | null;
    details: any | null;
    sourceDetails: any | null;
    reuses: any | null;
    relationships: any[] | null;
    isFile: boolean;
    isDeletePlaceholder: boolean;
    selectedFuhId: number | null;
    tabs: any[];
    treeComponent: TreeComponent;
    isEditMode: boolean;
    forceDisableSaveButton: boolean;
    titleError: string | null;
    loaded: boolean;
  } {
    return {
      active: null,
      details: null,
      sourceDetails: null,
      reuses: null,
      isFile: false,
      isDeletePlaceholder: false,
      relationships: null,
      selectedFuhId: null,
      tabs: [
        {
          isLc: false,
          isRegAct: false,
          isReused: false,
          value: 'details',
        },
        {
          isLc: true,
          isRegAct: false,
          isReused: false,
          value: 'lifecycle',
        },
      ],
      treeComponent: new TreeComponent(),
      isEditMode: false,
      forceDisableSaveButton: false,
      titleError: null,
      loaded: false,
    };
  },
  computed: {
    ...mapGetters(['companyId']),
    ...mapState(['selectedApplicationId', 'selectedFormat', 'selectedRegion']),
    icoCurrentFileUrl(): string {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      // eslint-disable-next-line @typescript-eslint/no-var-requires
      return GlobalHelper.getPath(require('../../Content/images/treeicons/ico-current-file.svg'));
    },
    icoDeletingFileUrl(): string {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      // eslint-disable-next-line @typescript-eslint/no-var-requires
      return GlobalHelper.getPath(require('../../Content/images/treeicons/ico-deleting-file.svg'));
    },
    icoReplacingFileUrl(): string {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      // eslint-disable-next-line @typescript-eslint/no-var-requires
      return GlobalHelper.getPath(require('../../Content/images/treeicons/ico-replacing-file.svg'));
    },
    icoAppendingFileUrl(): string {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      // eslint-disable-next-line @typescript-eslint/no-var-requires
      return GlobalHelper.getPath(require('../../Content/images/treeicons/ico-appending-file.svg'));
    },
    iconEditUrl(): string {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      // eslint-disable-next-line @typescript-eslint/no-var-requires
      return GlobalHelper.getPath(require('../../Content/images/icon-edit.svg'));
    },
    iconXUrl(): string {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      // eslint-disable-next-line @typescript-eslint/no-var-requires
      return GlobalHelper.getPath(require('../../Content/images/icon-x-invert.svg'));
    },
    iconSaveUrl(): string {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      // eslint-disable-next-line @typescript-eslint/no-var-requires
      return GlobalHelper.getPath(require('../../Content/images/save.svg'));
    },
    iconSaveGrayUrl(): string {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      // eslint-disable-next-line @typescript-eslint/no-var-requires
      return GlobalHelper.getPath(require('../../Content/images/save_gray.svg'));
    },
    iconRelationshipUrl(): string {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      // eslint-disable-next-line @typescript-eslint/no-var-requires
      return GlobalHelper.getPath(require('../../Content/images/icon-relationship.svg'));
    },
    showIcon(): boolean {
      return (
        this.isWebPublish &&
        this.treeComponent &&
        (this.treeComponent.NodeTypeId == NodeType.FileUseHistory ||
          this.treeComponent.NodeTypeId == NodeType.SectionExtension)
      );
    },
    showEditIcon(): boolean {
      return this.showIcon && this.hasPublish && this.active == 0;
    },
    showRelationshipIcon(): boolean {
      // temporary disabled
      // this.showIcon && this.active == 1;
      return false;
    },
    titleDetail(): any {
      const details = (this.details?.InfoItems || this.details || []).filter(
        (x: any) => x.Header == 'Title',
      );
      return details.length ? details[0] : {};
    },
    title(): string {
      return this.titleDetail.Content;
    },
    operationDisplayText(): string {
      const operation = (this.details?.InfoItems || this.details || []).filter(
        (x: any) => x.Header == 'Operation',
      );

      return operation.length ? operation[0].Content : '';
    },
    sourceTitle(): string {
      const details = (this.sourceDetails || []).filter((x: any) => x.Header == 'Title');
      return details.length ? details[0].Content : null;
    },
    disableSave(): boolean {
      return this.forceDisableSaveButton || !(this.title || '').trim() || !!this.titleError;
    },
    selectedNode(): any {
      return this.treeNode && $(`[data-uid="${(this.treeNode as any).uid}"]`);
    },
    hasPublish(): boolean {
      return this.isWebPublish && this.userRights.HasPublishRights;
    },
  },
  watch: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    showDetails(newVal: boolean, oldVal: boolean): void {
      this.isDeletePlaceholder = false;
      if (!newVal) {
        this.isEditMode = false;
      }
      this.$emit('changeOpenMenuCount', this.showDetails);
    },
    title(newVal: string, oldVal: string): void {
      if (newVal != oldVal) {
        this.titleError = null;
      }
    },
  },
  mounted() {
    if (this.treeNode) {
      this.treeComponent = TreeHelper.makeTreeComponent(this.treeNode);
      this.resetDetails();
      this.setTabsForType();
      this.$emit('changeShowDetails');
      this.updateDetails();
      this.selectedFuhId = (this.treeNode as TreeComponent).FileUseHistoryId;
      if (this.selectedFuhId) {
        this.isFile = true;
        this.updateRelationships();
        this.updateReusedFiles(this.selectedFuhId);
      } else {
        this.isFile = false;
      }
    }
  },
  methods: {
    isDisabled(detail: any): boolean {
      return this.isEditMode && detail.Header != 'Title';
    },
    updateDetails(): void {
      const url = `./Details/GetDetails?companyId=${this.companyId}&appId=${this.selectedApplicationId}&format=${this.selectedFormat}&region=${this.selectedRegion}&`;
      new ComponentService().getDetails(this.updateDetailsSuccess, this.treeComponent, url);
    },
    copyDetails() {
      this.sourceDetails = JSON.parse(JSON.stringify(this.details));
    },
    copySourceDetails() {
      this.details = JSON.parse(JSON.stringify(this.sourceDetails));
    },
    updateDetailsSuccess(result: any): void {
      this.details = this.formatDetails(this.treeComponent, result, false);
      this.copyDetails();
      this.active = null;
      this.$nextTick(() => {
        this.active = 0;
      });
      this.loaded = true;
      this.$emit('detailsLoaded');
    },
    formatDetails(treeComponent: TreeComponent, details: any, isRelationships: boolean): any {
      details.InfoItems = this.mapDetails(details.InfoItems);
      this.isDeletePlaceholder = false;

      switch (treeComponent.NodeTypeId) {
        case NodeType.Sub:
          return details;
        case NodeType.Unit:
          return this.orderDetailsByHeaders(details, [
            'Title',
            'Regulatory Activity Number',
            'Regulatory Activity Type',
            'State',
          ]);
        case NodeType.Section:
        case NodeType.RepeatSection:
        case NodeType.SectionExtension:
          return this.orderDetailsByHeaders(details, [
            'Title',
            'Section Number',
            'Section Path',
            'Number of Links',
            'Number of Bookmarks',
          ]);
        case NodeType.FileUseHistory:
          this.isDeletePlaceholder = treeComponent.ActionType == ActionType.Delete;
          if (isRelationships)
            return this.orderDetailsByHeaders(details, [
              'Title',
              'Filename',
              'File Path',
              'Checksum',
              'Actual Checksum',
              'Lifecycle Status',
              'State',
              'Number of Links',
              'Number of Bookmarks',
              'Submitted In',
            ]);
          if (treeComponent.FileType != FileTypes.PDF)
            return this.orderDetailsByHeaders(details, [
              'Title',
              'Filename',
              'Operation',
              'File Path',
              'File Size (KB)',
              'Checksum',
              'Actual Checksum',
              'Lifecycle Status',
              'State',
              'Submitted In',
            ]);
          return this.orderDetailsByHeaders(details, [
            'Title',
            'Filename',
            'Operation',
            'File Path',
            'Page Count',
            'File Size (KB)',
            'Checksum',
            'Actual Checksum',
            'Lifecycle Status',
            'State',
            'Number of Links',
            'Number of Bookmarks',
            'Submitted In',
          ]);
      }
    },
    mapDetails(details: any): DetailItemModel[] {
      return details.map((detail: any) => {
        switch (detail.Header) {
          case 'Action Type Id': {
            const displayText = ActionType.displayText(this.selectedFormat, detail.Content);
            return new DetailItemModel('Operation', displayText);
          }
          case 'State': {
            const displayText = FileUseState.displayText(this.selectedFormat, detail.Content);
            return new DetailItemModel(detail.Header, displayText);
          }
          case 'Lifecycle Status': {
            const status = FileUseHistoryStatus.fromString(detail.Content);
            const displayText = status
              ? FileUseHistoryStatus.displayText(this.selectedFormat, status)
              : detail.Content;
            return new DetailItemModel(detail.Header, displayText);
          }
          default: {
            return new DetailItemModel(detail.Header, detail.Content);
          }
        }
      });
    },
    orderDetailsByHeaders(details: any, headers: string[]): any {
      return DetailsHelper.sortDetails(details.InfoItems, headers, (detailOfInterest: Detail) =>
        headers.includes(detailOfInterest.Header),
      );
    },
    updateRelationships(): void {
      const treeComponent: TreeComponent = TreeHelper.makeTreeComponent(this.treeNode);
      const url = `./Relationships/GetRelationships?companyId=${this.companyId}&appId=${this.selectedApplicationId}&format=${this.selectedFormat}&region=${this.selectedRegion}`;
      new ComponentService().getDetails(this.updateRelationshipsSuccess, treeComponent, url);
    },
    updateRelationshipsSuccess(result: any): void {
      for (let j = 0; j < result.length; j += 1) {
        result[j].InfoItems = this.formatDetails(this.treeComponent, result[j], true);
      }
      this.relationships = result;
      if (this.relationships !== null) {
        const length = this.relationships.length;
        for (let i = 0; i < length; i += 1) {
          const item = this.relationships[i];
          item.Icon = this.getIconForActionType(item.ActionTypeId);
        }
      }
    },
    updateReusedFiles(fileUseHistoryId: number): void {
      new ComponentService().getReusedFiles(
        this.updateReusedFilesSuccess,
        fileUseHistoryId,
        this.companyId,
        this.selectedApplicationId,
        this.selectedFormat,
        this.selectedRegion,
      );
    },
    updateReusedFilesSuccess(result: any): void {
      this.reuses = result;

      if (result.length > 0) {
        this.tabs.push({
          isLc: false,
          isRegAct: false,
          isReused: true,
          value: 'Other File Uses',
        });
      }
    },
    getIconForActionType(actionType: number): string {
      switch (actionType) {
        case ActionType.Replace:
          return this.icoReplacingFileUrl;
        case ActionType.Delete:
          return this.icoDeletingFileUrl;
        case ActionType.Append:
          return this.icoAppendingFileUrl;
        default:
          return this.icoCurrentFileUrl;
      }
    },
    setTabsForType(): void {
      if (this.treeNode.FileUseHistoryId) {
        this.tabs = [
          {
            isLc: false,
            isRegAct: false,
            isReused: false,
            value: 'Details',
          },
          {
            isLc: true,
            isRegAct: false,
            isReused: false,
            value: 'Lifecycle',
          },
        ];
      } else if (this.treeNode.SpecId === 2) {
        /* us3 */
        this.tabs = [
          {
            isLc: false,
            isRegAct: false,
            isReused: false,
            value: 'Details',
          },
          {
            isLc: false,
            isRegAct: true,
            isReused: false,
            value: 'Regulatory Activities',
          },
        ];
      } else {
        this.tabs = [
          {
            isLc: false,
            isRegAct: false,
            isReused: false,
            value: 'Details',
          },
        ];
      }
    },
    resetDetails(): void {
      this.details = null;
      this.relationships = null;
      this.selectedFuhId = null;
      this.active = 0;
      this.reuses = null;
    },
    saveClick(): void {
      this.titleError = null;
      if (
        this.treeComponent.NodeTypeId == NodeType.SectionExtension &&
        this.title != this.sourceTitle
      ) {
        this.renameSectionExtension();
      } else {
        this.isEditMode = !this.isEditMode;
        this.copyDetails();
      }
    },
    renameSectionExtension(): void {
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const self = this;
      const node = this.treeNode;
      if (this.treeNode) {
        self.forceDisableSaveButton = true;
        $.ajax({
          method: 'POST',
          cache: false,
          url: './SectionExtension/Title',
          data: {
            SectionExtensionId: node.SectionExtensionId,
            Title: (self.title || '').trim(),
            SubId: node.SubId,
          },
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          success: function (result) {
            ErrorHelper.addSnackbarMessage(
              'The Node Extension was updated successfully.',
              'success',
            );
            const treeView = self.treeObj();
            const selectedDataItem = treeView.dataItem(self.selectedNode);
            selectedDataItem.set('Title', self.title);
            self.isEditMode = !self.isEditMode;
            self.copyDetails();
            GlobalHelper.enqueueUpdateApplication();
          },
          error: function (result, errorText, errorDescription) {
            if ((result.responseText || '').includes('Use a different title')) {
              self.titleError = result.responseText;
            } else {
              ErrorHelper.addSnackbarMessage(
                `Updating the Node Extension failed. ${
                  result.responseText || errorDescription || ''
                }`,
                'error',
              );
            }
          },
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          complete: function () {
            self.forceDisableSaveButton = false;
          },
        });
      }
    },
  },
});
