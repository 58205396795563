
import Vue from 'vue';

import { InputValidationRules } from 'vuetify';
import { mapGetters, mapMutations, mapState } from 'vuex';

import ErrorHelper from '@/helpers/errorHelper';
import { EventBus } from '@/helpers/EventBus';
import GlobalHelper from '@/helpers/globalHelper';
import { BadRequestError } from '@/models/Errors';
import { FileReplacementDto } from '@/models/WebPublish/FileReplacementDto';
import { filesService } from '@/services';

interface ReplacementItem {
  subId: number | undefined;
  fuhId: number | undefined;
  files: Array<IdTitleItem>;
}

interface IdTitleItem {
  id: number;
  title: string;
}

export default Vue.extend({
  props: {
    treeNode: { type: Object, default: null },
  },
  data(): {
    name: string;
    nameError: string;
    submitting: boolean;
    items: Array<ReplacementItem>;
    subs: Array<IdTitleItem>;
    replaces: Array<FileReplacementDto>;
  } {
    return {
      name: '',
      nameError: '',
      submitting: false,
      items: [{ subId: undefined, fuhId: undefined, files: [] }],
      subs: [],
      replaces: [],
    };
  },
  computed: {
    ...mapGetters(['companyId']),
    ...mapState(['selectedApplicationId', 'selectedFormat', 'selectedRegion']),
    nameRules(): InputValidationRules {
      return this.nameError ? [this.nameError] : [];
    },
  },
  watch: {
    name: function () {
      this.nameError = '';
    },
  },
  async mounted() {
    this.init();
    this.name = this.treeNode.FileName;
    await this.getPotentialReplaces();
    await this.getExistingReplaces();
  },
  methods: {
    ...mapMutations(['refresh']),
    init() {
      this.subs = [];
      this.items = [{ subId: undefined, fuhId: undefined, files: [] }];
    },
    async setName() {
      this.name = (this.name || '').trim();
    },
    async getPotentialReplaces() {
      this.replaces = await filesService.GetPotentialReplaces(
        this.companyId,
        this.selectedApplicationId,
        this.treeNode.FileUseHistoryId,
      );

      this.subs = [];
      for (const r of this.replaces) {
        if (!this.subs.some((x) => x.id === r.SubId)) {
          this.subs.push({ id: r.SubId, title: r.SubUnitTitle });
        }
      }

      this.subs = this.subs.sort((a, b) => GlobalHelper.naturalSort(a.title, b.title));
    },
    async getExistingReplaces() {
      const existing = await filesService.GetExistingReplaces(
        this.companyId,
        this.selectedApplicationId,
        this.treeNode.FileUseHistoryId,
      );

      if (existing?.length > 0) {
        this.items = [];
      }

      for (const item of existing || []) {
        if (!this.subs.some((x) => x.id === item.SubId)) {
          this.subs.push({ id: item.SubId, title: item.SubUnitTitle });
        }

        this.replaces.push(item);
        this.items.push({
          subId: item.SubId,
          fuhId: item.FileUseHistoryId,
          files: [
            { id: item.FileUseHistoryId, title: item.FileUseHistoryTitle },
            ...this.getFiles(item.SubId),
          ],
        });
      }
    },
    async save() {
      this.submitting = true;

      try {
        await filesService.SaveReplaces(this.companyId, {
          appId: this.selectedApplicationId,
          sourceFileUseHistoryIds: [this.treeNode.FileUseHistoryId],
          targetFileUseHistoryIds: [
            ...new Set(this.items.filter((x) => x.fuhId).map((x) => x.fuhId || 0)),
          ],
        });

        ErrorHelper.addSnackbarMessage('Replace Successfully Updated.', 'success');

        EventBus.$emit(
          `reloadSubs${this.treeNode.TreeTypeId}`,
          this.items.filter((x) => x.subId).map((x) => x.subId),
        );

        EventBus.$emit(`replaceDialogClose${this.treeNode.TreeTypeId}`);

        this.$emit('close');
      } catch (error) {
        if (error instanceof BadRequestError) {
          this.nameError = error.message;
          return;
        }

        ErrorHelper.addSnackbarMessage(
          'Updating Replace relationships has failed. Please contact Support for assistance.',
          'error',
          '',
          true,
        );
        this.$emit('close', false);
      } finally {
        this.submitting = false;
      }
    },
    closeDialog() {
      this.$emit('close');
    },
    addItem(item: ReplacementItem) {
      this.items.splice(this.items.indexOf(item) + 1, 0, {
        subId: undefined,
        fuhId: undefined,
        files: [],
      });
    },
    deleteItem(item: ReplacementItem) {
      if (this.items.length > 1) {
        this.items = this.items.filter((x) => x !== item);
      } else {
        item.subId = undefined;
        item.fuhId = undefined;
      }
    },
    onSubChanged(value: number, item: ReplacementItem) {
      item.fuhId = undefined;
      item.files = this.getFiles(value);
    },
    getFiles(subId: number) {
      return this.replaces
        .filter((x) => x.SubId === subId)
        .map((x) => ({ id: x.FileUseHistoryId, title: x.FileUseHistoryTitle }))
        .sort((a, b) => GlobalHelper.naturalSort(a.title, b.title));
    },
  },
});
