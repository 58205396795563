import Vue from 'vue';

import Vuetify from 'vuetify/lib';

import '@mdi/font/css/materialdesignicons.css';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#0973b6',
        info: '#0973b6',
      },
    },
  },
  icons: {
    iconfont: 'mdi',
  },
});
