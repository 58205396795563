
import Vue from 'vue';

import $ from 'jquery';
import { mapGetters, mapState } from 'vuex';

import { contextMenuBuilder } from '@/builders/ContextMenuBuilder';
import DeleteFileDialog from '@/components/popups/DeleteFileDialog.vue';
import EditFileNameDialog from '@/components/popups/EditFileNameDialog.vue';
import EditFileTitleDialog from '@/components/popups/EditFileTitleDialog.vue';
import InventoryContextMenu from '@/components/popups/InventoryContextMenu.vue';
import ReplaceFilesDialog from '@/components/popups/ReplaceFilesDialog.vue';
import { ActionType, ApplicationEvent, ContextMenuItemKey, FileUseHistoryStatus } from '@/enums';
import { DownloadHelper } from '@/helpers/downloadHelper';
import { TreeType } from '@/helpers/Enums';
import { EventBus } from '@/helpers/EventBus';
import { LinkToShareHelper } from '@/helpers/linkToShareHelper';
import ReviewDataGridBuilder from '@/helpers/reviewDataGridBuilder';
import {
  ContextMenuContext,
  ContextMenuItem,
  ContextMenuItemStyle,
  CreateClipboardItemRequest,
} from '@/models';
import InventoryComponent from '@/models/inventoryComponent';
import TreeComponent from '@/models/treeComponent';
import { treeClipboardService } from '@/services';
import ServiceComponent from '@/services/componentService';

require('../../Content/Common/Common.css');

export default Vue.extend({
  components: {
    DeleteFileDialog,
    ReplaceFilesDialog,
    EditFileTitleDialog,
    EditFileNameDialog,
    InventoryContextMenu,
  },
  props: {
    activeTree: Number,
    headerRef: Object,
    isWebPublish: Boolean,
    search: {
      type: String,
    },
    mobileView: Boolean,
    treeNode: {
      type: Object as () => TreeComponent,
    },
  },
  data() {
    return {
      total: 0,
      emptySearch: false,
      loaded: false,
      editFileNameDialogOpen: false,
      editFileTitleDialogOpen: false,
      replaceFilesDialogOpen: false,
      deleteFileDialogOpen: false,
      showContextMenu: false,
      service: new ServiceComponent(),
      contextElement: '',
      menuYOffset: 0,
      menuXOffset: 0,
      selectedFuh: null,
      items: [] as TreeComponent[], // Populate your items array as needed
      itemsPerPage: 15,
      dataLoading: false,
      usedHeight: 100,
      currentPage: 1,
      selected: [] as TreeComponent[], // Track selected rows
      selectedTitle: 'Select item(s) to view bulk actions', // Track selected rows
      contextMenuItems: [] as ContextMenuItem[],
      headers: [
        { text: 'Title', value: 'Title', sortable: true, width: 200 },
        { text: 'Section', value: 'SectionNumber', sortable: true, width: 100 },
        { text: 'Section Path', value: 'SectionPath', sortable: true, width: 165 },
        { text: 'Operation', value: 'ActionTypeId', sortable: true, width: 115 },
        { text: 'Life Cycle Status', value: 'LifeCycleStatus', sortable: true, width: 160 },
        { text: 'File Name', value: 'FileName', sortable: true, width: 115 },
        { text: 'Checksum', value: 'Checksum', sortable: false, width: 125 },
        { text: 'Actual Checksum', value: 'ActualChecksum', sortable: false, width: 158 },
        { text: 'Submitted In', value: 'SubmittedIn', sortable: false, width: 123 },
        { text: 'File Size(KB)', value: 'FileSize', sortable: false, width: 122 },
      ],
      ActionType,
      FileUseHistoryStatus,
      contextMenuDropdownSelection: 0,
    };
  },
  computed: {
    DataGridBuilder() {
      return ReviewDataGridBuilder;
    },
    contextMenuItemStyle() {
      return ContextMenuItemStyle;
    },
    ...mapGetters(['companyId']),
    ...mapState(['selectedApplicationId', 'selectedFormat', 'selectedRegion', 'highlightedItem']),
  },
  watch: {
    mobileView() {
      this.$nextTick(() => this.changeHeight());
    },
    currentPage() {
      this.fetchData(false);
    },
    items() {
      this.itemsUpdated();
    },
    selected() {
      this.updateSelectedTitle();
      this.changeHeight();
    },
    treeNode(): void {
      this.itemsPerPage = this.itemsPerPage === -1 ? 15 : this.itemsPerPage;
      if (this.treeNode) {
        this.fetchData(true, true);
      }
    },
    itemsPerPage() {
      this.fetchData(false);
    },
  },
  mounted() {
    const el = document.getElementById('inventoryCardHeader') as HTMLElement;
    const resizeObserver = new ResizeObserver(() => {
      if (el) {
        this.changeHeight();
      }
    });
    resizeObserver.observe(el);

    EventBus.$on(ApplicationEvent.refreshInventory, () => this.refreshData());
    EventBus.$on(`filesGridRefreshData${this.activeTree}`, () => this.refreshData());
    EventBus.$on(`changeInventoryHeight`, () => this.changeHeight());
    this.refreshData();
  },
  beforeDestroy() {
    EventBus.$off(ApplicationEvent.refreshInventory);
    EventBus.$off(`filesGridRefreshData${this.activeTree}`);
    EventBus.$off('changeInventoryHeight');
  },
  created() {
    this.treeNode && this.fetchData();
  },
  methods: {
    itemsUpdated() {
      this.$nextTick(() => {
        this.changeHeight();

        ReviewDataGridBuilder.makeColumnsResizable(this.$refs.dataTable, this.items.length > 0);
      });
    },
    itemsPerPageUpdated(amount: number) {
      this.itemsPerPage = amount;
      this.itemsUpdated();
    },
    async refreshComponent() {
      this.$nextTick(async () => {
        if (this.selected && this.selected.length == 1) {
          await this.buildContextMenuFor(this.selected[0]);
        } else {
          this.clearContextMenu();
        }
      });
    },
    async currentItemsEvent(currentItems: any) {
      const startRange = this.itemsPerPage * (this.currentPage - 1);
      let j = 0;
      for (let i = startRange; i < startRange + currentItems.length; i++) {
        currentItems[j] = this.items[i];
        j++;
      }
    },
    async onSelectionChange() {
      setTimeout(() => this.changeHeight(), 100);
      this.updateSelectedTitle();
      await this.refreshComponent();
    },
    updateSelectedTitle() {
      if (!this.selected || this.selected.length == 0) {
        this.selectedTitle = 'Select item(s) to view bulk actions';
        return;
      }

      this.selectedTitle =
        this.selected.length == 1
          ? `${this.selected.length} selected item(s)`
          : 'Select only one item';
    },
    refreshData() {
      this.fetchData();
    },
    onEditFileNameDialogClose(renamed: boolean) {
      this.editFileNameDialogOpen = false;

      if (renamed) {
        this.$nextTick(() =>
          EventBus.$emit(`onEditFileNameDialogClose${this.activeTree}`, renamed),
        );
        this.$nextTick(() => EventBus.$emit(`filesGridRefreshData${this.activeTree}`));
      }
    },
    onEditFileTitleDialogClose(renamed: boolean) {
      this.editFileTitleDialogOpen = false;

      if (renamed) {
        this.$nextTick(() =>
          EventBus.$emit(`onEditFileTitleDialogClose${this.activeTree}`, renamed),
        );
        this.$nextTick(() => EventBus.$emit(`filesGridRefreshData${this.activeTree}`));
      }
    },
    onReplaceFilesDialogClose() {
      this.replaceFilesDialogOpen = false;
    },
    onDeleteFileDialogClose(deleted: boolean) {
      this.deleteFileDialogOpen = false;
      if (deleted) {
        this.$nextTick(() =>
          EventBus.$emit(`onDeleteFileDialogClose${this.activeTree}`, {
            deleted: deleted,
            fileUseHistoryId: this.selected[0].FileUseHistoryId,
          }),
        );
        this.$nextTick(() => EventBus.$emit(`filesGridRefreshData${this.activeTree}`));
        this.refreshComponent();
      }
    },
    expandNode(item: any): void {
      const model: InventoryComponent = this.treeNode as InventoryComponent;
      const data = {
        fileUseHistoryId: item.FileUseHistoryId,
        TreeType: model.TreeTypeId,
      };
      this.$emit('changeInventoryNodeToOpen', data);
    },
    rowClass(item: any): string {
      return item.Name === this.highlightedItem ? 'highlighted-item' : '';
    },
    fetchData(makeEmpty = true, once = false) {
      if (makeEmpty) {
        this.items = [];
        this.selected = [];
      }

      this.dataLoading = true;
      const model: InventoryComponent = this.treeNode as InventoryComponent;
      this.total = model.Total;
      if (model.TreeTypeId == null) {
        model.TreeTypeId = TreeType.Origin;
      }

      if (!this.selectedApplicationId) return;

      const perPage = this.itemsPerPage === -1 ? this.items.length : this.itemsPerPage;
      $.ajax({
        url: `./Inventory/GetInventoryPaging?companyId=${this.companyId}&appId=${this.selectedApplicationId}&format=${this.selectedFormat}&region=${this.selectedRegion}&Page=${this.currentPage}&PageSize=${perPage}&sortDirection=${1}&sortColumn=${'title'}`,
        contentType: 'application/json',
        dataType: 'json',
        data: model,
      })
        .done((result) => {
          if (makeEmpty) {
            this.items = [];
          }
          if (result.Data && result.Data.length > 0) {
            const tempData = result.Data.map((item: TreeComponent) => {
              return Object.assign(new TreeComponent(), item, {
                SectionId: this.treeNode.get('SectionId'),
                SectionExtensionId: this.treeNode.get('SectionExtensionId'),
                RepeatableSectionId: this.treeNode.get('RepeatableSectionId'),
                InRepeatGroup: this.treeNode.get('InRepeatGroup'),
                FileUseSetId: this.treeNode.get('FileUseSetId'),
                SubId: this.treeNode.get('SubId'),
                UnitId: this.treeNode.get('UnitId'),
                ReviewSectionId: this.treeNode.get('ReviewSectionId'),
                NodeTypeId: 6,
                NodeType: 6,
                TreeTypeId: 5,
                TreeType: 5,
                DoesExist: item.FileName !== null,
              });
            });

            if (once) {
              for (let i = 0; i < result.Total; i++) {
                const tempComp = new TreeComponent();
                tempComp.gridId = (i + 1).toString();
                this.items.push(tempComp);
              }
            }
            const startRange = this.itemsPerPage * (this.currentPage - 1);

            let j = 0;
            for (let i = 0; i < tempData.length; i++) {
              tempData[j].gridId = (i + startRange + 1).toString();
              this.items[i + startRange] = tempData[j];
              j++;
            }

            this.$nextTick(() => this.refreshComponent());
          }
          this.dataLoading = false;

          ReviewDataGridBuilder.UpdatePage(this.$refs.dataTable);
          this.$nextTick(() => this.changeHeight());
        })
        .fail(() => {
          this.dataLoading = false;
        });
    },
    encodeData(data: any): any {
      for (const key in data) {
        if (typeof data[key] === 'string') {
          data[key] = encodeURI(data[key]);
        }
      }
      return data;
    },
    changeHeight() {
      if (!(this.$refs.dataTable as any)?.$el) return;

      const popup = (this.$refs.dataTable as any).$el as HTMLDivElement;
      const hh = popup.getElementsByClassName('v-data-table__wrapper')[0] as HTMLDivElement;
      const hh2 = hh.firstChild as HTMLDivElement;

      if (hh2) {
        if (!(this.headerRef as any)?.$el || !(this.$refs.inventoryToolbarRef as any)?.$el) return;

        const headerRefHeight = ((this.headerRef as any).$el as HTMLDivElement).offsetHeight;
        const inventoryToolbarRefHeight = (
          (this.$refs.inventoryToolbarRef as any).$el as HTMLDivElement
        ).offsetHeight;
        if (hh.offsetHeight - headerRefHeight + 150 > hh2.offsetHeight) {
          this.usedHeight = headerRefHeight;
        } else {
          this.usedHeight = headerRefHeight + inventoryToolbarRefHeight;
        }
      }
    },
    async buildContextMenuFor(node: TreeComponent) {
      const context = new ContextMenuContext(
        this.isWebPublish,
        this.companyId,
        this.selectedApplicationId,
        this.activeTree,
        node,
      );
      this.contextMenuItems = await contextMenuBuilder.build(context);
    },
    clearContextMenu() {
      this.contextMenuItems = [];
    },
    async onContextMenuItemClick(item: ContextMenuItem, args: any | null = null) {
      this.$nextTick(() => {
        this.contextMenuDropdownSelection = 0;
      });

      const selectedNode = this.selected[0];
      if (!selectedNode) return;

      switch (item.key) {
        case ContextMenuItemKey.copyLink: {
          this.changeHeight();
          const item = Object.assign(new TreeComponent(), this.treeNode);
          LinkToShareHelper.copyLink(
            item,
            this.companyId,
            this.selectedApplicationId,
            this.selectedFormat,
            this.selectedRegion,
          );

          break;
        }
        case ContextMenuItemKey.downloadFile:
          await DownloadHelper.downloadFile(
            this.companyId,
            this.selected[0].FileUseHistoryId,
            null,
          );
          break;
        case ContextMenuItemKey.details:
          EventBus.$emit('showDetailsPopup', { item: this.selected[0], location: 2 });
          break;
        case ContextMenuItemKey.editFileTitle:
          this.editFileTitleDialogOpen = true;
          break;
        case ContextMenuItemKey.editFileName:
          this.editFileNameDialogOpen = true;
          break;
        case ContextMenuItemKey.replaceFiles:
          this.replaceFilesDialogOpen = true;
          break;
        case ContextMenuItemKey.copyForReused:
          {
            const request = new CreateClipboardItemRequest(
              this.selectedApplicationId,
              this.selected[0].SubId,
              this.selected[0].FileUseHistoryId ?? 0,
              this.selected[0].FileId ?? 0,
            );
            await treeClipboardService.create(this.companyId, request);
          }
          break;
        case ContextMenuItemKey.suspendFile:
          {
            const subId = args as number;
            const item = { id: subId, hisId: this.selected[0].FileUseHistoryId };
            this.$nextTick(() => EventBus.$emit(`onSuspendClickCall${this.activeTree}`, item));
          }
          break;
        case ContextMenuItemKey.removeSuspension:
          EventBus.$emit(`removeSuspension${this.activeTree}`, this.selected[0].FileUseHistoryId);
          break;
        case ContextMenuItemKey.deleteFile:
          this.deleteFileDialogOpen = true;
          break;
        default:
          throw Error(`No handler for ${item.key}.`);
      }

      await this.buildContextMenuFor(selectedNode);
    },
  },
});
