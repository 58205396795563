
import Vue from 'vue';

import $ from 'jquery';
import { mapGetters, mapState } from 'vuex';

import { ActionType } from '@/enums';
import { FileTypes, Panels } from '@/helpers/Enums';
import ErrorHelper from '@/helpers/errorHelper';
import { EventBus } from '@/helpers/EventBus';
import { FileComponent } from '@/models/fileComponent';
import HistoryTracker, { ViewHistory } from '@/pdf/history-tracker';
import NavigationHistory from '@/pdf/navigationHistory';
import PdfViewerGlobals from '@/pdf/pdfviewer-globals';
import Service from '@/pdf/pdfviewer-service';
import ComponentService from '@/services/componentService';

const IMAGE_FILE_TYPES: FileTypes[] = [
  FileTypes.GIF,
  FileTypes.PNG,
  FileTypes.JPG,
  FileTypes.JPEG,
  FileTypes.SVG,
];

export default Vue.extend({
  props: {
    nonAppActiveView: {
      type: Number,
    },
    isDocumentLoaded: {
      type: Boolean,
    },
    loadedDoc: {
      type: Object as () => FileComponent,
    },
  },
  data(): {
    srcXmlDoc: string;
    srcImage: string;
    service: Service;
    showXml: boolean;
    showImage: boolean;
    viewerText: string;
    history: HistoryTracker;
    showError: boolean;
    errorMessage: string;
  } {
    return {
      srcXmlDoc: '',
      srcImage: '',
      service: new Service(),
      showXml: false,
      showImage: false,
      viewerText: '',
      history: ViewHistory,
      showError: false,
      errorMessage: '',
    };
  },
  computed: {
    ...mapGetters(['companyId']),
    ...mapState(['selectedApplicationId', 'selectedFormat', 'selectedRegion']),
  },
  watch: {
    loadedDoc(file: FileComponent): void {
      this.showError = false;
      this.showXml = false;
      this.viewerText = '';
      this.srcImage = '';

      if (file.IsDeletePlaceholder) {
        const operationDisplayText = ActionType.displayText(this.selectedFormat, ActionType.Delete);
        const ex: any = {
          status: `This leaf is a ${operationDisplayText} Placeholder,`,
          statusText: `which ${operationDisplayText.toLowerCase()}s the related file.`,
        };
        this.handleFileError(ex, null, true);
        return;
      }

      if (file && file.FuhId) {
        this.clear();
        if (file.IsPlaceholder) {
          this.onRequestFilePlaceholder(file.FuhId, FileTypes.Other);
        } else if (file.Type === FileTypes.PDF) {
          return;
        } else if (file.Type === FileTypes.XML) {
          this.onRequestXmlOpen(this.companyId, file.FuhId);
        } else if (file.Type === FileTypes.TXT) {
          this.onRequestText(file.FuhId);
        } else if (IMAGE_FILE_TYPES.includes(file.Type!)) {
          this.onRequestImage(file.FuhId);
        } else {
          this.onRequestUnsupportedFile(file.FuhId, file.Type ? file.Type : FileTypes.Other);
        }
      }
    },
    nonAppActiveView(view: Panels) {
      if (view === Panels.Error) {
        this.showError = true;
        this.showXml = false;
        return;
      }
      this.errorMessage = '';
      this.showError = false;
      if (view == Panels.XmlViewer) {
        this.showXml = true;
        return;
      }
      this.showXml = false;
    },
  },
  mounted() {
    EventBus.$on('changeCanGoBack', (canGoBack: boolean) => {
      this.$emit('changeCanGoBack', canGoBack);
    });

    EventBus.$on('changeCanGoForward', (canGoForward: boolean) => {
      this.$emit('changeCanGoForward', canGoForward);
    });
  },
  methods: {
    async onRequestImage(fileId: number): Promise<void> {
      try {
        await PdfViewerGlobals.recordHistoryInSameDocument(this.nonAppActiveView);
        this.$emit('changeIsDocumentLoaded', false);

        const file = await this.service.getUrlFromFuhId(fileId, this.companyId);
        this.srcImage = file.Url;

        this.history.addIfDifferentFile(
          new NavigationHistory(fileId, null, '', file, file.FileType),
        );

        this.$emit('changeNonAppActiveView', Panels.ImageViewer);
        this.$emit('changeIsDocumentLoaded', true);
      } catch (ex) {
        this.handleFileError(ex, FileTypes.TXT);
      }
    },
    async onRequestText(fileId: number): Promise<void> {
      try {
        await PdfViewerGlobals.recordHistoryInSameDocument(this.nonAppActiveView);
        this.$emit('changeIsDocumentLoaded', false);
        const file = await this.service.getUrlFromFuhId(fileId, this.companyId);
        const docText = await $.get({
          url: file.Url,
          xhrFields: { withCredentials: false },
        });
        this.viewerText = docText;
        this.history.addIfDifferentFile(
          new NavigationHistory(fileId, null, '', file, FileTypes.TXT),
        );
        this.$emit('changeNonAppActiveView', Panels.TxtViewer);

        this.$emit('changeIsDocumentLoaded', true);
      } catch (ex) {
        this.handleFileError(ex, FileTypes.TXT);
      }
    },
    async onRequestXmlOpen(companyId: number, fuhId: number): Promise<void> {
      try {
        await PdfViewerGlobals.recordHistoryInSameDocument(this.nonAppActiveView);
        this.$emit('changeIsDocumentLoaded', false);
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const result = await new ComponentService().doesFileExistById(this.companyId, fuhId);
        const url = `xmlviewer/${this.companyId}/${
          this.selectedApplicationId || 0
        }/GetXMLFile?fuhId=${fuhId}`;
        this.srcXmlDoc = url;
        const newHistory = new NavigationHistory(fuhId, null, url, '', FileTypes.XML);
        this.history.addIfDifferentFile(newHistory);
      } catch (ex) {
        this.handleFileError(ex, FileTypes.XML);
      }
    },
    async onRequestUnsupportedFile(fileId: number, type: FileTypes): Promise<void> {
      try {
        await PdfViewerGlobals.recordHistoryInSameDocument(this.nonAppActiveView);
        this.$emit('changeIsDocumentLoaded', false);
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const result = await new ComponentService().doesFileExistById(this.companyId, fileId);
        this.history.addPlaceholder(type);
        this.$emit('changeIsUnsupportedFile', true);
        this.$emit('changeShowOverlayMessage', 'unsupported');
      } catch (ex) {
        this.handleFileError(ex, type);
      }
    },
    async onRequestFilePlaceholder(fileId: number, type: FileTypes): Promise<void> {
      try {
        await PdfViewerGlobals.recordHistoryInSameDocument(this.nonAppActiveView);
        this.$emit('changeIsDocumentLoaded', false);
        this.history.addPlaceholder(type);
        this.$emit('changeIsUnsupportedFile', true);
        this.$emit('changeShowOverlayMessage', 'placeholder');
      } catch (ex) {
        this.handleFileError(ex, type);
      }
    },
    handleFileError(ex: any, type: FileTypes | null, showCustomError = false) {
      if (!showCustomError) {
        this.$emit('changeShowOverlayMessage', 'missing');
      } else {
        this.errorMessage = `${ex.status} ${ex.statusText}`;
        this.showError = true;
        this.$emit('changeNonAppActiveView', Panels.Error);
      }
      this.history.addPlaceholder(type);
      this.srcXmlDoc = '';
      this.viewerText = '';
    },
    onXmlLoaded(): void {
      if (this.loadedDoc && this.srcXmlDoc != '') {
        this.$emit('changeIsDocumentLoaded', true);
        this.$emit('changeNonAppActiveView', Panels.XmlViewer);
        if (this.srcXmlDoc.length > 0)
          new ComponentService().isXmlStyleSheetBad(
            this.onXmlStyleSheetBadSuccess,
            `xmlviewer/IsXmlStyleSheetBad`,
          );
      }
    },
    onXmlStyleSheetBadSuccess(result: any): void {
      if (result === 'True') {
        ErrorHelper.addSnackbarMessage(
          'Could not load stylesheet, an unstyled version of the XML is shown.',
          'snackbarWarning',
        );
      }
    },
    clear(): void {
      //this.$emit('changeShowOverlayMessage', '');
      this.srcXmlDoc = '';
      this.viewerText = '';
    },
  },
});
