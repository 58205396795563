
import Vue from 'vue';

import CopyButton from '@/components/details/CopyButton.vue';
import DetailsHelper from '@/helpers/detailsHelper';
import GlobalHelper from '@/helpers/globalHelper';
export default Vue.extend({
  components: {
    CopyButton,
  },
  props: {
    reuseInfo: {
      type: [Array, Object],
    },
  },
  data(): {
    reuses: any;
  } {
    let currentAppNumber: string | null = null;
    const indexOfCurrentApp = this.reuseInfo
      .map((a: { InCurrentApp: boolean }) => a.InCurrentApp)
      .indexOf(true);
    if (indexOfCurrentApp != -1) currentAppNumber = this.reuseInfo[indexOfCurrentApp].AppNumber;
    const groupedData = DetailsHelper.makeArrayNestedByPropertyName(this.reuseInfo, 'AppNumber');
    const sortedData = DetailsHelper.sortCurrentAppFirst(groupedData, currentAppNumber);
    return {
      reuses: sortedData,
    };
  },
  mounted(): void {
    const dividers = document.querySelectorAll('#detailsCard > .pa-3 > .v-divider');
    const lastDivider = dividers[dividers.length - 1];
    lastDivider.parentElement!.removeChild(lastDivider);
  },
  methods: {
    getActionTypeImage(actionTypeId: number): string | null {
      switch (actionTypeId) {
        case 0:
        case 1:
        case 5:
        case 6:
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          return GlobalHelper.getPath(
            // eslint-disable-next-line @typescript-eslint/no-var-requires
            require('../../Content/images/treeicons/ico-reused-new-file-a.svg'),
          );
        case 2:
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          return GlobalHelper.getPath(
            // eslint-disable-next-line @typescript-eslint/no-var-requires
            require('../../Content/images/treeicons/ico-reused-delete-file-a.svg'),
          );
        case 3:
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          return GlobalHelper.getPath(
            // eslint-disable-next-line @typescript-eslint/no-var-requires
            require('../../Content/images/treeicons/ico-reused-replace-file-a.svg'),
          );
        case 4:
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          return GlobalHelper.getPath(
            // eslint-disable-next-line @typescript-eslint/no-var-requires
            require('../../Content/images/treeicons/ico-reused-append-file-a.svg'),
          );
      }
      return null;
    },
    getActionTypeWord(actionTypeId: number): string | null {
      const word = DetailsHelper.getActionTypeWord(actionTypeId);
      return word;
    },
  },
});
