
import Vue from 'vue';

import { mapGetters, mapState } from 'vuex';

import BaseTree from '@/components/tree/BaseTree.vue';
import { EventBus } from '@/helpers/EventBus';
import { SearchHelper } from '@/helpers/searchHelper';
import UserRightsComponent from '@/models/userRightsComponent';
import ComponentService from '@/services/componentService';

export default Vue.extend({
  components: {
    BaseTree,
  },
  props: {
    parentSearchVisible: {
      type: Boolean,
    },
    searchText: {
      type: String,
    },
    treeSearchResults: {
      type: Array,
    },
    bind: {
      type: Boolean,
    },
    activeTree: {
      type: Number,
    },
    selectedNode: {
      type: Object,
    },
    showHide: {
      type: Boolean,
    },
    showDetails: {
      type: Boolean,
    },
    showContextMenu: {
      type: Boolean,
    },
    isWebPublish: Boolean,
    userRights: {
      type: Object as () => UserRightsComponent,
      default: new UserRightsComponent(),
    },
  },
  data(): {
    errorColor: string;
    errorIcon: string;
    retryVisible: boolean;
    searching: boolean;
    searchErrorMessage: string;
    searchVisible: boolean;
    searchMessageVisible: boolean;
  } {
    return {
      errorColor: 'info',
      errorIcon: 'mdi-information-outline',
      retryVisible: false,
      searching: false,
      searchErrorMessage: '',
      searchVisible: false,
      searchMessageVisible: false,
    };
  },
  computed: {
      ...mapGetters(['companyId']),
      ...mapState(['selectedApplicationId', 'selectedFormat', 'selectedRegion'])
  },
  watch: {
    searchText(): void {
      if (this.searchText !== null) {
        this.$emit('changeTreeSearchResults', []);
        this.searchMessageVisible = false;
        this.searching = true;
        this.searchVisible = false;
        this.search();
      }
    },
  },
  methods: {
    retry(): void {
      EventBus.$emit('trees_retry');
    },
    showNoResultsMessage(): void {
      this.searchMessageVisible = true;
      this.searchVisible = false;
      this.searching = false;
      this.errorColor = 'info';
      this.errorIcon = 'mdi-information-outline';
      this.searchErrorMessage = 'No results found.';
    },
    search(): void {
      const data = { value: this.searchText, companyId: this.companyId, appId: this.selectedApplicationId, format: this.selectedFormat, region: this.selectedRegion};
      const url = './Search/Search';
      new ComponentService().searchText(data, this.searchTextSuccess, this.searchTextError, url);
    },
    searchTextSuccess(result: any): void {
      this.$emit('removeDisable');
      if (result.Data != null && result.Data.length === 0) {
        this.showNoResultsMessage();
      } else {
        this.appendResultsToTree(result.Data);
      }
    },
    searchTextError(result: any, errors: any): void {
      this.$emit('removeDisable');
      if (errors[0].includes('too many results')) {
        this.appendResultsToTree(result.Data);
        this.showErrorNotification(errors[0]);
      } else {
        this.showErrorNotification(null);
      }
    },
    showErrorNotification(message: string | null): void {
      this.searchMessageVisible = true;
      if (message === null) {
        this.errorColor = 'error';
        this.errorIcon = 'mdi-alert-outline';
        this.searchErrorMessage = 'Something went wrong.';
      } else {
        this.errorColor = 'info';
        this.errorIcon = 'mdi-information-outline';
        this.searchErrorMessage = message;
      }
    },
    appendResultsToTree(result: any): void {
      this.searchMessageVisible = false;
      this.searching = false;
      this.searchVisible = true;
      this.$emit('changeTreeSearchResults', result);
      this.$nextTick(() => {
        SearchHelper.addDataAndMark(this.searchText);
      });
    },
  },
});
