
import Vue from 'vue';

import { mapGetters } from 'vuex';

import { DownloadHelper } from '@/helpers/downloadHelper';
import GlobalHelper from '@/helpers/globalHelper';
import { FileComponent } from '@/models/fileComponent';

export default Vue.extend({
  props: {
    showOverlayMessage: {
      type: String,
    },
    loadedDoc: {
      type: Object as () => FileComponent,
    },
  },
  data(): {
    messageType: string;
    loadFile: FileComponent | null;
  } {
    return {
      messageType: '',
      loadFile: null,
    };
  },
  computed: {
    ...mapGetters(['companyId']),
    missingFileUrl(): string {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      // eslint-disable-next-line @typescript-eslint/no-var-requires
      return GlobalHelper.getPath(require('../../Content/images/missing_file.svg'));
    },
    unViewableUrl(): string {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      // eslint-disable-next-line @typescript-eslint/no-var-requires
      return GlobalHelper.getPath(require('../../Content/images/un_viewable.svg'));
    },
    placeholderUrl(): string {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      // eslint-disable-next-line @typescript-eslint/no-var-requires
      return GlobalHelper.getPath(require('../../Content/images/placeholder.svg'));
    },
  },
  watch: {
    showOverlayMessage(type: string): void {
      this.messageType = type;
    },
    loadedDoc(loadFile: FileComponent): void {
      this.loadFile = loadFile;
    },
  },
  methods: {
    downloadFile() {
      DownloadHelper.downloadFile(this.companyId, (this.loadFile as FileComponent).FuhId, null);
    },
  },
});
