import ErrorHelper from '@/helpers/errorHelper';
import GlobalHelper from '@/helpers/globalHelper';
import {
  CreateClipboardItemRequest,
  PasteClipboardItemRequest, PasteClipboardItemResponse,
  TreeClipboardModel,
} from '@/models';

export class TreeClipboardService {
  public async getItem(companyId: number): Promise<TreeClipboardModel> {
    return await GlobalHelper.http(
      `${window.sessionUrl}TreeClipboard/Get?companyId=${companyId}`,
      ErrorHelper.TreeClipboardItem,
    );
  }

  public async hasItem(companyId: number): Promise<boolean> {
    const response = await this.getItem(companyId);
    return !!response;
  }

  public async create(companyId: number, request: CreateClipboardItemRequest): Promise<void> {
    const response = await fetch(
      `${window.sessionUrl}TreeClipboard/Create?companyId=${companyId}`,
      {
        method: 'POST',
        body: JSON.stringify(request),
        headers: {
          'Content-Type': 'application/json',
        },
      },
    );

    if (!response.ok) {
      const errorMessage = JSON.parse(await response.text());
      throw Error(errorMessage);
    }
  }

  public async paste(companyId: number, request: PasteClipboardItemRequest): Promise<PasteClipboardItemResponse> {
    const response = await fetch(`${window.sessionUrl}TreeClipboard/Paste?companyId=${companyId}`, {
      method: 'POST',
      body: JSON.stringify(request),
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (!response.ok) {
      const errorMessage = JSON.parse(await response.text());
      throw Error(errorMessage);
    }

    return await response.json();
  }

  public async clear(companyId: number): Promise<void> {
    const response = await fetch(`${window.sessionUrl}TreeClipboard/Clear?companyId=${companyId}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (!response.ok) {
      const errorMessage = JSON.parse(await response.text());
      throw Error(errorMessage);
    }
  }
}

export const treeClipboardService = new TreeClipboardService();
