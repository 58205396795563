export default class Filters {
  public static HideEmptyFilter: any = {
    filters: [
      // Hide Empty Section
      {
        field: 'HasFiles',
        operator: 'eq',
        value: true,
      },
      {
        field: 'DontHide',
        operator: 'eq',
        value: true,
      },
      // Don't hide FileUseHistories
      {
        field: 'FileUseHistoryId',
        operator: 'neq',
        value: null,
      },
      // Don't hide Subs
      {
        field: 'SectionId',
        operator: 'eq',
        value: null,
      },
      {
        // Don't hide Units
        filters: [
          {
            field: 'SubId',
            operator: 'eq',
            value: null,
          },
          {
            field: 'ReviewSectionId',
            operator: 'eq',
            value: 0,
          },
        ],
        logic: 'and',
      },
      {
        // Don't hide modules for LC/Current
        filters: [
          {
            field: 'SubId',
            operator: 'eq',
            value: null,
          },
          {
            field: 'ParentId',
            operator: 'eq',
            value: null,
          },
        ],
        logic: 'and',
      },
    ],
    logic: 'or',
  };
}
