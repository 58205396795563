
import Vue from 'vue';

import { InputValidationRules } from 'vuetify';
import { mapGetters, mapMutations, mapState } from 'vuex';

import ErrorHelper from '@/helpers/errorHelper';
import { BadRequestError } from '@/models/Errors';
import { filesService } from '@/services';

export default Vue.extend({
  props: {
    open: Boolean,
    treeNode: { type: Object, default: null },
  },
  data(): {
    name: string;
    nameError: string;
    sectionName: string;
    submitting: boolean;
  } {
    return {
      name: '',
      nameError: '',
      sectionName: '',
      submitting: false,
    };
  },
  computed: {
    ...mapGetters(['companyId']),
    ...mapState(['selectedApplicationId', 'selectedFormat', 'selectedRegion']),
    nameRules(): InputValidationRules {
      return this.nameError ? [this.nameError] : [];
    },
  },
  watch: {
    open(value) {
      if (value) {
        this.name = this.treeNode.Title;
        this.sectionName = this.treeNode.parentNode().Title;
      }
    },
    name: function () {
      this.nameError = '';
    },
  },
  methods: {
    ...mapMutations(['refresh']),
    async setName() {
      this.name = (this.name || '').trim();
    },
    async addFolderClick() {
      this.submitting = true;

      try {
        await filesService.editFolder(this.companyId, {
          AppId: this.selectedApplicationId,
          SubId: this.treeNode.SubId,
          Title: (this.name || '').trim(),
          SectionExtensionId: this.treeNode.SectionExtensionId,
        });

        ErrorHelper.addSnackbarMessage('The folder has been saved successfully.', 'success');
        this.$emit('close', true);
      } catch (error) {
        if (error instanceof BadRequestError) {
          this.nameError = error.message;
          return;
        }

        ErrorHelper.addSnackbarMessage('Editing the folder failed', 'error', '', true);
        this.$emit('close', false);
      } finally {
        this.submitting = false;
      }
    },
    closeDialog() {
      this.$emit('close');
    },
  },
});
