
import Vue from 'vue';

import { chain, isString } from 'lodash';
import { mapGetters, mapState } from 'vuex';

import Dialog from '@/components/popups/Dialog.vue';
import { EntityDialogMode } from '@/enums';
import ErrorHelper from '@/helpers/errorHelper';
import { BadRequestError } from '@/models/Errors';
import TreeComponent from '@/models/treeComponent';
import {
  ControlledHeaderValue,
  HeaderData,
  KeywordHistory,
  RepeatableSectionHeaderValue,
} from '@/models/WebPublish';
import { repeatableSectionsService } from '@/services';

interface HeaderWithValue {
  HeaderId: number;
  Description: string;
  IsControlled: boolean;
  Optional: boolean;
  ControlledValues: ControlledHeaderValue[];
  OriginalValue: string;
  Value: string | ControlledHeaderValue;
}

const STUDY_ID_HEADER_DESCRIPTION = 'Study Id';
const STUDY_TITLE_HEADER_DESCRIPTION = 'Study Title';
const STUDY_ID_STUDY_TITLE_SEPARATOR = '_$';
//970006 is the Id for the study Id - title header
const STUDY_ID_STUDY_TITLE_HEADER_ID = 970006;

class HeadersConverter {
  public static FromDto(sourceHeaders: HeaderData[], values?: KeywordHistory[]): HeaderWithValue[] {
    const result = this.convertHeaders(sourceHeaders);
    if (values) {
      this.setHeaderValues(result, values);
    }

    return result;
  }

  public static IsValidHeader(header: HeaderWithValue): boolean {
    const headerValue = header.Value;
    return headerValue !== null;
  }

  public static ToDto(headers: HeaderWithValue[]): RepeatableSectionHeaderValue[] {
    const getHeaderValue = (header: HeaderWithValue): RepeatableSectionHeaderValue => {
      if (!HeadersConverter.IsValidHeader(header)) header.Value = '';

      const headerValue: string | ControlledHeaderValue = header.Value;
      if (isString(headerValue)) {
        return {
          HeaderId: header.HeaderId,
          Value: headerValue,
        };
      }

      return {
        HeaderId: header.HeaderId,
        KeywordId: headerValue.KeywordId,
        KeywordHistoryId: headerValue.KeywordHistoryId,
        Value: headerValue.Value,
      };
    };

    const getStudyIdAndTitleValue = (headers: HeaderWithValue[]): string => {
      const studyIdHeader = headers.find((h) => h.Description === STUDY_ID_HEADER_DESCRIPTION)!;
      const studyTitleHeader = headers.find(
        (h) => h.Description === STUDY_TITLE_HEADER_DESCRIPTION,
      )!;

      return `${studyIdHeader.Value}${STUDY_ID_STUDY_TITLE_SEPARATOR}${studyTitleHeader.Value}`;
    };

    return chain(headers)
      .groupBy((header) => header.HeaderId)
      .map((headers, key) => {
        if (headers.length === 2) {
          return {
            HeaderId: Number(key),
            Value: getStudyIdAndTitleValue(headers),
          };
        }

        return getHeaderValue(headers[0]);
      })
      .value();
  }

  private static convertHeaders(sourceHeaders: HeaderData[]): HeaderWithValue[] {
    const headers = sourceHeaders.map(
      (h: HeaderData) =>
        ({
          Description: h.Description,
          HeaderId: h.HeaderId,
          Optional: h.Optional,
          Value: '',
          ControlledValues: h.ControlledValues,
          IsControlled: h.IsControlled,
        }) as HeaderWithValue,
    );
    //970006 is the Id for the study Id - title header in the DB
    const index = headers.findIndex((h) => h.HeaderId == STUDY_ID_STUDY_TITLE_HEADER_ID);
    if (index >= 0) {
      const studyHeader = headers[index];

      // Replace combined "Study Id and Study Title" header with 2 separate headers
      headers.splice(
        index,
        1,
        ...[
          {
            IsControlled: false,
            Description: STUDY_ID_HEADER_DESCRIPTION,
            Optional: studyHeader.Optional,
            HeaderId: studyHeader.HeaderId,
            ControlledValues: [],
            OriginalValue: '',
            Value: '',
          },
          {
            IsControlled: false,
            Description: STUDY_TITLE_HEADER_DESCRIPTION,
            Optional: studyHeader.Optional,
            HeaderId: studyHeader.HeaderId,
            ControlledValues: [],
            OriginalValue: '',
            Value: '',
          },
        ],
      );
    }

    return headers;
  }

  private static setHeaderValues(headers: HeaderWithValue[], headerValues: KeywordHistory[]) {
    for (const headerValue of headerValues) {
      const foundHeaders = headers.filter((header) => header.HeaderId == headerValue.HeaderId);
      if (foundHeaders.length === 1) {
        const header = foundHeaders[0];
        if (header.IsControlled) {
          const controlledHeaderValue = header.ControlledValues.filter(
            (cv) => cv.KeywordId == headerValue.KeywordId,
          )[0];
          header.Value = {
            KeywordId: controlledHeaderValue.KeywordId,
            KeywordHistoryId: controlledHeaderValue.KeywordHistoryId,
            Value: headerValue.Value,
          };
        } else {
          header.Value = headerValue.Value;
        }

        header.OriginalValue = headerValue.Value;
        continue;
      }

      if (foundHeaders.length > 1) {
        for (const foundHeader of foundHeaders) {
          switch (foundHeader.Description) {
            case STUDY_ID_HEADER_DESCRIPTION:
              foundHeader.OriginalValue = headerValue.Value.split(
                STUDY_ID_STUDY_TITLE_SEPARATOR,
              )[0];
              foundHeader.Value = foundHeader.OriginalValue;
              break;
            case STUDY_TITLE_HEADER_DESCRIPTION:
              foundHeader.OriginalValue = headerValue.Value.split(
                STUDY_ID_STUDY_TITLE_SEPARATOR,
              )[1];
              foundHeader.Value = foundHeader.OriginalValue;
              break;
            default:
              foundHeader.Value = headerValue.Value;
              foundHeader.OriginalValue = headerValue.Value;
              break;
          }
        }
      }
    }

    // For optional headers we may have no value
    // In this case we must set empty string
    for (const header of headers) {
      if (!header.Value) {
        header.OriginalValue = '';
        header.Value = '';
      }
    }
  }
}

const defaultOutputFolderField = {
  Id: 1,
  Description: 'Output folder',
  Optional: true,
  Value: '',
  PreviousValue: '',
  IsChecked: false,
  PreviousIsChecked: false,
  OrderIndex: 0,
  CheckBoxTitle: 'Create new folder?',
};

export default Vue.extend({
  components: {
    // eslint-disable-next-line vue/no-reserved-component-names
    Dialog,
  },
  props: {
    open: { type: Boolean },
    mode: { type: Number as () => EntityDialogMode },
    treeNode: {
      type: Object as () => TreeComponent,
      default: null,
    },
  },
  data(): {
    loading: boolean;
    saving: boolean;
    headers: HeaderWithValue[];
    outputFolderField: {
      Id: number;
      Description: string;
      Optional: boolean;
      Value: string;
      PreviousValue: string;
      IsChecked: boolean;
      PreviousIsChecked: boolean;
      OrderIndex: number;
      CheckBoxTitle: string;
    };
    saveErrorMessage: string;
    timerId: any;
    items: any[];
  } {
    return {
      loading: false,
      saving: false,
      headers: [],
      outputFolderField: defaultOutputFolderField,
      saveErrorMessage: '',
      timerId: undefined,
      items: [],
    };
  },
  computed: {
    ...mapState(['selectedApplicationId']),
    ...mapGetters(['companyId']),
    STUDY_TITLE_HEADER_DESCRIPTION() {
      return STUDY_TITLE_HEADER_DESCRIPTION;
    },
    STUDY_ID_HEADER_DESCRIPTION() {
      return STUDY_ID_HEADER_DESCRIPTION;
    },
    sectionTitle(): string {
      return this.treeNode?.Title;
    },
    saveButtonEnabled(): boolean {
      const { error } = this.validateFields();
      return error == undefined;
    },
    validationError(): string | undefined {
      const { error } = this.validateFields();
      return error;
    },
  },
  watch: {
    open(value) {
      if (value) {
        // Although we have "headers = []" in the data section, we still have to have this line as well,
        // so we don't have flickering when open the dialog for the second (and later) times

        this.headers = [];
        this.outputFolderField = defaultOutputFolderField;
        this.saveErrorMessage = '';
        this.timerId = undefined;
        this.items = [];

        this.load();
      }
    },
  },
  methods: {
    getItemsDebounced(header: any, prefix: string, timeout = 500) {
      if (prefix == null) return;

      clearTimeout(this.timerId);

      this.timerId = setTimeout(async () => {
        await this.getItems(header, prefix);
      }, timeout);
    },
    async getItems(header: any, prefix: string) {
      let formattedPrefix = prefix;

      if (header.HeaderId === STUDY_ID_STUDY_TITLE_HEADER_ID) {
        if (prefix.includes(STUDY_ID_STUDY_TITLE_SEPARATOR) || prefix.endsWith('_')) {
          this.items = [];
          return;
        }

        formattedPrefix = prefix
          .replace(' - ', STUDY_ID_STUDY_TITLE_SEPARATOR)
          .replace(' -', STUDY_ID_STUDY_TITLE_SEPARATOR);
      }

      const items = await repeatableSectionsService.getKeywordValues(
        this.companyId,
        header.HeaderId,
        formattedPrefix,
      );

      this.items = [{ HeaderId: header.HeaderId, items }];
    },
    validateFields(): { error: string | undefined } {
      if (this.headers.length == 0) {
        return { error: undefined };
      }

      if (this.saveErrorMessage) {
        return { error: this.saveErrorMessage };
      }

      const hasEmptyRequiredHeaders = this.headers.some(
        (header) => !header.Optional && !header.Value,
      );

      if (hasEmptyRequiredHeaders) {
        return { error: 'All required fields must have values' };
      }

      const atLeastOneHeaderHasValue = this.headers.some((header) => header.Value);
      if (!atLeastOneHeaderHasValue) {
        return { error: 'At least one field must have value' };
      }

      if (this.mode == EntityDialogMode.edit) {
        const hasChangedHeaderValue = this.headers.some((header) => {
          if (!HeadersConverter.IsValidHeader(header)) header.Value = '';
          const isSameValue = isString(header.Value)
            ? header.Value == header.OriginalValue
            : header.Value.Value == header.OriginalValue;

          return !isSameValue;
        });

        const hasOutputFolderChanges =
          this.outputFolderField.PreviousValue === this.outputFolderField.Value &&
          this.outputFolderField.PreviousIsChecked === this.outputFolderField.IsChecked;

        if (!hasChangedHeaderValue && hasOutputFolderChanges) {
          return { error: "Can't save because there are no changes yet" };
        }
      }

      return { error: undefined };
    },
    handleFieldChange() {
      this.saveErrorMessage = '';
    },
    clickItem(header: any, val: string) {
      // we need to process it in next ticket to correct handle header value assignment
      // in case when value was already set SB-6944
      this.$nextTick(() => {
        let assigned = false;
        if (header.Description === STUDY_ID_HEADER_DESCRIPTION) {
          const substrs = (val || '').split('_$');
          if (substrs.length > 1) {
            assigned = true;
            header.Value = substrs[0];
            const headerTitle = this.headers.find(
              (x) => x.Description == STUDY_TITLE_HEADER_DESCRIPTION,
            );
            if (headerTitle) headerTitle.Value = substrs[1];
          }
        }
        !assigned && (header.Value = val);
        this.items = [];
      });
    },
    async load() {
      this.loading = true;
      try {
        const reviewSectionId = this.treeNode.ReviewSectionId;
        let headers: HeaderWithValue[] = [];
        if (this.mode == EntityDialogMode.create) {
          const contextGroupData = await repeatableSectionsService.getRepeatableSectionCreateData(
            this.companyId,
            reviewSectionId,
          );
          headers = HeadersConverter.FromDto(contextGroupData.Headers);
          this.outputFolderField.Value = '';
        } else {
          const contextGroupData = await repeatableSectionsService.getRepeatableSectionEditData(
            this.companyId,
            this.treeNode.RepeatableSectionId ?? 0,
            reviewSectionId,
            this.treeNode.SubId ?? 0,
          );
          headers = HeadersConverter.FromDto(
            contextGroupData.Headers,
            contextGroupData.HeaderValues,
          );
          this.outputFolderField.Value =
            contextGroupData.RepeatableSection.CustomOutputFolder ?? '';
          this.outputFolderField.PreviousValue = this.outputFolderField.Value;
        }

        this.headers = headers;
        this.outputFolderField.IsChecked = this.outputFolderField.Value.trim() !== '';
        this.outputFolderField.PreviousIsChecked = this.outputFolderField.IsChecked;
        this.outputFolderField.OrderIndex = headers.length - 1;
      } catch {
        ErrorHelper.addSnackbarMessage(
          "Can't get information about context group",
          'error',
          '',
          true,
        );
        this.closeDialog(false);
      } finally {
        this.loading = false;
      }
    },
    async endEditAndSave() {
      setTimeout(async () => await this.save(), 0);
    },
    async save() {
      this.saving = true;

      try {
        if (this.mode == EntityDialogMode.create) {
          await this.saveCreate();
        } else if (this.mode == EntityDialogMode.edit) {
          await this.saveEdit();
        }

        ErrorHelper.addSnackbarMessage('Context group has been saved successfully.', 'success');
        this.closeDialog(true);
      } catch (e) {
        if (e instanceof BadRequestError) {
          this.saveErrorMessage = e.message;
        } else {
          ErrorHelper.addSnackbarMessage('Saving context group failed', 'error', '', true);
        }
      } finally {
        this.saving = false;
      }
    },
    async saveEdit() {
      const headerValues = HeadersConverter.ToDto(this.headers);

      // For edit mode we must use parent ReviewSection instead of current ReviewSection
      const parentNode = (this.treeNode as any).parentNode() as TreeComponent;

      await repeatableSectionsService.editRepeatableSection(this.companyId, {
        AppId: this.selectedApplicationId!,
        SubId: this.treeNode.SubId!,
        ReviewSectionId: this.treeNode.ReviewSectionId,
        RepeatableSectionId: this.treeNode.RepeatableSectionId!,
        ParentRepeatableSectionId: parentNode.RepeatableSectionId,
        HeaderValues: headerValues,
        OutputFolder: this.outputFolderField.IsChecked ? this.outputFolderField.Value : '',
        UseOutputFolder: this.outputFolderField.IsChecked,
      });
    },
    async saveCreate() {
      const headerValues = HeadersConverter.ToDto(this.headers);

      await repeatableSectionsService.createRepeatableSection(this.companyId, {
        AppId: this.selectedApplicationId!,
        SubId: this.treeNode.SubId!,
        ReviewSectionId: this.treeNode.ReviewSectionId,
        ParentRepeatableSectionId: this.treeNode.RepeatableSectionId,
        HeaderValues: headerValues,
        OutputFolder: this.outputFolderField.IsChecked ? this.outputFolderField.Value : '',
        UseOutputFolder: this.outputFolderField.IsChecked ?? false,
      });
    },
    closeDialog(created: boolean) {
      this.$emit('close', created);
    },
    getItemsForHeader(headerId: number) {
      return (this.items || []).find((x) => x.HeaderId === headerId)?.items || [];
    },
  },
});
