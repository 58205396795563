
import Vue from 'vue';

import GlobalHelper from '@/helpers/globalHelper';
export default Vue.extend({
  props: {
    contactDialogState: {
      type: Boolean,
    },
  },
  data(): {
    contactDialog: boolean;
    contactInfos: any[];
  } {
    return {
      contactDialog: false,
      contactInfos: [
        {
          href: 'mailto:support@certara.com',
          icon: 'mdi-email-outline',
          title: 'Email Support: support@certara.com',
        },
        {
          href: 'tel:+1-919-852-4620',
          icon: 'mdi-phone-outline',
          title: 'Call Support: +1-919-852-4620',
        },
      ],
    };
  },
  computed: {
    iconXUrl(): string {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      // eslint-disable-next-line @typescript-eslint/no-var-requires
      return GlobalHelper.getPath(require('../../Content/images/icon-x.svg'));
    },
  },
  watch: {
    contactDialogState(): void {
      this.contactDialog = this.contactDialogState;
    },
    contactDialog(): void {
      if (this.contactDialog === false) {
        this.$emit('changeContactDialogState');
      }
    },
  },
});
