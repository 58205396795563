
import Vue from 'vue';

import CopyButton from '@/components/details/CopyButton.vue';

export default Vue.extend({
  components: {
    CopyButton,
  },
  props: {
    header: {
      type: String,
    },
    items: {
      type: [String, Array],
    },
    keyName: {
      type: String,
    },
    valueName: {
      type: String,
    },
    oneline: {
      type: Boolean,
    },
    postValue: {
      type: String,
    },
  },
});
