
import Vue from 'vue';

import $ from 'jquery';
import { mapGetters, mapMutations, mapState } from 'vuex';

import DetailItem from '@/components/details/DetailItem.vue';
import DetailList from '@/components/details/DetailList.vue';
import GalenicForm from '@/components/details/GalenicForm.vue';
import DetailsHelper from '@/helpers/detailsHelper';
import { Detail } from '@/models/adaptedDetailComponent';
import ComponentService from '@/services/componentService';
import { RootMutations } from '@/store';

export default Vue.extend({
  components: {
    DetailList,
    DetailItem,
    GalenicForm,
  },
  props: {
    showAppDetails: {
      type: Boolean,
    },
    showAppSelector: {
      type: Boolean,
    },
  },
  data(): {
    showDetails: boolean;
    details: any;
    service: ComponentService;
    commonItems: string[];
  } {
    return {
      service: new ComponentService(),
      showDetails: false,
      details: null,
      commonItems: [
        'Region',
        'Format',
        'Application Number',
        'Application Type',
        'Sponsor Identifier',
        'Procedure',
        'ATC Code',
        'Product Name',
        'Application Folder',
        'Application Center',
      ],
    };
  },
  computed: {
    ...mapGetters(['companyId']),
    ...mapState(['selectedApplicationId', 'selectedFormat', 'selectedRegion']),
  },
  watch: {
    showAppSelector(): void {
      if (!this.showAppSelector) {
        this.service.getAppDetails(
          this.companyId,
          this.selectedApplicationId,
          this.selectedFormat,
          this.selectedRegion,
          this.onAppDetails,
        );
      }
    },
    showAppDetails(): void {
      this.onShowAppDetails();
    },
  },
  mounted(): void {
    this.onShowAppDetails();
  },
  methods: {
    ...mapMutations({
      close: RootMutations.closeModalWindow,
    }),
    orderedResults(data: any): Detail[] {
      return DetailsHelper.sortDetails(data, this.commonItems, (x: Detail) =>
        this.commonItems.includes(x.Header),
      );
    },
    onAppDetails(data: any): void {
      this.details = data;
      this.fixTabHeight();
    },
    onShowAppDetails(): void {
      if (this.showAppDetails && this.details === null) {
        this.service.getAppDetails(
          this.companyId,
          this.selectedApplicationId,
          this.selectedFormat,
          this.selectedRegion,
          this.onAppDetails,
        );
      }
    },
    getProperty(list: any, propName: string): string | null {
      const prop: any = list.InfoItems.find((x: any) => x.Header === propName);
      return prop ? prop.Content : null;
    },
    fixTabHeight(): void {
      // workaround for bug (https://github.com/vuetifyjs/vuetify/issues/6962)
      setTimeout(() => $('.v-window__container').css('height', '400'), 1000);
    },
  },
});
