
import Vue from 'vue';

import ApplicationWizardCard from '@/components/cards/ApplicationWizardCard.vue';
import CreateSubmissionCard from '@/components/cards/CreateSubmissionCard.vue';
import EditApplicationCard from '@/components/cards/EditApplicationCard.vue';
import EditSubmissionCard from '@/components/cards/EditSubmissionCard.vue';
import AppDetails from '@/components/details/AppDetails.vue';
import { ModalWindow } from '@/enums';
import { RootMutations } from '@/store';

export default Vue.extend({
  components: {
    AppDetails,
    ApplicationWizardCard,
    EditApplicationCard,
    CreateSubmissionCard,
    EditSubmissionCard,
  },
  computed: {
    model: {
      get() {
        return this.$store.state.modalWindow !== ModalWindow.none;
      },
      set() {
        this.close();
      },
    },
    applicationWizard(): boolean {
      return this.$store.state.modalWindow === ModalWindow.applicationWizard;
    },
    editApplication(): boolean {
      return this.$store.state.modalWindow === ModalWindow.editApplication;
    },
    createSubmission(): boolean {
      return this.$store.state.modalWindow === ModalWindow.createSubmission;
    },
    editSubmission(): boolean {
      return this.$store.state.modalWindow === ModalWindow.editSubmission;
    },
    appDetails(): boolean {
      return this.$store.state.modalWindow === ModalWindow.viewApplicationDetails;
    },
  },
  methods: {
    close() {
      this.$store.commit(RootMutations.closeModalWindow);
    },
  },
});
