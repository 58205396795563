import { EventBus } from '@/helpers/EventBus';
import PdfViewerGlobals from '@/pdf/pdfviewer-globals';

declare global {
  interface Window {
    previewPane: any;
    returnUrl: string;
    errorMessage: string;
    PdfViewerGlobals: PdfViewerGlobals;
    clipboardData: any;
    baseUrl: string;
    sessionUrl: string;
    version: string;
    infoClick: any;
    onHover: any;
    offHover: any;
    menuClick: any;
    publishClick: any;
  }

  interface Document {
    fullscreenElement: any;
    msFullscreenElement: any;
    mozFullScreenElement: any;
    webkitFullscreenElement: any;
    onmsfullscreenchange: any;
    onmozfullscreenchange: any;
    onwebkitfullscreenchange: any;
  }
}

function emitClickEvent(eventName: string, senderElement: any, parentElement: HTMLElement): void {
  const coordinates = parentElement.getBoundingClientRect();
  const treeNode = senderElement.parentElement.parentElement.querySelectorAll('.tree-item');
  const treeId = senderElement.getAttribute('treeId');
  EventBus.$emit(eventName, treeNode, treeId, coordinates);
}

function removeHighlights(oldNode: Element): void {
  oldNode.classList.remove('grey', 'lighten-3');
  oldNode.removeAttribute('id');
}

export function initWindow(pdfViewerGlobals: any) {
  window.onHover = (sender: any, message: string): void => {
    EventBus.$emit('tooltipOnHover', sender, message);
    window.PdfViewerGlobals = pdfViewerGlobals;
  };

  window.offHover = (message: string): void => {
    EventBus.$emit('tooltipOffHover', message);
  };

  window.infoClick = (sender: any): void => {
    const oldNode = document.querySelector('#selectedDetailsNode');
    const parent = sender.parentElement.parentElement as HTMLElement;

    if (oldNode) {
      removeHighlights(oldNode);
    }

    if (oldNode === parent) return;

    parent.classList.add('grey', 'lighten-3');
    parent.setAttribute('id', 'selectedDetailsNode');
    emitClickEvent('infoClick', sender, parent);
  };

  window.menuClick = (sender: any): void => {
    const oldNode = document.querySelector('#selectedContextNode');
    const parent = sender.parentElement.parentElement as HTMLElement;

    if (oldNode) {
      removeHighlights(oldNode);
    }

    if (oldNode === parent) {
      return;
    }

    parent.classList.add('grey', 'lighten-3');
    parent.setAttribute('id', 'selectedContextNode');
    emitClickEvent('menuClick', sender, parent);
  };

  window.publishClick = (sender: any): void => {
    const oldNode = document.querySelector('#selectedContextNode');
    const parent = sender.parentElement.parentElement as HTMLElement;

    if (oldNode) {
      removeHighlights(oldNode);
    }

    if (oldNode === parent) {
      return;
    }

    parent.classList.add('grey', 'lighten-3');
    parent.setAttribute('id', 'selectedContextNode');
    emitClickEvent('publishClick', sender, parent);
  };

  window.PdfViewerGlobals = PdfViewerGlobals;
}
