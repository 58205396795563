
import Vue from 'vue';

import { mapGetters, mapState } from 'vuex';

import { ApplicationEvent, EntityDialogMode } from '@/enums';
import { NodeType } from '@/helpers/Enums';
import ErrorHelper from '@/helpers/errorHelper';
import { EventBus } from '@/helpers/EventBus';
import { CreateClipboardItemRequest, PasteClipboardItemRequest } from '@/models';
import { BadRequestError } from '@/models/Errors';
import TreeComponent from '@/models/treeComponent';
import UserRightsComponent from '@/models/userRightsComponent';
import { filesService, treeClipboardService } from '@/services';

export default Vue.extend({
  props: {
    treeNode: {
      type: Object as () => TreeComponent,
    },
    selectedNode: {
      type: Object as () => TreeComponent,
      default: new TreeComponent(),
    },
    tree: {
      type: String,
    },
    treeObj: {
      type: Function,
    },
    originTreeObj: {
      type: Function,
    },
    isWebPublish: {
      type: Boolean,
    },
    userRights: {
      type: Object as () => UserRightsComponent,
      default: new UserRightsComponent(),
    },
    showHide: Boolean,
    getSectionsUrl: String,
    treeIntId: Number,
  },
  data(): {
    otherSubs: TreeComponent[];
    showSuspend: boolean;
    nodeTitle: string | null;
    nodeHasChildren: boolean;
    fileExists: boolean;
    isSectionExtension: boolean;
    isRepeatSection: boolean;
    treeNodeFuhId: number | null;
    lastTreeNode: any | null;
    actionTreeTypes: string[];
  } {
    return {
      otherSubs: [],
      showSuspend: false,
      nodeTitle: '',
      nodeHasChildren: false,
      fileExists: false,
      isSectionExtension: false,
      isRepeatSection: false,
      treeNodeFuhId: null,
      lastTreeNode: null,
      actionTreeTypes: ['Current', 'Lifecycle'],
    };
  },
  computed: {
    ...mapState(['selectedApplicationId', 'selectedRegion', 'selectedFormat']),
    ...mapGetters(['companyId', 'loaded']),
  },
  watch: {
    async treeNode(): Promise<void> {
      if (!this.treeNode) {
        return;
      }

      this.fileExists = this.treeNode.FileUseHistoryId ? this.treeNode.DoesExist : false;
      this.treeNodeFuhId = this.treeNode.FileUseHistoryId;
      this.isSectionExtension = !!this.treeNode.SectionExtensionId && !this.treeNodeFuhId;
      this.isRepeatSection =
        !!this.treeNode.RepeatableSectionId &&
        this.treeNode.NodeType == NodeType.RepeatSection &&
        !this.treeNode.StartsRepeatGroup;
      this.lastTreeNode = this.treeNode;
      this.nodeTitle = this.treeNode.Title || '';
      this.nodeHasChildren = this.treeNode.HasChildren;

      this.$nextTick(() => {
        this.$emit('addClickListener', this.fileExists);
      });
    },
  },
  beforeDestroy() {
    EventBus.$off(`onEditFileTitleDialogClose${this.treeIntId}`);
    EventBus.$off(`onEditFileNameDialogClose${this.treeIntId}`);
    EventBus.$off(`onAddFilesDialogClose${this.treeIntId}`);
    EventBus.$off(`onEditFolderDialogClose${this.treeIntId}`);
    EventBus.$off(`onAddFolderDialogClose${this.treeIntId}`);
    EventBus.$off(`onDeleteFolderDialogClose${this.treeIntId}`);
    EventBus.$off(`onDeleteFileDialogClose${this.treeIntId}`);
    EventBus.$off(`onCreateContextGroupDialogClose${this.treeIntId}`);
    EventBus.$off(`onDeleteContextGroupDialogClose${this.treeIntId}`);
    EventBus.$off(`onSuspendClickCall${this.treeIntId}`);
    EventBus.$off(`filesGridRefreshData${this.treeIntId}`);
    EventBus.$off(`filesGridRefreshDataParentNode${this.treeIntId}`);
    EventBus.$off(`removeSuspension${this.treeIntId}`);
    EventBus.$off(`reloadSubs${this.treeIntId}`);
    EventBus.$off(`filesGridReusedPasted${this.treeIntId}`);
  },
  mounted() {
    EventBus.$on(`onEditFileTitleDialogClose${this.treeIntId}`, (t: boolean) => {
      this.lastTreeNode = this.selectedNode;
      this.onEditFileTitleDialogClose(t);
    });
    EventBus.$on(`onEditFileNameDialogClose${this.treeIntId}`, (t: boolean) => {
      this.lastTreeNode = this.selectedNode;
      this.onEditFileNameDialogClose(t);
    });
    EventBus.$on(`onAddFilesDialogClose${this.treeIntId}`, (t: boolean) => {
      this.lastTreeNode = this.selectedNode;
      this.onAddFilesDialogClose(t);
    });
    EventBus.$on(`onEditFolderDialogClose${this.treeIntId}`, (t: boolean) => {
      this.lastTreeNode = this.selectedNode;
      this.onEditFolderDialogClose(t);
    });
    EventBus.$on(`onAddFolderDialogClose${this.treeIntId}`, (t: boolean) => {
      this.lastTreeNode = this.selectedNode;
      this.onAddFolderDialogClose(t);
    });
    EventBus.$on(`onDeleteFolderDialogClose${this.treeIntId}`, (t: boolean) => {
      this.lastTreeNode = this.selectedNode;
      this.onDeleteFolderDialogClose(t);
    });
    EventBus.$on(
      `onDeleteFileDialogClose${this.treeIntId}`,
      (args: { deleted: boolean; fileUseHistoryId: number }) => {
        this.onDeleteFileDialogClose(args);
      },
    );
    EventBus.$on(
      `onCreateContextGroupDialogClose${this.treeIntId}`,
      (created: boolean, mode: EntityDialogMode) => {
        this.onCreateContextGroupDialogClose(created, mode);
      },
    );
    EventBus.$on(`onDeleteContextGroupDialogClose${this.treeIntId}`, (t: boolean) => {
      this.onDeleteContextGroupDialogClose(t);
    });
    EventBus.$on(`onSuspendClickCall${this.treeIntId}`, async (t: any) => {
      await this.onSuspendClick(t.id, t.hisId);
    });
    EventBus.$on(`filesGridRefreshData${this.treeIntId}`, (historyId: number) => {
      this.lastTreeNode = this.selectedNode.FileUseHistoryId
        ? this.findNodeByFileUseHistoryId(this.selectedNode.FileUseHistoryId)?.parentNode()
        : this.getActiveNode();

      this.reloadActiveNodeAfterAddingFiles();
      if (this.lastTreeNode.SectionExtensionId) {
        this.reloadNode(this.lastTreeNode, true);
      } else {
        const originalFileNode = this.findNodeByFileUseHistoryId(historyId);
        if (originalFileNode) {
          this.reloadParentNodeOf(originalFileNode);
        }
      }
    });
    EventBus.$on(`filesGridRefreshDataParentNode${this.treeIntId}`, (parentNode: boolean) => {
      if (!parentNode) {
        this.reloadActiveNode();
      } else {
        this.reloadParentNodeOfActiveNode();
      }
    });
    EventBus.$on(`removeSuspension${this.treeIntId}`, (fileUseHistoryId: number) => {
      this.removeSuspension(fileUseHistoryId);
    });
    EventBus.$on(`replaceDialogClose${this.treeIntId}`, () => {
      this.lastTreeNode = this.selectedNode;
      this.onReplaceDialogClose();
    });
    EventBus.$on(`reloadSubs${this.treeIntId}`, (subIds: number[]) => {
      this.refreshSubs(subIds);
    });
    EventBus.$on(`filesGridReusedPasted${this.treeIntId}`, (fuhId: number) => {
      this.refreshFileInTree(fuhId, false, true);
    });
  },
  methods: {
    initOtherSubs() {
      let subs = this.treeObj().dataSource.data() as TreeComponent[];

      let displayOrder = 0;
      if (this.tree === 'Activities') {
        displayOrder = this.parseDisplayOrder(
          subs.find((x) => x.UnitId === this.treeNode?.UnitId)?.Title || '',
        );
        subs = this.originTreeObj().dataSource.data() as TreeComponent[];
      } else {
        displayOrder = this.parseDisplayOrder(
          subs.find((x) => x.SubId === this.treeNode?.SubId)?.Title || '',
        );
      }

      this.showSuspend =
        subs.length > 1 && subs.some((x) => this.parseDisplayOrder(x.Title) > displayOrder);

      this.otherSubs = subs
        .filter((x) => this.parseDisplayOrder(x.Title) > displayOrder)
        .slice(0, 5);
    },
    onAddFilesDialogClose(uploaded: boolean) {
      uploaded && this.reloadActiveNodeAfterAddingFiles();
    },
    onAddFolderDialogClose(added: boolean) {
      if (!added) {
        return;
      }

      this.reloadActiveNode();
      EventBus.$emit(ApplicationEvent.refreshInventory);
    },
    onEditFolderDialogClose(renamed: boolean) {
      if (!renamed) {
        return;
      }

      this.reloadParentNodeOfActiveNode();
      EventBus.$emit(ApplicationEvent.refreshInventory);
    },
    onEditFileNameDialogClose(renamed: boolean) {
      if (!renamed) {
        return;
      }

      this.reloadLastTreeNode();
      EventBus.$emit(ApplicationEvent.refreshInventory);
    },
    onEditFileTitleDialogClose(renamed: boolean) {
      if (!renamed) {
        return;
      }

      this.reloadLastTreeNode();
      EventBus.$emit(ApplicationEvent.refreshInventory);
    },
    onReplaceDialogClose() {
      this.reloadLastTreeNode();
      EventBus.$emit(ApplicationEvent.refreshInventory);
    },
    onCreateContextGroupDialogClose(created: boolean, mode: EntityDialogMode) {
      if (!created) {
        return;
      }

      if (mode == EntityDialogMode.create) {
        this.reloadActiveNode();
      } else {
        const parentTreeNode = this.getActiveNode().parentNode();
        this.$emit('changeSelectedNode', parentTreeNode);
        this.reloadParentNodeOfActiveNode();
      }

      EventBus.$emit(ApplicationEvent.refreshInventory);
    },
    onDeleteContextGroupDialogClose(deleted: boolean) {
      if (!deleted) {
        return;
      }

      const treeView = this.treeObj();
      const currentTreeItem = treeView.findByUid(this.lastTreeNode.uid);
      const currentTreeNode = treeView.dataItem(currentTreeItem);

      treeView.remove(currentTreeItem);

      this.updateHasFiles(currentTreeNode);
      this.reloadFilesForContextGroupOrFolder(currentTreeNode);
      EventBus.$emit(ApplicationEvent.refreshInventory);
    },
    onDeleteFileDialogClose(args: { deleted: boolean; fileUseHistoryId: number }) {
      if (!args.deleted) {
        return;
      }

      this.refreshFileInTree(args.fileUseHistoryId, true);
    },
    refreshFileInTree(fileUseHistoryId: number, removeFileFromTree: boolean, reloadAll = false) {
      const treeView = this.treeObj();

      const fileUseHistoryItem = this.findNodeByFileUseHistoryId(fileUseHistoryId);
      if (!fileUseHistoryItem) return;

      const currentTreeItem = treeView.findByUid(fileUseHistoryItem.uid);
      const currentTreeNode = treeView.dataItem(currentTreeItem);
      if (!currentTreeNode) return;

      if (currentTreeNode.FileUseHistoryId === this.selectedNode.FileUseHistoryId) {
        this.$emit('changeSelectedNode', new TreeComponent());
      }

      removeFileFromTree && treeView.remove(currentTreeItem);

      this.updateHasFiles(currentTreeNode);
      this.reloadFiles(currentTreeNode, reloadAll);
      EventBus.$emit(ApplicationEvent.refreshInventory);
    },
    onDeleteFolderDialogClose(deleted = false) {
      if (!deleted) {
        return;
      }

      const treeView = this.treeObj();
      const currentTreeItem = treeView.findByUid(this.lastTreeNode.uid);
      const currentTreeNode = treeView.dataItem(currentTreeItem);

      treeView.remove(currentTreeItem);
      this.$emit('changeSelectedNode', new TreeComponent());

      this.updateHasFiles(currentTreeNode);
      EventBus.$emit(ApplicationEvent.refreshInventory);
    },
    async onSuspendClick(targetSubId: number | null, hisId: number | null | undefined) {
      if (targetSubId && hisId) {
        try {
          await filesService.suspendFile(
            this.companyId,
            this.selectedApplicationId,
            targetSubId,
            hisId,
          );
          ErrorHelper.addSnackbarMessage('File has been suspended successfully.', 'success');
          this.onSuspendFileClose(targetSubId, hisId);
        } catch (error) {
          let text = 'Suspending the file failed';
          if (error instanceof BadRequestError) {
            text = error.message;
          }

          ErrorHelper.addSnackbarMessage(text, 'error', '', true);
        }
      }
    },
    onSuspendFileClose(targetSubId: number, fileUseHistoryId: number) {
      this.refreshSubs([targetSubId]);

      this.treeObj().one('dataBound', () => {
        const node = this.findNodeByFileUseHistoryId(fileUseHistoryId);

        if (node) {
          this.reloadParentNodeOf(node, node.id);
        }
      });

      EventBus.$emit(ApplicationEvent.refreshInventory);
    },
    refreshSubs(subIds: number[]) {
      this.initOtherSubs();
      for (const subId of subIds) {
        const targetSub = this.otherSubs.find((x) => x.SubId == subId);
        const displayOrder = this.parseDisplayOrder(targetSub?.Title || '');
        const targetNode = (this.treeObj().dataSource.data() as TreeComponent[]).find(
          (x) => this.parseDisplayOrder(x.Title) === displayOrder,
        );
        this.reloadNode(this.getNode((targetNode as any).uid), false, false);
      }
    },
    async copyForReused(subId: number | null, fuhId: number, fileId: number) {
      const request = new CreateClipboardItemRequest(
        this.selectedApplicationId,
        subId,
        fuhId,
        fileId,
      );
      await treeClipboardService.create(this.companyId, request);
    },
    async pasteAsReused(
      subId: number,
      reviewSectionId: number,
      repeatableSectionId: number | null = null,
      sectionExtensionId: number | null = null,
    ) {
      const request = new PasteClipboardItemRequest(
        this.selectedApplicationId,
        subId,
        reviewSectionId,
        repeatableSectionId,
        sectionExtensionId,
      );

      const response = await treeClipboardService.paste(this.companyId, request);
      this.reloadActiveNodeAfterAddingFiles();

      if (sectionExtensionId) {
        this.reloadNode(this.lastTreeNode, true);
      } else {
        const originalFileNode = this.findNodeByFileUseHistoryId(response.OriginalFileUseHistoryId);
        if (originalFileNode) {
          this.reloadParentNodeOf(originalFileNode);
        }
      }
    },
    async removeSuspension(fileUseHistoryId: number) {
      try {
        const response = await filesService.removeSuspension(this.companyId, fileUseHistoryId);

        const fileUseHistoryNode = this.findNodeByFileUseHistoryId(
          response.FileUseHistoryId,
        ) as any;
        const relatedFileUseHistoryNode = this.findNodeByFileUseHistoryId(
          response.RelatedFileUseHistoryId,
        ) as any;

        if (fileUseHistoryNode) {
          this.lastTreeNode = fileUseHistoryNode;
          this.refreshFileInTree(response.FileUseHistoryId, true);
          this.reloadParentNodeOf(fileUseHistoryNode);
        }

        relatedFileUseHistoryNode &&
          this.reloadParentNodeOf(
            relatedFileUseHistoryNode,
            fileUseHistoryNode ? '' : relatedFileUseHistoryNode.Id,
          );
      } catch (e) {
        if (e instanceof BadRequestError) {
          ErrorHelper.addSnackbarMessage(e.message, 'error', '');
        } else {
          ErrorHelper.addSnackbarMessage(
            'Remove Suspension failed.',
            'error',
            (e as Error).message,
            true,
          );
        }
      } finally {
        EventBus.$emit(ApplicationEvent.refreshInventory);
      }
    },
    getNode(nodeId: string): kendo.data.Node {
      const treeView = this.treeObj() as kendo.ui.TreeView;
      const element = treeView.findByUid(nodeId);
      return treeView.dataItem(element) as any;
    },
    getActiveNode(): kendo.data.Node {
      return this.getNode(this.lastTreeNode.uid);
    },
    findNodeByFileUseHistoryId(fuhId: number): kendo.data.Node | null {
      const treeView = this.treeObj();

      for (const item of treeView.items()) {
        const node = treeView.dataItem(item);

        if (node && node.FileUseHistoryId === fuhId) {
          return node;
        }
      }

      return null;
    },
    findNodesByFileId(fileId: number): kendo.data.Node[] {
      const treeView = this.treeObj();
      const result = Array<kendo.data.Node>();

      for (const item of treeView.items()) {
        const node = treeView.dataItem(item);

        if (node && node.FileId === fileId) {
          result.push(node);
        }
      }

      return result;
    },
    updateHasFiles(treeNode: kendo.data.Node) {
      let iterateTreeNode = treeNode.parentNode();
      do {
        iterateTreeNode.set(
          'HasFiles',
          iterateTreeNode.children.view().some((x: any) => x.HasFiles || x.FileType !== null),
        );

        iterateTreeNode = iterateTreeNode.parentNode();
      } while (iterateTreeNode);
    },
    reloadLastTreeNode() {
      if (this.lastTreeNode.FileUseHistoryId) {
        const fileNode = this.findNodeByFileUseHistoryId(this.lastTreeNode.FileUseHistoryId);
        fileNode && this.reloadParentNodeOf(fileNode);
      } else {
        this.reloadActiveNode();
      }
    },
    reloadActiveNode() {
      const activeNode = this.getActiveNode();
      (activeNode as kendo.data.HierarchicalDataSourceSchemaModel).hasChildren = true;
      this.reloadNode(activeNode);
    },
    reloadParentNodeOfActiveNode() {
      const activeNode = this.getActiveNode();
      activeNode && this.reloadParentNodeOf(activeNode);
    },
    reloadParentNodeOf(treeNode: kendo.data.Node, id = '') {
      const parentTreeNode = treeNode.parentNode();
      this.reloadNode(parentTreeNode, false, true, id);
    },
    reloadNode(treeNode: kendo.data.Node, setChildren = false, expand = true, id = '') {
      const treeView = this.treeObj();
      const treeElement = treeView.findByUid(treeNode.uid);
      const isExpanded = !!treeElement[0].attributes['data-expanded'];

      if (isExpanded) {
        treeView.collapse(treeElement);
      }

      treeNode.loaded(false);

      if (setChildren) {
        (treeNode as kendo.data.HierarchicalDataSourceSchemaModel).hasChildren = true;
      }

      (treeNode as any).load();

      treeView.one('dataBound', () => {
        if (isExpanded && expand) {
          treeView.expand(treeElement);
          treeView.one('dataBound', () => {
            this.$nextTick(() => {
              if (id) {
                const node = (treeNode as any).items.find((x: any) => x.Id == id);
                EventBus.$emit('refreshHeaderComponent', node);
                treeView.select(treeView.findByUid(node.uid));
              }
            });
          });
        }
      });
    },
    reloadActiveNodeAfterAddingFiles() {
      const activeNode = this.getActiveNode();
      this.reloadNodeAfterAddingFiles(activeNode);
    },
    reloadNodeAfterAddingFiles(treeNode: any) {
      const treeView = this.treeObj();
      const treeElement = treeView.findByUid(treeNode.uid);
      let iterateTreeNode = treeNode;

      do {
        iterateTreeNode.set('HasFiles', true);
        iterateTreeNode = iterateTreeNode.parentNode();
      } while (iterateTreeNode);

      treeNode.hasChildren = true;
      treeView.collapse(treeElement);

      treeNode.loaded(false);
      treeNode.load();

      EventBus.$emit(ApplicationEvent.refreshInventory);

      treeView.one('dataBound', () => {
        treeView.expand(treeElement);
      });
    },
    reloadFilesForContextGroupOrFolder(treeNode: any) {
      if (!treeNode.RepeatableSectionId && !treeNode.SectionExtensionId) return;

      treeNode.children.view().forEach((childNode: any) => {
        if (childNode.FileId) {
          this.reloadFiles(childNode);
        } else if (childNode.RepeatableSectionId || childNode.SectionExtensionId) {
          this.reloadFilesForContextGroupOrFolder(childNode);
        }
      });
    },
    reloadFiles(treeNode: any, reloadAll = false) {
      if (!treeNode.FileId) return;

      this.findNodesByFileId(treeNode.FileId).forEach((node: any) => {
        if (node.Id !== treeNode.Id || reloadAll) this.reloadParentNodeOf(node);
      });
    },
    parseDisplayOrder(title: string): number {
      const r = title.match(/\d+/);
      if (r?.length) {
        return parseInt(r[0]);
      }

      return 0;
    },
  },
});
