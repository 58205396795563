export default class ReviewDataGridBuilder {
  public static makeColumnsResizable(dataTable: any, generate: boolean) {
    if (dataTable && generate) {
      const one = (dataTable as any).$el as HTMLDivElement;
      const table = one.querySelector('table');
      if (table) {
        this.resizableGrid(table);
      }
    } else {
      const elements = Array.from(document.getElementsByClassName('col-resize-custom'));
      elements.forEach((element) => {
        element.parentNode?.removeChild(element);
      });
    }
  }

  public static DataGridShiftIgnore(item: any, selected: any[]) {
    const itemObj = item.item as any;
    const includes = selected.includes(itemObj);
    if (item.value === true && !includes) {
      selected.push(itemObj);
    } else if (item.value === false && includes) {
      selected = selected.filter((t) => t !== itemObj);
    }
    return selected;
  }
  public static resizableGrid(table: HTMLTableElement) {
    const row = table.querySelector('tr');
    const cols = row ? row.children : undefined;
    if (!cols) return;

    table.style.overflow = 'hidden';

    const tableHeight = table.offsetHeight;
    for (let i = 0; i < cols.length - 1; i++) {
      const div = this.createDiv(tableHeight);
      cols[i].appendChild(div);
      (cols[i] as HTMLElement).style.position = 'relative';
      (cols[i] as HTMLElement).setAttribute('counter', String(i));

      this.setListeners(div, table);
    }
    this.Update3Dots(table);
  }

  public static UpdatePage(dataTable: any) {
    const table = (dataTable as any).$el as HTMLDivElement;

    const firstRow = table.getElementsByTagName('tr')[0];
    if (firstRow) {
      firstRow.style.display = '';
    }
    setTimeout(() => this.Update3Dots(table), 0);
  }

  public static Update3Dots(table: HTMLElement, column: HTMLElement | null = null) {
    const currentColumn = column as HTMLElement;
    const children = [...table.getElementsByTagName('tr')];
    children.forEach((tr, index) => {
      if (index !== 0) {
        (tr as HTMLElement).style.display = 'flex';
        const columns = tr.children;
        const headerColumns = children[0].children;
        for (let i = 0; i < columns.length; i++) {
          const tdElement = columns[i] as HTMLElement;

          const children = [...tdElement.childNodes];
          children.forEach((tdChild: any) => {
            if (tdChild.nodeType === Node.TEXT_NODE) {
              const textContent = tdElement.textContent?.trim();
              if (textContent) {
                const span = document.createElement('span');
                span.textContent = textContent;
                tdElement.textContent = '';
                span.style.overflow = 'hidden';
                span.style.textOverflow = 'ellipsis';
                span.style.whiteSpace = 'nowrap';
                span.style.display = 'flow';
                tdElement.appendChild(span);
              }
            } else if (
              tdChild.tagName.toLowerCase() == 'span' ||
              tdChild.tagName.toLowerCase() == 'a'
            ) {
              tdChild.style.overflow = 'hidden';
              tdChild.style.textOverflow = 'ellipsis';
              tdChild.style.whiteSpace = 'nowrap';
              tdChild.style.display = 'flow';
            }
          });

          if (
            currentColumn &&
            parseInt(currentColumn.style.width) > (headerColumns[i] as HTMLElement).offsetWidth
          ) {
            const number = parseInt(currentColumn.getAttribute('counter') || '');
            (columns[number] as HTMLElement).style.width = currentColumn.style.width;
            (columns[number] as HTMLElement).style.minWidth = currentColumn.style.width;
          } else {
            (columns[i] as any).width = (headerColumns[i] as HTMLElement).offsetWidth;
            (columns[i] as any).minWidth = (headerColumns[i] as HTMLElement).offsetWidth;
            (columns[i] as HTMLElement).style.display = 'flex';
            (columns[i] as HTMLElement).style.alignItems = 'center';
          }
        }
      } else {
        (tr as HTMLElement).style.display = 'inline-block';
      }
    });
  }

    public static setListeners(div: HTMLElement, table: HTMLTableElement) {
    let pageX: number;
    let leftCol: HTMLElement;
    let rightCol: HTMLElement | null;
    let leftColWidth: number;
    let rightColWidth: number | undefined;

    div.addEventListener('mousedown', (e) => {
      e.preventDefault();
      e.stopPropagation();
      if (e.target) {
        const target = e.target as HTMLTextAreaElement;
        if (target.parentElement) {
          leftCol = target.parentElement as HTMLElement;
          rightCol = leftCol.nextElementSibling as HTMLElement;
          pageX = e.pageX;

          const padding = this.paddingDiff(leftCol);

          leftColWidth = leftCol.offsetWidth - padding;
          if (rightCol) rightColWidth = rightCol.offsetWidth - padding;
        }
      }
    });

    div.addEventListener('mouseover', (e) => {
      (e.target as HTMLElement).style.borderRight = '2px solid #d9d9d9';
    });

    div.addEventListener('mouseout', (e) => {
      (e.target as HTMLElement).style.borderRight = '';
    });

    document.addEventListener('mousemove', (e) => {
      if (leftCol && rightCol) {
        const diffX = e.pageX - pageX;
        const minColSize = 50;

        let leftSize = leftColWidth + diffX;
        leftSize = leftSize < minColSize ? minColSize : leftSize;

        if (rightCol && leftSize != minColSize) {
          let rightSize = rightColWidth! - diffX;
          rightSize = rightSize < minColSize ? minColSize : rightSize;

          rightCol.style.width = rightSize + 'px';
          rightCol.style.minWidth = rightSize + 'px';
          rightCol.style.maxWidth = rightSize + 'px';
        }
        
        leftCol.style.width = leftSize + 'px';
        leftCol.style.maxWidth = leftSize + 'px';
        leftCol.style.minWidth = leftSize + 'px';
        this.Update3Dots(table, leftCol);
        this.Update3Dots(table, rightCol);
      }
    });

    div.addEventListener('click', (e) => {
      e.preventDefault();
      e.stopPropagation();
    });
    document.addEventListener('mouseup', () => {
      leftCol = undefined!;
      rightCol = undefined!;
      pageX = undefined!;
      rightColWidth = undefined!;
      leftColWidth = undefined!;
    });
  }
  public static createDiv(height: number) {
    const div = document.createElement('div');
    div.style.top = '0';
    div.style.right = '0';
    div.style.width = '1px';
    div.style.backgroundColor = '#E5E4E2';
    div.style.position = 'absolute';
    div.style.cursor = 'col-resize';
    div.className = 'col-resize-custom';
    div.style.userSelect = 'none';
    div.style.height = '35px';
    return div;
  }
  public static paddingDiff(col: HTMLElement) {
    if (this.getStyleVal(col, 'box-sizing') === 'border-box') {
      return 0;
    }

    const padLeft = this.getStyleVal(col, 'padding-left');
    const padRight = this.getStyleVal(col, 'padding-right');
    return parseInt(padLeft) + parseInt(padRight);
  }
  public static getStyleVal(elm: HTMLElement, css: string) {
    return window.getComputedStyle(elm, null).getPropertyValue(css);
  }
}
