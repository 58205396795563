import type { WebViewerInstance } from '@pdftron/webviewer';
import $ from 'jquery';
import { Store } from 'vuex';

import { FileTypes } from '@/helpers/Enums';
import { EventBus } from '@/helpers/EventBus';
import { FileComponent } from '@/models/fileComponent';
import { ViewHistory } from '@/pdf/history-tracker';
import NavigationHistory from '@/pdf/navigationHistory';
import PdfDestination from '@/pdf/pdfDestination';
import PdfViewerService from '@/pdf/pdfviewer-service';
import PostLoadDestination from '@/pdf/postLoadDestination';
import { RootState } from '@/store/types';

export default class PdfViewerControl extends HTMLElement {
  // TODO: provide store reference from main.ts
  public static register(store: any, pdfTron: any, creationOptions: any): void {
    const el: any = document.createElement('preview-pane');
    PdfViewerControl.store = store;
    PdfViewerControl.pdfTron = pdfTron;
    PdfViewerControl.creationOptions = creationOptions;
    if (typeof el.name === 'undefined') {
      window.customElements.define('preview-pane', PdfViewerControl);
    }
  }

  // This is here so that this control type can be identified.
  public name = 'PDFViewerControl';

  /*
    public creationOptions: any = {
        config: "./pdf/pdftron730/webviewer/viewer_config.js",
        documentType: "pdf",
        enableAnnotations: true,
        enableReadOnlyMode: true,
        hideAnnotationPanel: false,
        path: "./vuepackages/pdf/pdftron730/webviewer",
        showPageHistoryButtons: true,
        showToolbarControl: true,
        streaming: false,
        disabledElements: ["header"],
        css: "./pdf/previewpane/PreviewPane.css"
    }; */

  private static store: Store<RootState>;

  private static pdfTron: any = null;

  public static creationOptions: any = null;

  private service: PdfViewerService;

  // ideally, we'd like this to be typed, but there isn't a typescript definition file for the viewer
  private viewer: Promise<WebViewerInstance> | undefined;

  private postLoadDest: PostLoadDestination | undefined;

  private currentFuhId: number;

  private currentFilePath: string;

  private iframe: any = null;

  private documentElement: any = null;

  private currentFileName: string | undefined;

  public constructor() {
    super();
    this.service = new PdfViewerService();
    this.currentFilePath = '';
    this.currentFuhId = 0;
  }

  public async clearDocumentAndHistory(): Promise<any> {
    const instance = await this.viewer!;
    instance.Core.documentViewer.closeDocument();
    instance.UI.closeElements(['errorModal', 'progressModal']);
    ViewHistory.clear();
    this.currentFileName = undefined;
    this.currentFilePath = '';
    this.currentFuhId = 0;
  }

  // this is for custom element lifecycle, so we can move this to mounted? or something like that
  public connectedCallback(): void {
    const options: any = {};
    this.service = new PdfViewerService();

    this.service.getLicense().done((result: any): void => {
      options.l = result.Data.license;
      this.viewer = PdfViewerControl.pdfTron(
        $.extend(PdfViewerControl.creationOptions, options),
        this,
      );
      this.viewer!.then((instance: WebViewerInstance): void => {
        this.documentElement = (): void => this.iframe.$('#DocumentViewer');
        instance.Core.documentViewer.addEventListener(
          'documentLoaded',
          this.onDocumentLoaded.bind(this),
        );
        instance.Core.annotationManager.addEventListener(
          'annotationSelected',
          this.onAnnotationSelected.bind(this),
        );
        instance.UI.setActiveLeftPanel('outlinesPanel');
        instance.UI.iframeWindow.addEventListener('loaderror', () => {
          PdfViewerControl.store.commit('changeIsDocumentLoaded', false);
          PdfViewerControl.store.commit('changeShowOverlayMessage', 'missing');
        });
      });
    });

    PdfViewerControl.store.watch(
      (state: { loadedDoc: any }) => state.loadedDoc,
      this.loadedDocChanged.bind(this),
    );
  }

  private async loadedDocChanged(file: FileComponent): Promise<void> {
    PdfViewerControl.store.commit('changeLoadedPdfPath', null);
    if (file.Type === FileTypes.PDF) {
      if (!file.FileExists) {
        await this.recordLoadHistory(file.FuhId);
        PdfViewerControl.store.commit('changeIsDocumentLoaded', false);
        PdfViewerControl.store.commit('changeShowOverlayMessage', 'missing');
      } else if (file.HasXfa) {
        PdfViewerControl.store.commit('changeIsUnsupportedFile', true);
        await this.recordLoadHistory(file.FuhId);
        PdfViewerControl.store.commit('changeIsDocumentLoaded', false);
        PdfViewerControl.store.commit('changeShowOverlayMessage', 'xfa');
      } else if (file.history) {
        PdfViewerControl.store.commit('changeIsDocumentLoaded', true);
        this.goToHistoryLocation(file.history);
      } else
        this.openFile(
          file.FuhId as number,
          PdfViewerControl.store.state.activeCompany?.Id as number,
        );
    }
  }

  private async onAnnotationSelected(event: any, annotations: any, action: any): Promise<any> {
    EventBus.$emit('toggleNotesPanel');
    if (action === 'selected') this.toggleNotesPanel(annotations);
  }

  private async observeSearchOverlayStatus() {
    const instance = await this.viewer!;
    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if ((mutation.target as HTMLElement).querySelector('.right-panel') == null) {
          PdfViewerControl.store.commit('changeIsSearchOpen', false);
        }
      });
    });
    observer.observe(instance.UI.iframeWindow.document.querySelector('.content')!, {
      attributes: true,
      attributeOldValue: true,
      attributeFilter: ['class'],
      childList: true,
    });
  }

  // toolbar functions
  public async toggleFullScreen(): Promise<any> {
    const instance = await this.viewer!;
    instance.UI.toggleFullScreen();
  }

  public async search(): Promise<any> {
    const instance = await this.viewer!;
    instance.UI.toggleElementVisibility('searchPanel');
  }

  public async toggleSideWindow(): Promise<any> {
    const instance = await this.viewer!;
    instance.UI.toggleElementVisibility('leftPanel');
  }

  public async syncSideWindow(): Promise<any> {
    const instance = await this.viewer!;
    const settings = PdfViewerControl.store.state.userSettings;
    if (settings && settings.BookmarkPanelOpen) {
      instance.UI.openElements(['leftPanel']);
    }
  }

  public async toggleNotesPanel(annots: any): Promise<any> {
    const instance = await this.viewer!;
    instance.UI.toggleElementVisibility('notesPanel');
    instance.Core.annotationManager.showAnnotation(annots[0]);
  }

  public async fitWidth(): Promise<any> {
    const instance = await this.viewer!;
    instance.UI.setFitMode(instance.UI.FitMode.FitWidth);
  }

  public async fitPage(): Promise<any> {
    const instance = await this.viewer!;
    instance.UI.setFitMode(instance.UI.FitMode.FitPage);
  }

  private static zoomIncrements: number[] = [
    0.25, 0.33, 0.5, 0.67, 0.75, 0.8, 0.9, 1.0, 1.1, 1.25, 1.5, 1.75, 2.0, 2.5, 3.0, 4.0, 5.0,
  ];

  private getClosestZoomIndex(x: number): number {
    const closest = PdfViewerControl.zoomIncrements.reduce(function (prev, curr): number {
      return Math.abs(curr - x) < Math.abs(prev - x) ? curr : prev;
    });
    return PdfViewerControl.zoomIncrements.indexOf(closest);
  }

  public async zoomIn(): Promise<any> {
    const instance = await this.viewer!;
    const zoom = instance.UI.getZoomLevel();
    const zoomLevel = this.getClosestZoomIndex(zoom);
    const zoomInLevel = Math.min(zoomLevel + 1, PdfViewerControl.zoomIncrements.length - 1);
    instance.UI.setZoomLevel(PdfViewerControl.zoomIncrements[zoomInLevel]);
  }

  public async zoomOut(): Promise<any> {
    const instance = await this.viewer!;
    const zoom = instance.UI.getZoomLevel();
    const zoomLevel = this.getClosestZoomIndex(zoom);
    const zoomOutLevel = Math.max(zoomLevel - 1, 0);
    instance.UI.setZoomLevel(PdfViewerControl.zoomIncrements[zoomOutLevel]);
  }

  // history stuff

  public async mergeHistoryAtCurrentPosition(): Promise<any> {
    const view = await this.getViewSnapshot();
    ViewHistory.add(
      new NavigationHistory(
        this.currentFuhId,
        view,
        this.currentFilePath,
        this.currentFileName,
        FileTypes.PDF,
      ),
    );
  }

  // if we are on a different page in document, add a new history
  // otherwise just update the scroll values to current position
  public async recordHistoryInSameDocument(): Promise<any> {
    const currentview = await this.getViewSnapshot();
    const history = new NavigationHistory(
      this.currentFuhId,
      currentview,
      this.currentFilePath,
      this.currentFileName,
      FileTypes.PDF,
    );
    ViewHistory.addIfDifferentPage(history);
  }

  private async getViewSnapshot(): Promise<PdfDestination> {
    const instance = await this.viewer!;
    const scrollViewElement = instance.Core.documentViewer.getScrollViewElement() as HTMLElement;
    const currentLocation = new PdfDestination(
      instance.Core.documentViewer.getCurrentPage(),
      scrollViewElement.offsetLeft,
      scrollViewElement.offsetTop,
      instance.UI.getZoomLevel(),
    );
    return currentLocation;
  }

  // navigation stuff

  // clicked on a link (external)
  public goToPdfLink(gotoR: any, addToHistory = true): void {
    this.service
      .getUrlFromRelativePath(
        this.currentFilePath,
        gotoR.filename,
        PdfViewerControl.store.state.activeCompany?.Id as number,
      )
      .done((result: any): void => {
        const path: string = result.Url;
        this.postLoadDest = new PostLoadDestination('PdfCoordinates', gotoR.dest, addToHistory);
        this.loadDocumentAndAddHistory(-1, path, gotoR.filename);
        PdfViewerControl.store.commit('changeLoadedPdfPath', {
          source: gotoR.filename,
          target: path,
        });
      })
      .fail((result: any): void => {
        // eslint-disable-next-line no-console
        console.error(result);
      });
  }

  // clicking back and forward
  public async goToHistoryLocation(location: NavigationHistory | null): Promise<any> {
    if (!location) {
      return;
    }

    if (location.path != null && !this.compareUrlPaths(this.currentFilePath, location.path)) {
      this.postLoadDest = new PostLoadDestination('ViewerCoordinates', location.dest, false);
      this.loadDocument(location.fuhId, location.path, location.filename);
    } else if (
      location.fuhId === this.currentFuhId ||
      (location.path != null && location.fuhId === -1)
    ) {
      this.gotoView(new PostLoadDestination('ViewerCoordinates', location.dest));
    } else {
      this.postLoadDest = new PostLoadDestination('ViewerCoordinates', location.dest, false);
      this.openFile(
        location.fuhId,
        PdfViewerControl.store.state.activeCompany?.Id as number,
        false,
      );
    }
  }

  private async gotoView(loadInfo: PostLoadDestination): Promise<any> {
    const instance = await this.viewer!;

    const doc = instance.Core.documentViewer.getDocument();

    if (loadInfo.type === 'PdfCoordinates') {
      // if we are opening a link
      const page = loadInfo.destination.page === undefined ? 1 : loadInfo.destination.page;

      if (loadInfo.destination.left === undefined || loadInfo.destination.top === undefined) {
        loadInfo.destination = { x: 0, y: 0 };
      } else {
        loadInfo.destination = doc.getViewerCoordinates(
          loadInfo.destination.page,
          loadInfo.destination.left,
          loadInfo.destination.top,
        );
      }

      const destination = new instance.Core.Bookmark(
        [],
        '',
        page,
        null as any,
        loadInfo.destination.y,
        loadInfo.destination.x,
      );
      instance.Core.documentViewer.displayBookmark(destination);
    } else {
      // if we are opening a history location
      instance.Core.documentViewer.displayPageLocation(
        loadInfo.destination.page,
        loadInfo.destination.x,
        loadInfo.destination.y,
        false,
      );
    }

    if (loadInfo.destination.zoom) {
      instance.UI.setZoomLevel(loadInfo.destination.zoom);
    }
  }

  // loading stuff
  public openFile(fuhId: number, companyId: number, addToHistory = true): void {
    this.service
      .getUrlFromFuhId(fuhId, companyId)
      .done((result: any): void => {
        if (addToHistory) {
          this.loadDocumentAndAddHistory(fuhId, result.Url, result.FileName);
        } else {
          this.loadDocument(fuhId, result.Url, result.FileName);
        }
      })
      .fail((result: any): void => {
        // eslint-disable-next-line no-console
        console.error(result);
      });
  }

  private async onDocumentLoaded(): Promise<any> {
    PdfViewerControl.store.commit('changeIsDocumentLoaded', true);
    this.syncSideWindow();
    let addToHistory = true;
    try {
      if (this.postLoadDest != undefined) {
        this.gotoView(this.postLoadDest);
        addToHistory = this.postLoadDest.addToHistory;
      }
    } finally {
      if (addToHistory) this.mergeHistoryAtCurrentPosition();
      this.postLoadDest = undefined;
      this.observeSearchOverlayStatus();
    }
  }

  private async recordLoadHistory(fuhId: number | null): Promise<void> {
    if (this.isFileOpen()) await this.recordHistoryInSameDocument();

    if (this.currentFuhId === fuhId) {
      PdfViewerControl.store.commit('changeIsDocumentLoaded', true);
      return;
    }
    ViewHistory.addPlaceholder(FileTypes.PDF);
  }

  private async loadDocumentAndAddHistory(
    fuhId: number,
    url: string,
    filename: string | undefined,
  ): Promise<any> {
    await this.recordLoadHistory(fuhId);
    await this.loadDocument(fuhId, url, filename);
  }

  private isFileOpen(): boolean {
    return this.currentFilePath !== null && this.currentFuhId !== 0;
  }

  private async loadDocument(
    fuhId: number,
    url: string,
    filename: string | undefined,
  ): Promise<any> {
    const instance = await this.viewer!;
    this.currentFilePath = url;
    this.currentFileName = filename;
    PdfViewerControl.store.commit('changeIsDocumentLoaded', false);
    instance.UI.loadDocument(url, { filename });
    this.currentFuhId = fuhId;
  }

  private compareUrlPaths(url1: string, url2: string): boolean {
    return url1.substr(0, url1.indexOf('?')) === url2.substr(0, url2.indexOf('?'));
  }
}
