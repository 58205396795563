
import Vue from 'vue';

import { mapGetters, mapMutations, mapState } from 'vuex';

import Dialog from '@/components/popups/Dialog.vue';
import ErrorHelper from '@/helpers/errorHelper';
import { BadRequestError } from '@/models/Errors';
import TreeComponent from '@/models/treeComponent';
import { filesService } from '@/services';

export default Vue.extend({
  components: {
    // eslint-disable-next-line vue/no-reserved-component-names
    Dialog,
  },
  props: {
    value: { type: Boolean },
    treeNode: { type: Object, default: null },
    treeView: { type: Object },
  },
  data(): {
    deleting: boolean;
    submissionUnitsCount: number;
  } {
    return {
      deleting: false,
      submissionUnitsCount: 0,
    };
  },
  computed: {
    ...mapGetters(['companyId']),
    ...mapState(['selectedApplicationId', 'selectedFormat', 'selectedRegion']),
  },
  beforeUpdate() {
    this.submissionUnitsCount = this.treeView?.dataSource?.total() ?? 0;
  },
  methods: {
    ...mapMutations(['changeAppSelector', 'changeSelectedNode']),
    async deleteSubmissionUnit(): Promise<void> {
      this.deleting = true;

      try {
        await filesService.deleteFolder(this.companyId, this.treeNode.SubId, this.treeNode.SectionExtensionId);
        ErrorHelper.addSnackbarMessage('The folder has been deleted successfully.', 'success');
        this.$emit('close', true);
      } catch (e) {
        if (e instanceof BadRequestError) {
          ErrorHelper.addSnackbarMessage(e.message, 'error', '');
        } else {
          ErrorHelper.addSnackbarMessage(
            'Delete was unsuccessful. Please try again or contact your System Administrator if the issue persists.',
            'error',
            (e as Error).message,
            true,
          );
        }
      } finally {
        this.deleting = false;
        this.$emit('input', false);
      }
    },
    async removeSubUnitFromTheTree(): Promise<void> {
      const treeItem = this.treeView.findByUid(this.treeNode.uid);
      this.changeSelectedNode(new TreeComponent());
      await new Promise((resolve) => setTimeout(resolve, 0));
      this.treeView.remove(treeItem);
    },
  },
});
