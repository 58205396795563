
import Vue from 'vue';

import { mapMutations, mapState } from 'vuex';

import NavBar from '@/components/navs/NavBar.vue';
import NavDrawer from '@/components/navs/NavDrawer.vue';

export default Vue.extend({
  components: {
    NavBar,
    NavDrawer,
  },
  props: {
    accessDenied: Boolean,
  },
  computed: {
    ...mapState([
      'showAppSelector',
      'showTrees',
      'appTitle',
      'nonAppActiveView',
      'userGuideUrl',
      'drawerState',
      'isPublishMode',
      'webApplicationName',
    ]),
  },
  methods: {
    ...mapMutations([
      'changeDrawerState',
      'changeAppSelector',
      'changeShowTrees',
      'changeInfoDialogState',
      'changeContactDialogState',
      'changeUserGuideUrl',
      'changeOpenMenuCount',
    ]),
  },
});
