
import Vue from 'vue';

import BaseFullscreenLoader from '@/components/loaders/BaseFullscreenLoader.vue';

export default Vue.extend({
  components: {
    BaseFullscreenLoader,
  },
  props: {
    showLoader: {
      type: Boolean,
    },
  },
  data(): {
    message: string;
  } {
    return {
      message: 'Publishing application',
    };
  },
});
