
import Vue from 'vue';

import { mapGetters, mapMutations } from 'vuex';

import ApplicationInformation1 from '@/components/applicationWizard/ApplicationInformation1.vue';
import ApplicationInformation2 from '@/components/applicationWizard/ApplicationInformation2.vue';
import SequenceContactList from '@/components/applicationWizard/SequenceContactList.vue';
import SequenceInformation from '@/components/applicationWizard/SequenceInformation.vue';
import { ApplicationEvent, ApplicationWizardScreen } from '@/enums';
import ErrorHelper from '@/helpers/errorHelper';
import { EventBus } from '@/helpers/EventBus';
import { BadRequestError } from '@/models/Errors';
import {
  AgencyType,
  AgencyTypeCode,
  AppType,
  Contact,
  ContactDetailType,
  ContactType,
  CrossReferenceApplication,
  Region,
  SubType,
  Unit,
  UnitType,
  Version,
} from '@/models/WebPublish';
import { applicationsService } from '@/services';
import { RootMutations } from '@/store';

export default Vue.extend({
  components: {
    ApplicationInformation1,
    ApplicationInformation2,
    SequenceInformation,
    SequenceContactList,
  },
  data(): {
    currentScreen: ApplicationWizardScreen;
    regions: Region[];
    versions: Version[];
    agencyTypes: AgencyType[];
    agencyTypeCodes: AgencyTypeCode[];
    appTypes: AppType[];
    unitTypes: UnitType[];
    subTypes: SubType[];
    units: Unit[];
    contactTypes: ContactType[];
    contactDetailTypes: ContactDetailType[];
    regionId: number | null;
    version: Version | null;
    agencyTypeCodeId: number | null;
    appTypeId: number | null;
    unitTypeId: number | null;
    unitId: number | null;
    subTypeId: number | null;
    appNumber: string;
    applicant: string;
    sequenceNumber: number;
    description: string;
    contacts: Contact[];
    crossReferenceApplications: CrossReferenceApplication[];
    isEditMode: boolean;
    uniqueAppNumberAndAppType: boolean;
    loading: boolean;
  } {
    return {
      currentScreen: ApplicationWizardScreen.applicationInformation1,
      regions: [],
      versions: [],
      agencyTypes: [],
      agencyTypeCodes: [],
      appTypes: [],
      unitTypes: [],
      subTypes: [],
      units: [],
      contactTypes: [],
      contactDetailTypes: [],
      regionId: null,
      version: null,
      agencyTypeCodeId: null,
      appTypeId: null,
      unitTypeId: null,
      unitId: null,
      subTypeId: null,
      appNumber: '',
      applicant: '',
      sequenceNumber: 1,
      description: '',
      contacts: [],
      crossReferenceApplications: [{ AppTypeId: null, AppNumber: '' }],
      isEditMode: false,
      uniqueAppNumberAndAppType: true,
      loading: false,
    };
  },
  computed: {
    ...mapGetters(['companyId']),
    isApplicationInformation1(): boolean {
      return this.currentScreen === ApplicationWizardScreen.applicationInformation1;
    },
    isApplicationInformation2(): boolean {
      return this.currentScreen === ApplicationWizardScreen.applicationInformation2;
    },
    isSequenceInformation(): boolean {
      return this.currentScreen === ApplicationWizardScreen.sequenceInformation;
    },
    isContactList(): boolean {
      return this.currentScreen === ApplicationWizardScreen.contactList;
    },
    canClickNext(): boolean {
      switch (this.currentScreen) {
        case ApplicationWizardScreen.applicationInformation1:
          return !!(this.regionId && this.version);
        case ApplicationWizardScreen.applicationInformation2:
          return (
            !!(
              this.appTypeId &&
              parseInt(this.appNumber) &&
              this.appNumber.length === 6 &&
              this.agencyTypeCodeId
            ) && this.uniqueAppNumberAndAppType
          );
        case ApplicationWizardScreen.sequenceInformation:
          return !!(this.applicant && this.sequenceNumber && this.unitTypeId && this.subTypeId);
        case ApplicationWizardScreen.contactList:
          return !!this.contacts.length;
        default:
          return true;
      }
    },
  },
  async beforeMount() {
    await Promise.all([
      this.loadRegions(),
      this.loadAppTypes(),
      this.loadUnitTypes(),
      this.loadSubTypes(),
      this.loadUnits(),
    ]);
  },
  methods: {
    ...mapMutations({
      close: RootMutations.closeModalWindow,
    }),
    async loadRegions() {
      this.regions = await applicationsService.getRegions();
    },
    async loadVersions() {
      this.versions = this.regionId ? await applicationsService.getVersions(this.regionId) : [];
    },
    async loadAgencyTypes() {
      this.agencyTypes = this.regionId
        ? await applicationsService.getAgencyTypes(this.regionId)
        : [];
    },
    async loadAgencyTypeCodes() {
      this.agencyTypeCodes = this.version
        ? await applicationsService.getAgencyTypeCodes(this.version.CodeSystemId)
        : [];
    },
    async loadAppTypes() {
      this.appTypes = this.version
        ? await applicationsService.getAppTypes(this.version.CodeSystemId)
        : [];
    },
    async loadUnitTypes() {
      this.unitTypes =
        this.appTypeId && this.version
          ? await applicationsService.getUnitTypes(this.version.CodeSystemId, this.appTypeId)
          : [];
    },
    async loadSubTypes() {
      this.subTypes =
        this.unitTypeId && this.version
          ? await applicationsService.getSubTypes(this.version.CodeSystemId, this.unitTypeId)
          : [];
    },

    async loadUnits() {
      this.units = await applicationsService.getAllowedUnits(
        this.$store.state.activeCompany.Id,
        null,
        null,
        null,
        this.unitTypeId,
      );
    },

    async loadContactTypes() {
      this.contactTypes = this.regionId ? await applicationsService.getContactTypes() : [];
    },
    async loadContactDetailTypes() {
      this.contactDetailTypes = this.regionId
        ? await applicationsService.getContactDetailTypes()
        : [];
    },
    async setRegionId(regionId: number) {
      this.regionId = regionId;
      this.version = null;
      this.unitTypeId = null;

      await Promise.all([
        this.loadVersions(),
        this.loadContactTypes(),
        this.loadContactDetailTypes(),
        this.loadAgencyTypes(),
      ]);
    },
    async setVersion(version: Version) {
      this.version = version;
      this.appTypeId = null;
      this.unitTypeId = null;
      this.subTypeId = null;

      await this.loadAppTypes();
      await this.loadAgencyTypeCodes();
    },
    async setAppTypeId(appTypeId: number) {
      this.appTypeId = appTypeId;
      this.unitTypeId = null;
      this.subTypeId = null;

      await this.loadUnitTypes();
    },
    async setUnitTypeId(unitTypeId: number) {
      this.unitTypeId = unitTypeId;
      this.subTypeId = null;

      await this.loadSubTypes();
      this.unitId = this.units[0].Id;
    },
    setSubTypeId(subTypeId: number) {
      this.subTypeId = subTypeId;
    },
    setAppNumber(appNumber: string) {
      this.appNumber = appNumber;
    },
    async setAgencyTypeCodeId(agencyTypeCodeId: number) {
      this.agencyTypeCodeId = agencyTypeCodeId;
    },
    setApplicant(applicant: string) {
      this.applicant = applicant;
    },
    setSequenceNumber(sequenceNumber: number) {
      this.sequenceNumber = sequenceNumber;
    },
    setDescription(description: string) {
      this.description = description;
    },
    setContacts(contacts: Contact[]) {
      this.contacts = contacts;
    },
    setCrossReferenceApplications(apps: CrossReferenceApplication[]) {
      this.crossReferenceApplications = apps;
    },
    setUniqueAppNumberAndAppType(uniqueAppNumberAndAppType: boolean) {
      this.uniqueAppNumberAndAppType = uniqueAppNumberAndAppType;
    },
    async nextScreen() {
      switch (this.currentScreen) {
        case ApplicationWizardScreen.applicationInformation1:
          this.currentScreen = ApplicationWizardScreen.applicationInformation2;
          break;
        case ApplicationWizardScreen.applicationInformation2:
          if (this.$store.state.activeCompany && this.appTypeId) {
            try {
              const result = await applicationsService.validateAppNumberAndType(
                this.$store.state.activeCompany.Id,
                {
                  AppNumber: this.appNumber,
                  AppTypeId: this.appTypeId,
                  IsPublish: true,
                },
              );

              if (result) {
                this.currentScreen = ApplicationWizardScreen.sequenceInformation;
              } else {
                this.uniqueAppNumberAndAppType = false;
              }
            } catch (e) {
              ErrorHelper.addSnackbarMessage(
                'Create application failed.',
                'error',
                (e as Error).message,
                true,
              );
            }
          }
          break;
        case ApplicationWizardScreen.sequenceInformation:
          this.currentScreen = ApplicationWizardScreen.contactList;
          break;
      }
    },
    prevScreen() {
      switch (this.currentScreen) {
        case ApplicationWizardScreen.contactList:
          this.currentScreen = ApplicationWizardScreen.sequenceInformation;
          break;
        case ApplicationWizardScreen.sequenceInformation:
          this.currentScreen = ApplicationWizardScreen.applicationInformation2;
          break;
        case ApplicationWizardScreen.applicationInformation2:
          this.currentScreen = ApplicationWizardScreen.applicationInformation1;
          break;
      }
    },
    async save(): Promise<number | undefined> {
      if (!this.appTypeId || !this.unitTypeId || !this.subTypeId || !this.agencyTypeCodeId) {
        ErrorHelper.addSnackbarMessage(
          'Creating application failed.',
          'error',
          `appTypeId = ${this.appTypeId} unitTypeId = ${this.unitTypeId} subTypeId = ${this.subTypeId}`,
          true,
        );
        return;
      }

      try {
        const appId = await applicationsService.createApplication(
          this.$store.state.activeCompany.Id,
          {
            AppNumber: this.appNumber,
            AppTypeId: this.appTypeId,
            AgencyTypeCodeId: this.agencyTypeCodeId,
            CrossReferenceApps: this.crossReferenceApplications.filter((x) => x.AppTypeId),
            Submission: {
              Applicant: this.applicant,
              SubmissionUnitNumber: this.sequenceNumber,
              SubmissionTypeId: this.subTypeId,
              UnitTypeId: this.unitTypeId,
              UnitId: null,
              Title: this.description,
              Contacts: this.contacts,
            },
          },
        );

        ErrorHelper.addSnackbarMessage('The application has been created successfully.', 'success');
        return appId;
      } catch (e) {
        if (e instanceof BadRequestError) {
          ErrorHelper.addSnackbarMessage(e.message, 'error', '');
        } else {
          ErrorHelper.addSnackbarMessage(
            'Creating application failed.',
            'error',
            (e as Error).message,
            true,
          );
        }

        return;
      }
    },
    async finish() {
      this.loading = true;
      try {
        const appId = await this.save();
        if (appId !== undefined) {
          EventBus.$emit(ApplicationEvent.applicationCreated, appId);
          this.close();
        }
      } finally {
        this.loading = false;
      }
    },
  },
});
