import $ from 'jquery';

import { Panels } from '@/helpers/Enums';
import { EventBus } from '@/helpers/EventBus';
import { FileComponent } from '@/models/fileComponent';
import { ViewHistory } from '@/pdf/history-tracker';
import NavigationHistory from '@/pdf/navigationHistory';

export class ViewerToolbarHelper {
  public static toggleSideWindow(): void {
    ($('#previewPane') as any)[0].toggleSideWindow();
  }

  public static goBack(): void {
    const whereTo = ViewHistory.goBack();
    if (!whereTo) return;

    this.changeViewer(whereTo);
  }

  public static goForward(): void {
    const whereTo = ViewHistory.goForward();
    if (!whereTo) return;

    this.changeViewer(whereTo);
  }

  private static changeViewer(whereTo: NavigationHistory): void {
    EventBus.$emit('changeNonAppActiveView', Panels.Viewer);
    EventBus.$emit(
      'changeLoadedDoc',
      new FileComponent(whereTo.fuhId, whereTo.type, false, true, whereTo),
    );
  }

  public static zoomIn(): void {
    ($('#previewPane') as any)[0].zoomIn();
  }

  public static zoomOut(): void {
    ($('#previewPane') as any)[0].zoomOut();
  }

  public static fitWidth(): void {
    ($('#previewPane') as any)[0].fitWidth();
  }

  public static fitPage(): void {
    ($('#previewPane') as any)[0].fitPage();
  }

  public static search(): void {
    ($('#previewPane') as any)[0].search();
  }

  public static fullscreen(): void {
    const elem = ($('#viewerContainer') as any)[0];

    const inFullScreenMode =
      (document as any).fullscreenElement ||
      (document as any).mozFullScreenElement ||
      (document as any).webkitFullscreenElement ||
      (document as any).msFullscreenElement;

    if (!inFullScreenMode) {
      if (elem.requestFullscreen) {
        elem.requestFullscreen();
      } else if (elem.mozRequestFullScreen) {
        /* Firefox */
        elem.mozRequestFullScreen();
      } else if (elem.webkitRequestFullscreen) {
        /* Chrome, Safari and Opera */
        elem.webkitRequestFullscreen();
      } else if (elem.msRequestFullscreen) {
        /* IE/Edge */
        elem.msRequestFullscreen();
      }
      EventBus.$emit('changeIsFullScreen', true);
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if ((document as any).mozCancelFullScreen) {
        /* Firefox */
        (document as any).mozCancelFullScreen();
      } else if ((document as any).webkitExitFullscreen) {
        /* Chrome, Safari and Opera */
        (document as any).webkitExitFullscreen();
      } else if ((document as any).msExitFullscreen) {
        /* IE/Edge */
        (document as any).msExitFullscreen();
      }
      EventBus.$emit('changeIsFullScreen', false);
    }
  }
}
