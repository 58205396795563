import { ContextMenuItemKey } from '@/enums';
import { TreeType } from '@/helpers/Enums';
import TreeComponent from '@/models/treeComponent';

export class ContextMenuItem {
  constructor(
    public key: ContextMenuItemKey,
    public displayText: string,
    public order: number,
    public style: ContextMenuItemStyle = ContextMenuItemStyle.default,
    public disabled: boolean = false,
    public items: ContextMenuSubItem[] = [],
  ) {}

  public get isDropdown() {
    return this.items.length > 0;
  }
}

export class ContextMenuSubItem {
  constructor(
    public id: number,
    public displayText: string,
  ) {}
}

export enum ContextMenuItemStyle {
  default,
  success,
  danger,
}

export class ContextMenuContext {
  constructor(
    public isPublishMode: boolean,
    public companyId: number,
    public appId: number,
    public activeTreeType: TreeType,
    public selectedNode: TreeComponent | null,
  ) {}
}
