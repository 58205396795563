
import Vue from 'vue';

import { TreeType } from '@/helpers/Enums';

export default Vue.extend({
  props: {
    treeNode: {
      type: Object,
    },
    showMenu: {
      type: Boolean,
    },
    selectedFuhId: {
      type: Number,
    },
    activeTree: {
      type: Number,
    },
    isWebPublish: Boolean,
  },
  data(): {
    tree: any;
  } {
    return {
      tree: TreeType.Origin,
    };
  },
  watch: {
    activeTree(): void {
      this.tree = this.activeTree;
    },
  },
  methods: {
    expandNode(): void {
      const data = {
        fileUseHistoryId: this.selectedFuhId,
        TreeType: this.tree,
      };
      this.$emit('changeInventoryNodeToOpen', data);
    },
  },
});
