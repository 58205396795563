
import Vue from 'vue';

import CopyButton from '@/components/details/CopyButton.vue';
import ClipboardHelper from '@/helpers/clipboardHelper';

export default Vue.extend({
  components: {
    CopyButton,
  },
  props: {
    detail: Object,
  },
  methods: {
    copyDetail(detail: string) {
      ClipboardHelper.copyDetail(detail);
    },
  },
});
