
import Vue from 'vue';

import GlobalHelper from '@/helpers/globalHelper';
export default Vue.extend({
  props: {
    infoDialogState: {
      type: Boolean,
    },
    appName: {
      type: String,
    },
  },
  data(): {
    currentYear: number;
    imageUrl: string;
    infoDialog: boolean;
    version: string;
  } {
    return {
      currentYear: new Date().getFullYear(),
      imageUrl: '',
      infoDialog: false,
      version: '',
    };
  },
  computed: {
    synchrologoUrl(): string {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      // eslint-disable-next-line @typescript-eslint/no-var-requires
      return GlobalHelper.getPath(require('../../Content/images/certaralogo.svg'));
    },
  },
  watch: {
    infoDialogState(): void {
      this.infoDialog = this.infoDialogState;
    },
    infoDialog(): void {
      if (this.infoDialog === false) {
        this.$emit('changeInfoDialogState');
      }
    },
  },
  mounted(): void {
    this.version = window.version;
    this.imageUrl = this.synchrologoUrl;
  },
});
