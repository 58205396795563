
import Vue from 'vue';

import { Region, Version } from '@/models/WebPublish';

export default Vue.extend({
  props: {
    regions: { type: Array as () => Array<Region>, default: () => [] },
    versions: { type: Array as () => Array<Version>, default: () => [] },
    selectedRegionId: { type: Number as () => number | null, default: () => null },
    selectedVersion: { type: Object as () => Version | null, default: () => null },
  },
  computed: {
    isRegionSelected() {
      return !!this.selectedRegionId;
    },
  },
  methods: {
    onRegionSelect(regionId: string) {
      this.$emit('onRegionSelect', regionId);
    },
    onVersionSelect(specificationId: number) {
      const version = this.versions.find((v) => v.SpecificationId === specificationId);
      this.$emit('onVersionSelect', version);
    },
  },
});
